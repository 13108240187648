import styles from "components/Form/Form.module.scss";

export const formConfig = {
  email: {
    title: "Email Address",
    elementType: "input",
    elementConfig: {
      id: "email",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Email is required",
    },
  },
  password: {
    title: "Password",
    elementType: "input",
    elementConfig: {
      id: "password",
      type: "password",
      value: "",
      autoComplete: "off",
      placeholder: "········",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Pasword is required",
    },
  },
};
