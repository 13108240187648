import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";

const userLoginAdapter = createEntityAdapter({});

const initialState = userLoginAdapter.getInitialState();

export const userLoginApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsersLogin: builder.query({
      query: (userId) => `/users/${userId}/logins`,
      transformResponse: (response) => {
        if (!response) return userLoginAdapter.setAll(initialState, []);

        const userLogin = { ...response };

        delete userLogin.password_hash;

        return userLoginAdapter.setAll(initialState, [userLogin]);
      },
      providesTags: (result, error, id) => [{ type: "UserLogin", id }],
    }),
    createUserLogin: builder.mutation({
      query: ({ userId, ...data }) => ({
        url: `/users/${userId}/logins`,
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidateTags: [{ type: "UserLogin", id: "LIST" }],
    }),
    updateUserLogin: builder.mutation({
      query: ({ userId, ...data }) => ({
        url: `/users/${userId}/logins`,
        method: "PATCH",
        body: JSON.stringify({
          id: userId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "UserLogin", id: args.id },
      ],
    }),
    deleteUserLogin: builder.mutation({
      query: (userId) => ({
        url: `/users/${userId}/logins`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "UserLogin", id: args.id },
      ],
    }),
  }),
});

export const {
  useGetUsersLoginQuery,
  useCreateUserLoginMutation,
  useDeleteUserLoginMutation,
  useUpdateUserLoginMutation,
} = userLoginApiSlice;
