import { UNAUTHORIZED } from "constants";
import { Globals } from "utils/styleconfig";
import { truncateString } from "utils/helpers";
import Button from "components/UI/Button/Button";
import React, { useEffect, useMemo } from "react";
import { RiEditBoxLine, RiDeleteBinLine } from "react-icons/ri";
import { useDeleteModalContext } from "context/DeleteModalContext";

import styles from "components/Table/Table.module.scss";

const allowedTypes = ["string", "number"];

const Table = ({
  data,
  children,
  customStyle,
  customTHead,
  customTBody,
  headings = [],
  hiddenColumns = [],
  enableActions = true,
  onEdit = () => UNAUTHORIZED,
  onDelete = () => UNAUTHORIZED,
  onItemClickHandler = () => {},
}) => {
  const { toggle, itemId, reset, isConfirmed } = useDeleteModalContext();

  useEffect(() => {
    let isDeleted = false;

    if (isConfirmed) {
      isDeleted = true;
      onDelete(itemId, true);
    }

    return () => {
      if (isDeleted) {
        reset();
      }
    };
  }, [isConfirmed, itemId, onDelete, reset]);

  const keys = useMemo(() => {
    let arr = [];

    if (headings.length <= 0 && typeof headings === "object") {
      data?.ids?.slice(0, 1).forEach((id) => {
        arr = Object.keys(data?.entities[id]);
      });

      if (arr.includes("id")) {
        arr.splice(arr.indexOf("id"), 1);
      }

      return arr;
    }

    if (headings.includes("id")) {
      headings.splice(headings.indexOf("id"), 1);
    }

    arr = headings;

    return arr;
  }, [data?.entities, data?.ids, headings]);

  let tableActions = null;

  const editDisabled = useMemo(() => onEdit() === UNAUTHORIZED, [onEdit]);
  const deleteDisabled = useMemo(() => onDelete() === UNAUTHORIZED, [onDelete]);

  const tableHeadings = useMemo(
    () =>
      keys
        .filter((col) => !hiddenColumns.includes(col))
        .map((heading) => heading),
    [keys, hiddenColumns]
  );

  if (enableActions) {
    tableActions = tableHeadings.length > 0 ? <th>Actions</th> : <th></th>;
  }

  const renderActionBtns = (id) =>
    enableActions && (
      <td className={styles.actions}>
        {!editDisabled && (
          <Button onClick={() => onEdit(id, true)}>
            <RiEditBoxLine color={Globals.colorDarkBlue} size={16} />
          </Button>
        )}
        {!deleteDisabled && (
          <Button onClick={() => toggle(id)}>
            <RiDeleteBinLine color={Globals.colorRed} size={16} />
          </Button>
        )}
      </td>
    );

  let theadEl = (
    <thead>
      <tr>
        {tableHeadings.map((heading, idx) => {
          const char = "_";
          const charIndex = heading.indexOf(char);

          let label = heading;

          if (charIndex !== -1) {
            label = heading.split(char).join(" ");
          }

          return <th key={`th-item-${idx + 1}`}>{label}</th>;
        })}
        {tableActions}
      </tr>
    </thead>
  );

  let tbodyEl = (
    <tbody>
      {data?.ids?.map((id) => {
        return (
          <tr key={id} onClick={() => onItemClickHandler(id)}>
            {tableHeadings.map((heading, i) => {
              return (
                <td key={`table-data-item-${i}`}>
                  {allowedTypes.includes(typeof data?.entities[id][heading])
                    ? truncateString(data?.entities[id][heading], 40)
                    : null}
                </td>
              );
            })}
            {renderActionBtns(id)}
          </tr>
        );
      })}
    </tbody>
  );

  if (customTHead) {
    const theadWithProps = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a
      // typescript error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          tableHeadings,
          data,
        });
      }

      return child;
    });

    theadEl = <>{theadWithProps}</>;
  }

  if (customTBody) {
    const tbodyWithProps = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a
      // typescript error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          tableHeadings,
          allowedTypes,
          onItemClickHandler,
          renderActionBtns,
          data,
        });
      }

      return child;
    });

    tbodyEl = <>{tbodyWithProps}</>;
  }

  return (
    <div className={`${styles.container} ${customStyle}`}>
      <table>
        {theadEl}
        {tbodyEl}
      </table>
    </div>
  );
};

export default Table;
