import styles from "components/FPSpinner/FPSpinner.module.scss";

const FPSpinner = () => {
  return (
    <div className={styles.container}>
      <div className={styles.lds}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p>Just a moment. Checking your device</p>
    </div>
  );
};

export default FPSpinner;
