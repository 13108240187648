import Form from "views/Form";
import Modal from "./Modal";
import className from "classnames";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Button from "components/UI/Button/Button";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import ReactPortal from "components/ReactPortal/ReactPortal";
import FormElement from "components/Form/FormElement/FormElement";
import { useCreditCallAccountMutation } from "features/call_account/callAccountApiSlice";

import formStyles from "components/Form/Form.module.scss";
import styles from "components/UI/Modals/Modal.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const formConfig = {
  type: {
    title: "Choose transaction type",
    elementType: "select",
    elementConfig: {
      id: "type",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.select],
    validation: {
      required: true,
    },
    options: [
      { value: "", text: "-- select transaction type --" },
      { value: "credit", text: "Credit" },
      { value: "debit", text: "Debit" },
    ],
    valid: false,
    touched: false,
    error: {
      message: "Transaction type is required",
    },
  },
  amount: {
    title: "Amount to credit",
    elementType: "input",
    elementConfig: {
      id: "amount",
      type: "text",
      value: "",
      inputMode: "numeric",
      autoComplete: "off",
      placeholder: "",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: true,
      isNumeric: true,
    },
    valid: false,
    touched: false,
    error: {
      message: "Amount is required",
    },
  },
  description: {
    title: "Description",
    elementType: "textarea",
    elementConfig: {
      id: "description",
      rows: 4,
      placeholder: "Write a description for this transaction",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.textarea],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Description is required",
    },
  },
};

const form = new Form(formConfig);

const CreditCallAccount = ({ visible, close }) => {
  const ref = useRef();

  const params = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  const [creditAccount] = useCreditCallAccountMutation();

  useEffect(() => {
    if (visible) {
      form.createFormElements();
      setFormElements(form.formElements);
    }
  }, [visible]);

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    if (updatedInput) {
      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  const onClose = () => {
    close();
    form.clear();
    setFormValidity(false);
    form.setConfig(formConfig);
    form.createFormElements();
    setFormElements(form.formElements);
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    setIsLoading(true);

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        formData[key] = el.elementConfig.value;
      }

      try {
        await creditAccount({ accountId: params?.id,
           ...formData,
          amount: formData.amount * 1, }).unwrap();
        onClose();
        toast.success("Transaction initiated successfully");
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <ReactPortal containerElement="body">
      <CSSTransition
        in={visible}
        nodeRef={ref}
        classNames={{
          enter: "",
          enterActive: styles.scaleIn,
          exit: "",
          exitActive: styles.scaleOut,
        }}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit
      >
        <Modal
          ref={ref}
          title="Credit CallAccount"
          onClose={onClose}
          maxWidth="55rem"
        >
          <p>Fill in the details of the transaction</p>
          <div className={styles.wrapper}>
            <form className={formStyles.container}>
              {formElements.map((el, idx) => {
                const { title, elementType, elementConfig, ...config } =
                  el.config;

                return (
                  <div
                    key={`el-group-${idx + 1}`}
                    className={className({
                      [formStyles.group]: true,
                      [utilityStyles.column100]: elementType === "textarea",
                    })}
                  >
                    <FormElement
                      {...config}
                      title={title}
                      type={elementType}
                      id={elementConfig.id}
                      config={{
                        ...elementConfig,
                        onChange: inputChangeHandler,
                      }}
                    />
                  </div>
                );
              })}
            </form>
          </div>
          <div className={`${styles.actions} ${utilityStyles.justifyStart}`}>
            <Button
              type="button"
              className={styles.btnConfirm}
              onClick={submitFormHandler}
              disabled={isLoading || !formValidity}
            >
              {isLoading ? "Please wait..." : "Continue"}
            </Button>
          </div>
        </Modal>
      </CSSTransition>
    </ReactPortal>
  );
};

export default CreditCallAccount;
