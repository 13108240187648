import { createContext, useContext, useState } from "react";

const DeleteModalContext = createContext({
  itemId: null,
  visible: false,
  reset: () => {},
  isConfirmed: false,
  confirm: () => {},
  toggle: (id) => {},
});

export const useDeleteModalContext = () => useContext(DeleteModalContext);

const DeleteModalProvider = ({ children }) => {
  const [itemId, setItemId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const toggleModal = (id) => {
    setVisible((prev) => !prev);
    setItemId(id || null);
  };

  const onConfirm = () => {
    setVisible(false);
    setIsConfirmed(true);
  };

  const reset = () => {
    setIsConfirmed(false);
    setVisible(false);
    setItemId(null);
  };

  return (
    <DeleteModalContext.Provider
      value={{
        itemId,
        reset,
        visible,
        isConfirmed,
        confirm: onConfirm,
        toggle: toggleModal,
      }}
    >
      {children}
    </DeleteModalContext.Provider>
  );
};

export default DeleteModalProvider;
