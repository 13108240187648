import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import Spinner from "components/Spinner/Spinner";
import { selectAuthToken } from "features/auth/authSlice";
import { selectDevice } from "features/device/deviceSlice";
import { useVerifyAuthMutation } from "features/auth/authApiSlice";

const PersistAuth = () => {
  const [isLoading, setIsLoading] = useState(true);

  const token = useSelector(selectAuthToken);

  const device = useSelector(selectDevice);

  const { trust_device } = useSelector((state) => state.auth);

  const [verifyAuth, { error }] = useVerifyAuthMutation();

  useEffect(() => {
    let isMounted = true;

    const onVerifyAuth = async (fingerprintId) => {
      try {
        await verifyAuth(fingerprintId).unwrap();
      } catch (error) {
      } finally {
        if (isMounted && !error) {
          setIsLoading(false);
        }
      }
    };

    // avoid unecessary calls to verify auth
    if (device.fingerprint) {
      // avoid unecessary calls to verify auth
      if (!token && trust_device) {
        onVerifyAuth(device.fingerprint);
      } else {
        setIsLoading(false);
      }
    }

    return () => {
      isMounted = false;
    };
  }, [device.fingerprint, error, token, trust_device, verifyAuth]);

  return (
    <>{!trust_device ? <Outlet /> : isLoading ? <Spinner /> : <Outlet />}</>
  );
};

export default PersistAuth;
