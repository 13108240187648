import className from "classnames";
import { useSelector } from "react-redux";
import Navbar from "components/Navbar/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
import Spinner from "components/Spinner/Spinner";
import { selectSidebar } from "features/sidebar/sidebarSlice";

import styles from "components/BaseLayout/BaseLayout.module.scss";

const BaseLayout = ({ title, showSpinner, children }) => {
  const sidebar = useSelector(selectSidebar);

  if (showSpinner)
    return (
      <>
        <Spinner />
        {children}
      </>
    );

  const contentStyles = className({
    [styles.content]: true,
    [styles.translateX_left]: !sidebar.in,
  });

  return (
    <div className={styles.container}>
      <Sidebar />
      <main className={contentStyles}>
        <Navbar title={title} />
        <div className={styles.content__card}>
          {children || (
            <div className={styles.content__empty}>
              <p>No data to display</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default BaseLayout;
