import Form from "views/Form";
import className from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Globals } from "utils/styleconfig";
import Button from "components/UI/Button/Button";
import { RiArrowRightSLine } from "react-icons/ri";
import { useParams, useNavigate, createSearchParams } from "react-router-dom";
import { formConfig } from "pages/Users/EditUser/formConfig";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useEffect, useLayoutEffect, useState } from "react";
import FormElement from "components/Form/FormElement/FormElement";
import { selectAllResults } from "features/search/searchSlice";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import { searchHandler } from "models/search";
import { dropdownItemSelector } from "models/form";
import { selectAuthUser } from "features/auth/authSlice";
import { actions, parseError } from "utils/helpers";
import { toast } from "react-hot-toast";
import * as apiLabel from "models/apiLabels";
import NewCredentialModal from "components/UI/Modals/NewCredential";

import {
  useGetUserQuery,
  useUpdateUserMutation,
} from "features/user/userApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const EditUser = ({ label }) => {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { data: user } = useGetUserQuery(params?.id);

  const authUser = useSelector(selectAuthUser);

  const [activeInput, setActiveInput] = useState("");

  const [toggleModal, setToggleModal] = useState(false);
  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(true);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [initialDataValues, setInitialDataValues] = useState([]);

  const searchResults = useSelector(selectAllResults);

  const [updateUser, { isLoading }] = useUpdateUserMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Edit User"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    if (user) {
      const dataValues = [];
      const updatedFormConfig = JSON.parse(JSON.stringify(formConfig));

      Object.entries(user).map(([key, value]) => {
        if (updatedFormConfig[key]) {
          updatedFormConfig[key].elementConfig.value = value;
          dataValues.push(String(value)); // Convert all values to strings to allow comparison
        }

        return null;
      });

      form.setConfig(updatedFormConfig);
      form.createFormElements();
      setFormElements(form.formElements);
      setInitialDataValues(dataValues);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const onViewAccountsBtnHandler = () => {
    navigate({
      pathname: `/users/${params.id}/accounts`,
      search: createSearchParams({ page: "1" }).toString(),
    });
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity && authUser?.actions[label].includes(actions.update)) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (key.split("_")[1] === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          formData[key] = el.elementConfig.value;
        }
      }

      try {
        await updateUser({
          userId: user.id,
          ...formData,
        }).unwrap();

        toast.success("User updated successfully");
        navigate({
          pathname: "/users",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      let isFormChanged = true;
      const updatedFormValues = [];

      updatedFormValues.push(e.target.value);

      if (e.target.id === "role_id") {
        searchHandler("/roles", e.target.value, dispatch);
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      isFormChanged =
        updatedFormValues.every((value) => initialDataValues.includes(value)) &&
        isFormChanged;

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
      setIsChangesMade(!isFormChanged);
    }
  };

  const onCreateCredentials = () => {
    setTimeout(() => {
      setToggleModal(true);
    }, 100);
  };

  let buttonEl = null;
  let accountBtnEl = null;
  let userLoginBtnEl = null;

  if (authUser?.actions[apiLabel.USER_LOGINS].includes(actions.create)) {
    userLoginBtnEl = (
      <Button
        type="button"
        className={`${formStyles.btnRed} ${utilityStyles.ml_1}`}
        disabled={isLoading}
        onClick={onCreateCredentials}
      >
        Create Credentials
      </Button>
    );
  }

  if (authUser?.actions[label].includes(actions.update)) {
    buttonEl = (
      <div className={formStyles.btn}>
        <Button type="submit" disabled={isLoading || !formValidity || !isChangesMade}>
          {isLoading ? "Saving..." : "Save Changes"}
        </Button>
        {userLoginBtnEl}
      </div>
    );
  }

  if (authUser?.actions[apiLabel.ACCOUNTS].includes(actions.view)) {
    accountBtnEl = (
      <button
        onClick={onViewAccountsBtnHandler}
        className={utilityStyles.outline__button}
      >
        <span>View user accounts</span>
        <RiArrowRightSLine color={Globals.colorDarkBlue} size={16} />
      </button>
    );
  }

  return (
    <>
      <NewCredentialModal
        visible={toggleModal}
        close={() => setToggleModal(false)}
      />
      <div
        className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
      >
        <div
          className={className({
            [cardStyles.menu]: true,
            [utilityStyles.wFull]: true,
          })}
        >
          <h3>Changes made to this user will overwrite the existing info</h3>
          {accountBtnEl}
        </div>
        <div className={formStyles.wrapper}>
          <form className={formStyles.container} onSubmit={submitFormHandler}>
            {formElements.map((el, idx) => {
              const { title, elementType, elementConfig, ...config } =
                el.config;

              return (
                <div key={`el-group-${idx + 1}`} className={formStyles.group}>
                  <FormElement
                    {...config}
                    title={title}
                    type={elementType}
                    id={elementConfig.id}
                    config={{
                      ...elementConfig,
                      onChange: inputChangeHandler,
                    }}
                  />
                  <FormDropdown
                    items={searchResults}
                    itemClickHandler={(item) =>
                      dropdownItemClickHandler(elementConfig.id, item)
                    }
                    visible={
                      activeInput === elementConfig.id && el.config.dropdown
                    }
                  />
                </div>
              );
            })}
            {buttonEl}
          </form>
        </div>
      </div>
    </>
  );
};

export default EditUser;
