import App from "App";
import React from "react";
import { store } from "app/store";
import Auth from "pages/Auth/Auth";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "pages/Dashboard/Dashboard";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import UserMenu from "components/UserMenu/UserMenu";
import { setTrustDevice } from "features/auth/actions";
import DeleteModalProvider from "context/DeleteModalContext";
import PersistAuth from "components/PersistAuth/PersistAuth";
import RequireAuth from "components/RequireAuth/RequireAuth";
import VerifyPayment from "pages/VerifyPayment/VerifyPayment";
import AccountSettings from "pages/AccountSettings/AccountSettings";
import ProtectedRoutes from "components/ProtectedRoutes/ProtectedRoutes";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import { routes } from "routes";

import "react-loading-skeleton/dist/skeleton.css";

import "./index.scss";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} errorElement={<ErrorPage />}>
      <Route path="/" element={<Dashboard />} />
      <Route path="/auth" element={<Auth />} />
      <Route element={<PersistAuth />}>
        <Route element={<RequireAuth />}>
          {routes.map((route, i) => {
            const Component = route.element;

            return (
              <Route
                key={i}
                path={route.path}
                element={
                  <ProtectedRoutes label={route.label} action={route.action}>
                    <Component label={route.label} />
                  </ProtectedRoutes>
                }
              />
            );
          })}
          <Route
            path="/users/call_accounts/:account_id/recharge/verify/:reference_code"
            element={<VerifyPayment />}
          />
          <Route path="/user/menu/:view?" element={<UserMenu />} />
          <Route path="/me" element={<AccountSettings />} />
        </Route>
      </Route>
    </Route>
  )
);

store.dispatch(
  setTrustDevice(JSON.parse(localStorage.getItem("trust_device")) || false)
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
    
        <Toaster />
        <DeleteModalProvider>
          <RouterProvider router={router} />
        </DeleteModalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
