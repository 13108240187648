import { clearResults } from "features/search/searchSlice";
import { searchApiSlice } from "features/search/searchApiSlice";

export const searchHandler = (apiRoute, text, dispatch, extraOptions = {}) => {
  let value = text;

  dispatch(clearResults());

  const extraParams = new URLSearchParams(extraOptions).toString();

  setTimeout(() => {
    dispatch(
      searchApiSlice.endpoints.search.initiate(
        { apiPath: `${apiRoute}?search=${value}&${extraParams}` },
        { subscribe: false, forceRefetch: true }
      )
    );
  }, 500);
};
