import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const sipProfileAdapter = createEntityAdapter({});

const initialState = sipProfileAdapter.getInitialState({ size: 0 });

export const sipProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSipProfiles: builder.query({
      query: (searchParams) =>
        `/sip_profiles?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response.results.length)
          return sipProfileAdapter.setAll(initialState, []);

        const fetchedSipProfiles = response.results.map((sipprofile) => {
          const {
            created_by_id,
            profile_id,
            enabled,
            created_by,
            created_at,
            deleted_at,
            updated_at,
            parameters,
            ...sipprofileProps
          } = sipprofile;

          sipprofileProps["enabled"] = enabled ? "Yes" : "No";

          sipprofileProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            ...sipprofileProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return sipProfileAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedSipProfiles
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "SipProfile", id })),
              { type: "SipProfile", id: "PARTIAL-LIST" },
            ]
          : [{ type: "SipProfile", id: "PARTIAL-LIST" }],
    }),
    getSipProfile: builder.query({
      query: (id) => `/sip_profiles/${id}`,
      providesTags: (result, error, id) => [{ type: "SipProfile", id }],
    }),
    createSipProfile: builder.mutation({
      query: (data) => ({
        url: "/sip_profiles",
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidateTags: [{ type: "SipProfile", id: "PARTIAL-LIST" }],
    }),
    updateSipProfile: builder.mutation({
      query: ({ sipProfileId, ...data }) => ({
        url: `/sip_profiles/${sipProfileId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: sipProfileId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "SipProfile", id: args.id },
      ],
    }),
    deleteSipProfile: builder.mutation({
      query: (id) => ({
        url: `/sip_profiles/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "SipProfile", id: args.id },
        { type: "SipProfile", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetSipProfileQuery,
  useGetSipProfilesQuery,
  useCreateSipProfileMutation,
  useDeleteSipProfileMutation,
  useUpdateSipProfileMutation,
} = sipProfileApiSlice;
