import moment from "moment";

export const truncateString = (str = "", maxLength) => {
  if (typeof str !== "string") return str;

  const lengthOfString = str.trim().length;

  if (lengthOfString > maxLength) {
    return str.slice(0, maxLength + 1) + "...";
  }

  return str;
};

export const formatDate = (date, format = "ll") => {
  if (!date) {
    return undefined;
  }

  return moment(date).format(format);
};

export const NUM_FORM_ITEMS = 2;

export const capitalize = (str) => {
  if (typeof str !== "string") return "";
  const strArr = str.split(" ");
  let newString = "";

  strArr.forEach((s, i) => {
    if (strArr.length - 1 === i) {
      newString += s.charAt(0).toUpperCase() + s.slice(1);
    } else {
      newString += s.charAt(0).toUpperCase() + s.slice(1) + " ";
    }
  });

  return newString;
};

export const toBoolean = (str) => {
  if (typeof str !== "string") return false;

  if (str === "true") return true;

  if (str === "false") return false;
};

export const ACCOUNT_TYPES = (checkid = false) => [
  `provider${checkid ? "_id" : ""}`,
  `user${checkid ? "_id" : ""}`,
];

const create = "create";
const list = "list";
const view = "view";
const update = "update";

export const actions = { create, list, view, update, delete: "delete" };

export const parseError = (error) => {
  if (error instanceof Error) {
    return error.message;
  }

  return "Oops! An unexpected error occured";
};

export const generateRandomNum = (max = 100000000) => {
  return Math.floor(Math.random() * max + 1);
};
