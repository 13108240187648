import className from "classnames";
import Button from "../Button/Button";
import { RiCloseLine } from "react-icons/ri";
import { Globals } from "utils/styleconfig";
import React from "react";

import styles from "components/UI/Modals/Modal.module.scss";

const Modal = React.forwardRef(
  ({ position = "top", title, children, onClose, maxWidth }, ref) => {
    return (
      <div
        className={className({
          [styles.overlay]: true,
          [styles.positionTop]: position === "top",
          [styles.positionCenter]: position === "center",
        })}
      >
        <div style={{ width: maxWidth }} ref={ref} className={styles.container}>
          <div className={styles.header}>
            <h2>{title}</h2>
            <Button onClick={onClose}>
              <RiCloseLine color={Globals.colorGray} size="23" />
            </Button>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    );
  }
);

export default Modal;
