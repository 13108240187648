import { Fragment, useCallback, useEffect } from "react";
import Table from "components/Table/Table";
import { UNAUTHORIZED } from "constants";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import { selectAuthUser } from "features/auth/authSlice";
import { actions } from "utils/helpers";
import { toast } from "react-hot-toast";
import {
  useGetExtensionsQuery,
  useDeleteExtensionMutation,
} from "features/extension/extensionApiSlice";

import cardStyles from "styles/modules/Card.module.scss";

const Extensions = ({ label }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const authUser = useSelector(selectAuthUser);

  const [searchParams] = useSearchParams();

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetExtensionsQuery(searchParams.toString());

  const [deleteExtension] = useDeleteExtensionMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Extensions"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load extensions, try refreshing the page");
    }
  }, [isError]);

  const onEditBtnHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/extensions/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate]
  );

  const onDeleteBtnHandler = useCallback(
    async (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.delete)) {
        if (callFn) {
          await deleteExtension(id).unwrap();
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, deleteExtension, label]
  );

  return (
    <div>
      <div className={cardStyles.menu}>
        <h3>Showing data for extensions</h3>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table
            data={currentData}
            onEdit={onEditBtnHandler}
            onDelete={onDeleteBtnHandler}
          />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </div>
  );
};

export default Extensions;
