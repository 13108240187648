import { apiSlice } from "features/api/apiSlice";
import authReducer from "features/auth/authSlice";
import { configureStore } from "@reduxjs/toolkit";
import deviceReducer from "features/device/deviceSlice";
import navbarReducer from "features/navbar/navbarSlice";
import searchReducer from "features/search/searchSlice";
import sidebarReducer from "features/sidebar/sidebarSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    search: searchReducer,
    device: deviceReducer,
    navbar: navbarReducer,
    sidebar: sidebarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: process.env.NODE_ENV === "development" ? true : false,
});
