import { createSlice } from "@reduxjs/toolkit";
import { authApiSlice } from "features/auth/authApiSlice";
import { setCredentials, logOut, setTrustDevice } from "features/auth/actions";

const initialState = {
  token: null,
  user: null,
  trust_device: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCredentials, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    });
    builder.addCase(logOut, (state) => {
      state.token = null;
      state.user = null;
    });
    builder.addCase(setTrustDevice, (state, action) => {
      state.trust_device = action.payload;
    });
    builder.addMatcher(
      authApiSlice.endpoints.verifyAuth.matchFulfilled,
      (state, action) => {
        state.token = action.payload.tokens?.accessToken;
        state.user = action.payload?.user;
      }
    );
  },
});

export const selectAuthToken = (state) => state.auth.token;
export const selectAuthUser = (state) => state.auth.user;

export default authSlice.reducer;
