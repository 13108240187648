import { apiSlice } from "features/api/apiSlice";

export const rechargeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    rechargeAccount: builder.mutation({
      query: ({ call_billing_account_id, ...data }) => ({
        url: `/users/call_accounts/${call_billing_account_id}/recharge`,
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      }),
    }),
  }),
});

export const { useRechargeAccountMutation } = rechargeApiSlice;
