import { formatDate } from "utils/helpers";
import { RESULTS_PER_PAGE } from "constants";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";

const didAdapter = createEntityAdapter({});

const initialState = didAdapter.getInitialState({ size: 0 });

export const didApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDIDs: builder.query({
      query: (searchParams) => `/dids?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results) return didAdapter.setAll(initialState, []);

        const fetchedDIDs = response.results.map((did) => {
          const {
            created_by,
            account_id,
            call_billing_account_id,
            simultaneous_extension,
            call_type,
            enabled,
            created_at,
            updated_at,
            always_vm_flag,
            connectcost,
            includedseconds,
            cost,
            maxchannels,
            init_inc,
            inc,
            leg_timeout,
            ringback,
            user_busy_vm_flag,
            user_not_registered_vm_flag,
            no_answer_vm_flag,
            call_type_vm_flag,
            call_type_id,
            created_by_id,
            ...didProps
          } = did;


          didProps["enabled"] = enabled ? "Yes" : "No";

          didProps["simultaneous_extension"] = simultaneous_extension ?
            "Yes" :
            "No";

          didProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;


          return {
            ...didProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return didAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedDIDs
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "DID", id })),
              { type: "DID", id: "PARTIAL-LIST" },
            ]
          : [{ type: "DID", id: "PARTIAL-LIST" }],
    }),
    getDID: builder.query({
      query: (id) => `/dids/${id}`,
      providesTags: (result, error, id) => [{
        type: "DID",
        id
      }],
    }),
    createDID: builder.mutation({
      query: (data) => ({
        url: "/dids",
        method: "POST",
        body: JSON.stringify({
          ...data
        }),
      }),
      invalidateTags: [{ type: "DID", id: "PARTIAL-LIST" }],
    }),
    updateDID: builder.mutation({
      query: ({
        didId,
        ...data
      }) => ({
        url: `/dids/${didId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: didId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [{
        type: "DID",
        id: args.id
      }],
    }),
    deleteDID: builder.mutation({
      query: (id) => ({
        url: `/dids/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "DID", id: args.id },
        { type: "DID", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetDIDQuery,
  useGetDIDsQuery,
  useUpdateDIDMutation,
  useCreateDIDMutation,
  useDeleteDIDMutation,
} = didApiSlice;
