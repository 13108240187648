import Form from "views/Form";
import className from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import FormElement from "components/Form/FormElement/FormElement";
import { formConfig } from "pages/SipProfiles/EditSipProfile/formConfig";
import { selectAuthUser } from "features/auth/authSlice";
import { actions, parseError } from "utils/helpers";
import { toast } from "react-hot-toast";
import {
  useGetSipProfileQuery,
  useUpdateSipProfileMutation,
} from "features/sip_profile/sipprofileApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const EditSipProfile = ({ label }) => {
  const params = useParams();

  const authUser = useSelector(selectAuthUser);

  const { data: sipProfile } = useGetSipProfileQuery(params?.id);

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(true);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [initialDataValues, setInitialDataValues] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateSipProfile, { isLoading }] = useUpdateSipProfileMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Edit Sip Profile"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    if (sipProfile) {
      const dataValues = [];
      const updatedFormConfig = JSON.parse(JSON.stringify(formConfig));

      Object.entries(sipProfile).map(([key, value]) => {
        if (updatedFormConfig[key]) {
          updatedFormConfig[key].elementConfig.value = value;
          dataValues.push(String(value)); // Convert all values to strings to allow comparison
        }

        return null;
      });

      form.setConfig(updatedFormConfig);
      form.createFormElements();
      setFormElements(form.formElements);
      setInitialDataValues(dataValues);
    }
  }, [sipProfile]);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity && authUser?.actions[label].includes(actions.update)) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        formData[key] = el.elementConfig.value;
      }

      try {
        await updateSipProfile({
          sipProfileId: sipProfile.id,
          ...formData,
        }).unwrap();

        toast.success("SipProfile updated successfully");
        navigate({
          pathname: "/sip_profiles",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    if (updatedInput) {
      let isFormChanged = true;
      const updatedFormValues = [];

      updatedFormValues.push(e.target.value);

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      isFormChanged =
        updatedFormValues.every((value) => initialDataValues.includes(value)) &&
        isFormChanged;

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
      setIsChangesMade(!isFormChanged);
    }
  };

  let buttonEl = null;

  if (authUser?.actions[label].includes(actions.update)) {
    buttonEl = (
      <div className={formStyles.btn}>
        <button disabled={isLoading || !formValidity || !isChangesMade}>
          {isLoading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    );
  }

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>
          Changes made to this sip profile will overwrite the existing info
        </h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div
                key={`el-group-${idx + 1}`}
                className={className({
                  [formStyles.group]: true,
                  [utilityStyles.column100]: elementType === "textarea",
                })}
              >
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
            );
          })}
          {buttonEl}
        </form>
      </div>
    </div>
  );
};

export default EditSipProfile;
