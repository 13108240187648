import { useSelector } from "react-redux";
import { Globals } from "utils/styleconfig";
import * as apiLabel from "models/apiLabels";
import { selectAuthUser } from "features/auth/authSlice";
import SidebarListItem from "components/Sidebar/SidebarList/SidebarListItem/SidebarListItem";

import {
  RiText,
  RiPhoneFill,
  RiServerFill,
  RiTrelloFill,
  RiProfileFill,
  RiUserAddFill,
  RiBuildingFill,
  RiContactsFill,
  RiPriceTag2Fill,
  RiMapPinUserFill,
  RiAccountCircleFill,
  RiRemoteControlFill,
  RiSettings4Fill,
  RiFileChartFill,
} from "react-icons/ri";

import styles from "components/Sidebar/Sidebar.module.scss";
import { Fragment, useMemo } from "react";
import Button from "components/UI/Button/Button";
import { useNavigate } from "react-router-dom";

const list = [
  {
    icon: <RiAccountCircleFill color={Globals.colorDarkBlue} size={16} />,
    title: "Accounts",
    label: apiLabel.ACCOUNTS,
    dropdown: true,
    sublist: [
      { title: "All Accounts", gotoPath: "/accounts?page=1", action: "list" },
      { title: "Add new Account", gotoPath: "/accounts/new", action: "create" },
    ],
  },

  {
    icon: <RiContactsFill color={Globals.colorDarkBlue} size={15} />,
    title: "Account CallerIds",
    dropdown: true,
    label: apiLabel.ACCOUNT_CALLER_IDS,
    sublist: [
      { title: "All CallerIds", gotoPath: "/callerids?page=1", action: "list" },
      {
        title: "Add new CallerId",
        gotoPath: "/callerids/new",
        action: "create",
      },
    ],
  },
  {
    icon: <RiPhoneFill color={Globals.colorDarkBlue} size={15} />,
    title: "Call Accounts",
    label: apiLabel.CALL_ACCOUNTS,
    dropdown: true,
    sublist: [
      {
        title: "All Call Accounts",
        gotoPath: "/call_accounts?page=1",
        action: "list",
      },
      {
        title: "Add new Call Account",
        gotoPath: "/call_accounts/new",
        action: "create",
      },
    ],
  },
  {
    icon: <RiFileChartFill color={Globals.colorDarkBlue} size={15} />,
    title: "Call Records",
    label: apiLabel.CALL_RECORDS,
    dropdown: true,
    sublist: [
      {
        title: "User Call Records",
        gotoPath: "/call_records/users?page=1",
        action: "list",
      },
      {
        title: "Provider Call Records",
        gotoPath: "/call_records/providers?page=1",
        action: "list",
      },
    ],
  },
  {
    icon: <RiRemoteControlFill color={Globals.colorDarkBlue} size={15} />,
    title: "DIDs",
    dropdown: true,
    label: apiLabel.DIDS,
    sublist: [
      { title: "All DIDs", gotoPath: "/dids?page=1", action: "list" },
      { title: "Add new DID", gotoPath: "/dids/new", action: "create" },
    ],
  },
  {
    icon: <RiText color={Globals.colorDarkBlue} size={15} />,
    title: "Trunks",
    dropdown: true,
    label: apiLabel.TRUNKS,
    sublist: [
      { title: "All Trunks", gotoPath: "/trunks?page=1", action: "list" },
      { title: "Add new Trunk", gotoPath: "/trunks/new", action: "create" },
    ],
  },
  {
    icon: <RiServerFill color={Globals.colorDarkBlue} size={16} />,
    title: "Domains",
    gotoPath: "/domains",
    action: "list",
    sublist: [],
    label: apiLabel.DOMAINS,
  },
  {
    icon: <RiBuildingFill color={Globals.colorDarkBlue} size={16} />,
    title: "Gateways",
    dropdown: true,
    label: apiLabel.GATEWAYS,
    sublist: [
      { title: "All Gateways", gotoPath: "/gateways?page=1", action: "list" },
      { title: "Add new Gateway", gotoPath: "/gateways/new", action: "create" },
    ],
  },
  {
    icon: <RiProfileFill color={Globals.colorDarkBlue} size={16} />,
    title: "Sip Profiles",
    dropdown: true,
    label: apiLabel.SIP_PROFILES,
    sublist: [
      {
        title: "All Sip Profiles",
        gotoPath: "/sip_profiles?page=1",
        action: "list",
      },
      {
        title: "Add new Sip Profile",
        gotoPath: "/sip_profiles/new",
        action: "create",
      },
    ],
  },
  {
    icon: <RiTrelloFill color={Globals.colorDarkBlue} size={16} />,
    title: "Extensions",
    dropdown: true,
    label: apiLabel.EXTENSIONS,
    sublist: [
      {
        title: "All Extensions",
        gotoPath: "/extensions?page=1",
        action: "list",
      },
      {
        title: "Add new Extension",
        gotoPath: "/extensions/new",
        action: "create",
      },
    ],
  },
  {
    icon: <RiMapPinUserFill color={Globals.colorDarkBlue} size={16} />,
    title: "Localizations",
    label: apiLabel.LOCALIZATIONS,
    dropdown: true,
    sublist: [
      {
        title: "All Localizations",
        gotoPath: "/localizations?page=1",
        action: "list",
      },
      {
        title: "Add new Localization",
        gotoPath: "/localizations/new",
        action: "create",
      },
    ],
  },
  {
    icon: <RiPriceTag2Fill color={Globals.colorDarkBlue} size={16} />,
    title: "Pricelists",
    label: apiLabel.PRICELISTS,
    dropdown: true,
    sublist: [
      {
        title: "All Pricelists",
        gotoPath: "/pricelists?page=1",
        action: "list",
      },
      {
        title: "Add new Pricelist",
        gotoPath: "/pricelists/new",
        action: "create",
      },
    ],
  },
  {
    icon: <RiUserAddFill color={Globals.colorDarkBlue} size={15} />,
    title: "Users",
    label: apiLabel.USERS,
    dropdown: true,
    sublist: [
      { title: "All Users", gotoPath: "/users?page=1", action: "list" },
      { title: "Add new User", gotoPath: "/users/new", action: "create" },
    ],
  },
];

const SidebarList = () => {
  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);

  const renderedList = useMemo(() => {
    if (typeof authUser?.role?.name === "string") {
      if (authUser.role.name.toLowerCase() === "user") {
        return [];
      }
    }

    const newList = list.filter((item) => {
      if (
        !item.sublist.length &&
        !authUser?.actions?.[item.label]?.includes(item.action)
      ) {
        return false;
      }

      item.sublist = item.sublist.filter((subItem) => {
        if (!authUser?.actions?.[item.label]?.includes(subItem.action)) {
          return false;
        }

        return true;
      });

      return true;
    });

    return newList;
  }, [authUser]);

  return (
    <Fragment>
      <ul className={styles.list}>
        {renderedList.map((item, idx) => {
          return (
            <SidebarListItem item={item} key={`item-${idx}`}>
              <span className={styles.rounded}>{item.icon}</span>
            </SidebarListItem>
          );
        })}
      </ul>
      <div className={styles.footer}>
        <Button onClick={() => navigate("/me")}>
          <RiSettings4Fill size={16} color={Globals.colorDarkBlue} />
          <span>Account Settings</span>
        </Button>
      </div>
    </Fragment>
  );
};

export default SidebarList;
