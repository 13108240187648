import styles from "components/Form/Form.module.scss";

export const formConfig = {
  firstname: {
    title: "First Name",
    elementType: "input",
    elementConfig: {
      id: "firstname",
      type: "text",
      value: "",
      placeholder: "Russel",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Firstname is required",
    },
  },
  lastname: {
    title: "Last Name",
    elementType: "input",
    elementConfig: {
      id: "lastname",
      type: "text",
      value: "",
      placeholder: "David",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Lastname is required",
    },
  },
  email: {
    title: "Email Address",
    elementType: "input",
    elementConfig: {
      id: "email",
      type: "email",
      value: "",
      placeholder: "JohnDoe@example.com",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Email address is required",
    },
  },
  phone: {
    title: "Phone Number",
    elementType: "input",
    elementConfig: {
      id: "phone",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Phone number is required",
    },
  },
  type: {
    title: "Type of User",
    elementType: "select",
    elementConfig: {
      id: "type",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    options: [
      { value: "", text: "-- select user type --" },
      { value: "admin", text: "Admin" },
      { value: "agent", text: "Agent" },
      { value: "user", text: "User" },
    ],
    error: {
      message: "Please select user type",
    },
  },
  role_id: {
    title: "Role",
    elementType: "input",
    elementConfig: {
      id: "role_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search role id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    dropdown: true,
    error: {
      message: "Role is required",
    },
  },
};
