import { ACCOUNT_TYPES, capitalize } from "utils/helpers";

export const updateAccountTypeId = (formConfig, refrenceInput) => {
  // Get form config;
  const oldFormConfig = JSON.parse(JSON.stringify(formConfig));

  let newFormConfig = {};

  Object.entries(oldFormConfig).forEach(([key, value]) => {
    if (
      ACCOUNT_TYPES(true).includes(key) ||
      key === `${refrenceInput?.elementConfig?.id}_id`
    ) {
      if (refrenceInput?.valid) {
        oldFormConfig[key].elementConfig = {
          ...oldFormConfig[key].elementConfig,
          id: `${refrenceInput.elementConfig.value}_id`,
          placeholder: `Search ${refrenceInput.elementConfig.value} Id`,
        };

        oldFormConfig[key].title = `${capitalize(
          refrenceInput?.elementConfig?.value
        )} Id ${
          refrenceInput.elementConfig.value === "provider" ? "(optional)" : ""
        }`;

        oldFormConfig[key].valid =
          refrenceInput.elementConfig.value === "provider" ? true : false;

        // change the default type_id field
        newFormConfig[`${refrenceInput.elementConfig.value}_id`] = {
          ...oldFormConfig[key],
        };
      } else {
        newFormConfig[key] = { ...value };
      }
    } else {
      newFormConfig[key] = { ...value };
    }
  });

  return newFormConfig;
};
