import { selectAuthUser } from "features/auth/authSlice";
import React, { useMemo } from "react";
import { routes } from "routes";
import { actions } from "utils/helpers";
import { useSelector } from "react-redux";
import { Navigate, createSearchParams } from "react-router-dom";

import logoStyles from "components/Logo/Logo.module.scss";
import searchStyles from "components/Search/Search.module.scss";
import navbarStyles from "components/Navbar/Navbar.module.scss";
import sidebarStyles from "components/Sidebar/Sidebar.module.scss";
import layoutStyles from "components/BaseLayout/BaseLayout.module.scss";

import Skeleton from "react-loading-skeleton";

const Dashboard = () => {
  const authUser = useSelector(selectAuthUser);

  const userActions = authUser?.actions;

  const indexPage = useMemo(() => {
    if (userActions) {
      return routes.find(
        (route) =>
          userActions[route.label] &&
          userActions[route.label].includes(actions.list) &&
          route.index
      );
    }
  }, [userActions]);

  if (!indexPage) {
    return <Navigate to="/user/menu" />;
  }

  return (
    <>
      <Navigate
        to={{
          pathname: indexPage.path,
          search: createSearchParams({ page: 1 }).toString(),
        }}
      />
      <div className={layoutStyles.container}>
        <div className={sidebarStyles.overlay}>
          <div className={sidebarStyles.container}>
            <div className={sidebarStyles.wrapper}>
              <div className={logoStyles.container}>
                <Skeleton width={30} height={30} circle />
                <h1>
                  <Skeleton width={200} borderRadius="0.8rem" />
                </h1>
              </div>
              <Skeleton height="100vh" borderRadius="0.8rem" />
            </div>
          </div>
        </div>
        <main className={layoutStyles.content}>
          <nav className={navbarStyles.container}>
            <div className={navbarStyles.heading}>
              <h2>
                <Skeleton width={300} borderRadius="0.8rem" />
              </h2>
            </div>
            <div className={searchStyles.container}>
              <Skeleton width={500} height={45} borderRadius="0.8rem" />
            </div>
            <div className={navbarStyles.menu}>
              <Skeleton width={40} height={40} borderRadius="0.8rem" />
              <Skeleton width={40} height={40} borderRadius="0.8rem" />
              <Skeleton width={40} height={40} borderRadius="0.8rem" />
            </div>
          </nav>
          <Skeleton height="100vh" borderRadius="0.8rem" />
        </main>
      </div>
    </>
  );
};

export default Dashboard;
