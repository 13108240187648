import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table/Table";
import { toast } from "react-hot-toast";
import { UNAUTHORIZED } from "constants";
import { Globals } from "utils/styleconfig";
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import { actions } from "utils/helpers";
import { selectAuthUser } from "features/auth/authSlice";
import {
  useDeleteDeviceMutation,
  useGetDevicesByExtensionQuery,
} from "features/device/deviceApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const ExtensionDevices = ({ label }) => {
  const params = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);

  const { currentData, isLoading, isError } = useGetDevicesByExtensionQuery(
    params?.id,
    {
      selectFromResult: ({ currentData, isError, isLoading }) => ({
        isError,
        isLoading,
        currentData,
      }),
    }
  );

  const [deleteDevice] = useDeleteDeviceMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Extension Devices"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load extension devices, try refreshing the page");
    }
  }, [isError]);

  let buttonEl = null;

  const onAddBtnHandler = useCallback(() => {
    if (authUser?.actions[label].includes(actions.create)) {
      navigate(`/extensions/${params?.id}/devices/new`);
    }
  }, [authUser?.actions, label, navigate, params?.id]);

  const onEditHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/extensions/${params?.id}/devices/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate, params?.id]
  );

  const onDeleteHandler = useCallback(
    async (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.delete)) {
        if (callFn) {
          await deleteDevice({
            extensionId: params?.id,
            deviceId: id,
          }).unwrap();
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, deleteDevice, label, params?.id]
  );

  if (authUser?.actions[label].includes(actions.create)) {
    buttonEl = (
      <button
        onClick={onAddBtnHandler}
        className={utilityStyles.outline__button}
      >
        <span>Add new Device</span>
        <RiArrowRightSLine color={Globals.colorDarkBlue} size={16} />
      </button>
    );
  }

  return (
    <div>
      <div className={cardStyles.menu}>
        <h3>Showing data for extension devices</h3>
        <div
          className={`${utilityStyles.flexRow} ${utilityStyles.itemsCenter}`}
        >
          {buttonEl}
        </div>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          data={currentData}
          onEdit={onEditHandler}
          onDelete={onDeleteHandler}
        />
      )}
    </div>
  );
};

export default ExtensionDevices;
