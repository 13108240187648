import { SiWebmoney } from "react-icons/si";
import { Globals } from "utils/styleconfig";

import styles from "components/Logo/Logo.module.scss";

const Logo = () => {
  return (
    <div className={styles.container}>
      <SiWebmoney color={Globals.colorDarkBlue} size={22} />
      <h1>Asa Billing Dashboard</h1>
    </div>
  );
};

export default Logo;
