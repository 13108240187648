import styles from "components/Form/Form.module.scss";

export const formConfig = {
  call_billing_account_id: {
    title: "Select the Account",
    elementType: "input",
    elementConfig: {
      id: "call_billing_account_id",
      type: "text",
      "data-id": "",
      value: "",
      placeholder: "Choose user line",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: false,
    touched: false,
    error: {
      message: "User line is required",
    },
  },
  amount: {
    title: "Amount to credit",
    elementType: "input",
    elementConfig: {
      id: "amount",
      type: "text",
      inputMode: "numeric",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
      isNumeric: true,
    },
    valid: false,
    touched: false,
    error: {
      message: "Amount is required",
    },
  },
};
