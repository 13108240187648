import { useRef } from "react";
import Button from "../Button/Button";
import Modal from "./Modal";
import { CSSTransition } from "react-transition-group";
import ReactPortal from "components/ReactPortal/ReactPortal";
import { useDeleteModalContext } from "context/DeleteModalContext";

import styles from "components/UI/Modals/Modal.module.scss";

const DeleteModal = () => {
  const ref = useRef();

  const { visible, toggle, confirm } = useDeleteModalContext();

  return (
    <ReactPortal containerElement="body">
      <CSSTransition
        in={visible}
        nodeRef={ref}
        classNames={{
          enter: "",
          enterActive: styles.animFadeInTop,
          exit: "",
          exitActive: styles.animFadeOutTop,
        }}
        timeout={{ enter: 500, exit: 300 }}
        unmountOnExit
      >
        <Modal
          ref={ref}
          title="Delete"
          position="top"
          maxWidth="44rem"
          onClose={toggle}
        >
          <p style={{ textAlign: "center" }}>
            Are you sure you want to permanently delete this?
          </p>
          <div className={styles.actions}>
            <Button onClick={() => toggle()} className={styles.btnClose}>
              No, Cancel
            </Button>
            <Button onClick={() => confirm()} className={styles.btnConfirm}>
              Confirm
            </Button>
          </div>
        </Modal>
      </CSSTransition>
    </ReactPortal>
  );
};

export default DeleteModal;
