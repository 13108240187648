import className from "classnames";
import { Globals } from "utils/styleconfig";
import { RESULTS_PER_PAGE } from "constants";
import Button from "components/UI/Button/Button";
import { useSearchParams } from "react-router-dom";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";

import styles from "components/Pagination/Pagination.module.scss";

const Pagination = ({
  urlParam = "page",
  onPageChange = () => {},
  totalCount = 0,
}) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get(urlParam)
    ? Number(searchParams.get(urlParam))
    : null;

  const totalDocCount = Math.ceil(totalCount / RESULTS_PER_PAGE);

  const onBack = () => {
    if (page < 2) {
      return;
    }

    onPageChange(page - 1);
    setSearchParams({ [urlParam]: page - 1 });
  };

  const onNext = () => {
    if (page < totalDocCount) {
      onPageChange(page + 1);
      setSearchParams({ [urlParam]: page + 1 });
    }
  };

  return (
    <div className={styles.container}>
      <span>
        Page {page} of {totalDocCount}
      </span>
      <div className={styles.controls}>
        <Button
          className={className({ [styles.disabled]: page === 1 })}
          disabled={page === 1}
          onClick={onBack}
        >
          <RiArrowLeftSLine color={Globals.colorDarkBlue} size={18} />
        </Button>
        <Button
          disabled={page >= totalDocCount}
          className={className({
            [styles.disabled]: page >= totalDocCount,
          })}
          onClick={onNext}
        >
          <RiArrowRightSLine color={Globals.colorDarkBlue} size={18} />
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
