import { Globals } from "utils/styleconfig";
import { useState } from "react";
import { BiPowerOff } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/Tooltip/Tooltip";
import Button from "components/UI/Button/Button";
import Search from "components/Search/Search";
import { useSelector, useDispatch } from "react-redux";
import { setTrustDevice } from "features/auth/actions";
import { selectAuthToken } from "features/auth/authSlice";
import { toggleSidebar } from "features/sidebar/sidebarSlice";
import { RiFunctionLine, RiSideBarFill } from "react-icons/ri";
import { useLogoutMutation } from "features/auth/authApiSlice";
import { selectNavbarTitle } from "features/navbar/navbarSlice";

import styles from "components/Navbar/Navbar.module.scss";

const Navbar = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [logout] = useLogoutMutation();

  const isVisible = JSON.parse(localStorage.getItem("toolTip"))?.gridMenu
    ?.visible;

  const [toggleTooltip, setToggleTooltip] = useState(
    isVisible === undefined ? true : false
  );

  const navbarTitle = useSelector(selectNavbarTitle);

  const token = useSelector(selectAuthToken);

  const onToggleSidebar = () => {
    if (!token) return null;

    dispatch(toggleSidebar());
  };

  const onLogOut = async () => {
    if (!token) return null;

    await logout().unwrap();

    // For test purposes set trust device to false
    // Will be removed before production
    dispatch(setTrustDevice(false));
    localStorage.setItem("trust_device", false);

    navigate("/auth");
  };

  const onCloseTooltip = () => {
    const toolTipConfig = JSON.parse(localStorage.getItem("toolTip"));

    setToggleTooltip(false);
    localStorage.setItem(
      "toolTip",
      JSON.stringify({ ...toolTipConfig, gridMenu: { visible: false } })
    );
  };

  return (
    <nav className={styles.container}>
      <div className={styles.heading}>
        <h2>{navbarTitle}</h2>
      </div>
      <Search />
      <div className={styles.menu}>
        <Button
          onClick={() => navigate("/user/menu")}
          className={styles.btn__menu}
        >
          <RiFunctionLine size={15} color={Globals.colorDarkBlue} />
        </Button>
        <Button onClick={onToggleSidebar} className={styles.btn__menu}>
          <RiSideBarFill size={15} color={Globals.colorDarkBlue} />
        </Button>
        <Button onClick={onLogOut} className={styles.btn__logOut}>
          <BiPowerOff size={15} color={Globals.colorWhite} />
        </Button>
        <Tooltip
          actionLabel="Ok"
          visible={toggleTooltip}
          actionHandler={onCloseTooltip}
          text="Explore more options, view user logs & recharge user lines"
        />
      </div>
    </nav>
  );
};

export default Navbar;
