import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const accountAdapter = createEntityAdapter({});

const initialState = accountAdapter.getInitialState({ size: 0 });

export const accountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: (searchParams) =>
        `/accounts?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results) return accountAdapter.setAll(initialState, []);

        const fetchedAcounts = response.results.map((account) => {
          const {
            user_id,
            domain_id,
            enabled,
            localization_id,
            domain,
            user,
            localization,
            allow_ip_management,
            created_by,
            call_recording,
            created_by_id,
            created_at,
            updated_at,
            did_cid_translation,
            std_cid_translation,
            maxchannels,
            cps,
            charge_per_min,
            ...accountProps
          } = account;

          accountProps["enabled"] = enabled ? "Yes" : "No";

          /* accountProps["allow_ip_management"] = allow_ip_management
            ? "Yes"
            : "No"; */

          return {
            user: `${user.lastname} ${user.firstname}`,
            ...accountProps,
            created_by: `${created_by.lastname} ${created_by.firstname}`,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return accountAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedAcounts
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Account", id })),
              { type: "Account", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Account", id: "PARTIAL-LIST" }],
    }),
    createAccount: builder.mutation({
      query: (data) => ({
        url: "/accounts",
        method: "POST",
        body: JSON.stringify({ ...data }),
      }),
      invalidateTags: [{ type: "Account", id: "PARTIAL-LIST" }],
    }),
    getAccount: builder.query({
      query: (id) => `/accounts/${id}`,
      providesTags: (result, error, id) => [{ type: "Account", id }],
    }),
    getAccountsByUser: builder.query({
      query: ({ userId, searchParams }) =>
        `/users/${userId}/accounts${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results) return accountAdapter.setAll(initialState, []);

        const fetchedAcounts = response.results.map((account) => {
          const {
            user_id,
            domain_id,
            enabled,
            localization_id,
            domain,
            user,
            localization,
            allow_ip_management,
            created_by,
            call_recording,
            created_by_id,
            created_at,
            updated_at,
            std_cid_translation,
            ...accountProps
          } = account;

          accountProps["enabled"] = enabled ? "Yes" : "No";

          accountProps["allow_ip_management"] = allow_ip_management
            ? "Yes"
            : "No";

          return {
            user: `${user.lastname} ${user.firstname}`,
            ...accountProps,
            created_by: `${created_by.lastname} ${created_by.firstname}`,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return accountAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedAcounts
        );
      },
      providesTags: (result, error, id) => [
        ...result.ids.map((id) => ({ type: "Account", id })),
      ],
    }),
    updateAccount: builder.mutation({
      query: ({ accountId, ...data }) => ({
        url: `/accounts/${accountId}`,
        method: "PATCH",
        body: JSON.stringify({ id: accountId, ...data }),
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Account", id: arg.id },
      ],
    }),
    deleteAccount: builder.mutation({
      query: (id) => ({
        url: `/accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Account", id: arg.id },
        { type: "Account", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetAccountQuery,
  useGetAccountsQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
  useGetAccountsByUserQuery,
} = accountApiSlice;
