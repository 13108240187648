import { Globals } from "utils/styleconfig";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useNavigate, useRouteError } from "react-router-dom";

import styles from "styles/modules/Error.module.scss";

const ErrorPage = ({ message }) => {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <h1>Oops!</h1>
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{error?.statusText || error?.message || message}</i>
        </p>
        <button onClick={() => navigate(-1)}>
          <RiArrowLeftSLine color={Globals.colorGray} size={18} />
          <span>Go Back</span>
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
