import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const userlineAdapter = createEntityAdapter({});
const userlogsAdapter = createEntityAdapter({});

const userLinesInitialState = userlineAdapter.getInitialState();
const userLogsInitialState = userlogsAdapter.getInitialState({
  size: 0,
  total_cost: 0,
  billseconds: 0,
});

export const userlineApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserlines: builder.query({
      query: () => "/users/lines",
      transformResponse: (response) => {
        if (!response?.results)
          return userlineAdapter.setAll(userLinesInitialState, []);

        const fetchedUserlines = response.results.map((userline) => {
          const {
            created_by,
            created_by_id,
            created_at,
            updated_at,
            ...userlineProps
          } = userline;

          return {
            name: userline.account_balance ||  userline.did_number ||  userline.username || userline.call_billing_account_id,
            ...userlineProps,
            created_at: formatDate(created_at),
            updated_at: formatDate(updated_at),
          };
        });

        return userlineAdapter.setAll(userLinesInitialState, fetchedUserlines);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Userline", id })),
              { type: "Userline", id: "LIST" },
            ]
          : [{ type: "Userline", id: "LIST" }],
    }),
    getUserLogs: builder.query({
      query: (searchParams) => `/users/lines/logs?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response) return userlogsAdapter.setAll(userLogsInitialState, []);

        return userlogsAdapter.setAll(
          {
            ...userLogsInitialState,
            size: response?.count,
            total_cost: response?.total_cost,
            billseconds: response?.billseconds,
          },
          response?.records
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Userlogs", id })),
              { type: "Userlogs", id: "LIST" },
            ]
          : [{ type: "Userlogs", id: "LIST" }],
    }),
  }),
});

export const { useGetUserlinesQuery, useGetUserLogsQuery } = userlineApiSlice;

// returns the query result object
export const selectUserlinesResult =
  userlineApiSlice.endpoints.getUserlines.select();

// creates memoized selector
const selectUserlinesData = createSelector(
  selectUserlinesResult,
  (result) => result.data
);

// getSelector creates these selectors by default and we rename them with aliases using destructuring
export const { selectAll: selectAllUserlines } = userlineAdapter.getSelectors(
  (state) => selectUserlinesData(state) ?? userLinesInitialState
);
