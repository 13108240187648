import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { nanoid, createEntityAdapter } from "@reduxjs/toolkit";

const deviceAdapter = createEntityAdapter({});

const initialState = deviceAdapter.getInitialState();

export const deviceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signDevice: builder.mutation({
      query: (token) => ({
        url: "/devices",
        method: "POST",
        body: JSON.stringify({ platform: "web", data: nanoid(), token }),
      }),
    }),
    getDevicesByExtension: builder.query({
      query: (id) => `/extensions/${id}/devices`,
      transformResponse: (response) => {
        if (!response?.length) return deviceAdapter.setAll(initialState, []);

        const fetchedDevices = response.map((device) => {
          const {
            enabled,
            created_by_id,
            extension_id,
            password,
            created_at,
            updated_at,
            ...deviceProps
          } = device;

          deviceProps["enabled"] = enabled ? "Yes" : "No";

          return {
            ...deviceProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return deviceAdapter.setAll(initialState, fetchedDevices);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Device", id })),
              { type: "Device", id: "LIST" },
            ]
          : [{ type: "Device", id: "LIST" }],
    }),
    getDevice: builder.query({
      query: ({ extensionId, deviceId }) =>
        `/extensions/${extensionId}/devices/${deviceId}`,
      providesTags: (result, error, id) => [{ type: "Device", id }],
    }),
    createDevice: builder.mutation({
      query: ({ extensionId, ...data }) => ({
        url: `/extensions/${extensionId}/devices`,
        method: "POST",
        body: JSON.stringify({ ...data }),
      }),
      invalidateTags: [{ type: "Device", id: "LIST" }],
    }),
    updateDevice: builder.mutation({
      query: ({ extensionId, deviceId, ...data }) => ({
        url: `/extensions/${extensionId}/devices/${deviceId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: deviceId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "Device", id: args.id },
      ],
    }),
    deleteDevice: builder.mutation({
      query: ({ extensionId, deviceId }) => ({
        url: `/extensions/${extensionId}/devices/${deviceId}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "Device", id: args.id },
        { type: "Device", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetDeviceQuery,
  useSignDeviceMutation,
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useGetDevicesByExtensionQuery,
} = deviceApiSlice;
