import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";

const domainAdapter = createEntityAdapter({});

const initialState = domainAdapter.getInitialState();

export const domainApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDomains: builder.query({
      query: () => "/domains",
      transformResponse: (response) => {
        if (!response.length) return domainAdapter.setAll(initialState, []);

        const fetchedDomains = response.map((domain) => {
          const {
            created_by_id,
            enabled,
            is_primary,
            created_at,
            updated_at,
            parameters,
            variables,
            ...domainProps
          } = domain;

          domainProps["is_primary"] = is_primary ? "Yes" : "No";
          domainProps["enabled"] = enabled ? "Yes" : "No";

          return {
            ...domainProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return domainAdapter.setAll(initialState, fetchedDomains);
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Domain", id })),
              { type: "Domain", id: "LIST" },
            ]
          : [{ type: "Domain", id: "LIST" }],
    }),
    getDomain: builder.query({
      query: (id) => `/domains/${id}`,
      providesTags: (result, error, id) => [{ type: "Domain", id }],
    }),
    updateDomain: builder.mutation({
      query: ({ domainId, ...data }) => ({
        url: `/domains/${domainId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: domainId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "Domain", id: args.id },
      ],
    }),
  }),
});

export const {
  useGetDomainQuery,
  useGetDomainsQuery,
  useUpdateDomainMutation,
} = domainApiSlice;
