import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const pricelistAdapter = createEntityAdapter({});

const initialState = pricelistAdapter.getInitialState({ size: 0 });

export const priceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPricelists: builder.query({
      query: (searchParams) => `/pricelists?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response.results) return pricelistAdapter.setAll(initialState, []);

        const fetchedPricelists = response.results.map((pricelist) => {
          const {
            enabled,
            created_by,
            created_at,
            updated_at,
            created_by_id,
            ...pricelistProps
          } = pricelist;

          pricelistProps["enabled"] = enabled ? "Yes" : "No";
          pricelistProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            ...pricelistProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return pricelistAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedPricelists
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Pricelist", id })),
              { type: "Pricelist", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Pricelist", id: "PARTIAL-LIST" }],
    }),
    getPricelist: builder.query({
      query: (id) => `/pricelists/${id}`,
      providesTags: (result, error, id) => [{ type: "Pricelist", id }],
    }),
    createPricelist: builder.mutation({
      query: (data) => ({
        url: "/pricelists",
        method: "POST",
        body: JSON.stringify({ ...data }),
      }),
      invalidateTags: [{ type: "Pricelist", id: "PARTIAL-LIST" }],
    }),
    updatePricelist: builder.mutation({
      query: ({ pricelistId, ...data }) => ({
        url: `/pricelists/${pricelistId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: pricelistId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "Pricelist", id: args.id },
      ],
    }),
    deletePricelist: builder.mutation({
      query: (id) => ({
        url: `/pricelists/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Pricelist", id: arg.id },
        { type: "Pricelist", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetPricelistQuery,
  useGetPricelistsQuery,
  useCreatePricelistMutation,
  useDeletePricelistMutation,
  useUpdatePricelistMutation,
} = priceApiSlice;
