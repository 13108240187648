import styles from "components/Form/Form.module.scss";

export const formConfig = {
  username: {
    title: "Username",
    elementType: "input",
    elementConfig: {
      id: "username",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Username is required",
    },
  },
  did_id: {
    title: "DID",
    elementType: "input",
    elementConfig: {
      id: "did_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search DID",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "DID is required",
    },
  },
  local_call: {
    title: "Locall call",
    elementType: "select",
    elementConfig: {
      id: "local_call",
      value: false,
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      {
        value: "",
        text: "-- local call --",
      },
      {
        value: false,
        text: "No",
      },
      {
        value: true,
        text: "Yes",
      },
    ],
    valid: true,
    touched: false,
    error: {
      message: "Local call is required",
    },
  },
  account_id: {
    title: "Account",
    elementType: "input",
    elementConfig: {
      id: "account_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search Account",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: false,
    touched: false,
    error: {
      message: "Account is required",
    },
  },
  call_billing_account_id: {
    title: "Call Account",
    elementType: "input",
    elementConfig: {
      id: "call_billing_account_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search Call Account",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: false,
    touched: false,
    error: {
      message: "Account is required",
    },
  },
  caller_id: {
    title: "CallerId",
    elementType: "input",
    elementConfig: {
      id: "caller_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search CallerId",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "CallerId is required",
    },
  },
  enabled: {
    title: "Is Extension Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
      value: true,
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: false, text: "No" },
      { value: true, text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
  /*  alias: {
    title: "Alias",
    elementType: "input",
    elementConfig: {
      id: "alias",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Alias is required",
    },
  }, */
};
