import Form from "views/Form";
import className from "classnames";
import { searchHandler } from "models/search";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountTypeId } from "models/accountType";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useEffect, useLayoutEffect, useState } from "react";
import { selectAllResults } from "features/search/searchSlice";
import { formConfig } from "pages/Accounts/NewAccount/formConfig";
import FormElement from "components/Form/FormElement/FormElement";
import { useCreateAccountMutation } from "features/account/accountApiSlice";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import { dropdownItemSelector } from "models/form";
import { toast } from "react-hot-toast";
import { parseError } from "utils/helpers";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const NewAccount = () => {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const searchResults = useSelector(selectAllResults);

  const [activeInput, setActiveInput] = useState("");

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  const [createAccount, { isLoading }] = useCreateAccountMutation();

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    dispatch(
      setNavbarTitle(params?.id ? "Create User Account" : "New Account")
    );
  }, [dispatch, params?.id]);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      if (e.target.id === "type") {
        const newFormConfig = updateAccountTypeId(
          form.getFormConfig(),
          updatedInput
        );

        form.setConfig(newFormConfig);
        form.createFormElements();
      }

      if (e.target.id === "user_id") {
        searchHandler(`/users`, e.target.value, dispatch);
      }

      if (e.target.id === "localization_id") {
        searchHandler("/localizations", e.target.value, dispatch);
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (key.split("_")[1] === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          if (el.elementConfig.type === "number") {
            formData[key] = form.parseFormValue(
              el.elementConfig.value,
              "number"
            );
          } else {
            formData[key] = el.elementConfig.value;
          }
        }
      }

      try {
        await createAccount(formData).unwrap();
        toast.success("Account created successfully");
        navigate({
          pathname: "/accounts",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Fill in details for the new account</h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div
                key={`el-group-${idx + 1}`}
                className={className({
                  [formStyles.group]: true,
                  [utilityStyles.column100]: elementType === "textarea",
                })}
              >
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
                <FormDropdown
                  items={searchResults}
                  itemClickHandler={(item) =>
                    dropdownItemClickHandler(elementConfig.id, item)
                  }
                  visible={
                    activeInput === elementConfig.id && el.config.dropdown
                  }
                />
              </div>
            );
          })}
          <div className={formStyles.btn}>
            <button disabled={!formValidity || isLoading}>
              {isLoading ? "Creating account..." : "Save Changes"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAccount;
