import Skeleton from "react-loading-skeleton";

import styles from "components/Table/TableSkeleton/TableSkeleton.module.scss";

const TableSkeleton = () => {
  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th colSpan={8}>
              <Skeleton height={19} borderRadius="2rem" />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array(10)
            .fill({})
            .map((item, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                  <td>
                    <Skeleton width={120} height={20} borderRadius="2rem" />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TableSkeleton;
