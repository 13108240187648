import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useLocation, Navigate } from "react-router-dom";
import BaseLayout from "components/BaseLayout/BaseLayout";
import { selectAuthToken } from "features/auth/authSlice";
import DeleteModal from "components/UI/Modals/DeleteModal";

function RequireAuth() {
  const location = useLocation();

  const token = useSelector(selectAuthToken);

  return (
    <BaseLayout showSpinner={!token ? true : false}>
      {token ? (
        <>
          <Outlet />
          <DeleteModal />
        </>
      ) : (
        <Navigate to="/auth" state={{ from: location }} replace />
      )}
    </BaseLayout>
  );
}

export default RequireAuth;
