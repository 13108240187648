import { apiSlice } from "features/api/apiSlice";

export const paymentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    verifyPayment: builder.query({
      query: ({ accountId, referenceCode }) =>
        `/users/call_accounts/${accountId}/recharge/verify/${referenceCode}`,
    }),
  }),
});

export const { useVerifyPaymentQuery } = paymentApiSlice;
