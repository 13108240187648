export const dropdownItemSelector = (key, item, form) => {
  const updatedFormConfig = JSON.parse(JSON.stringify(form.getFormConfig()));

  let formConfig = updatedFormConfig[key];

  if (formConfig) {
    formConfig.elementConfig["data-id"] = item.id;
    formConfig.elementConfig.value = item?.name || item?.number;
    formConfig.valid = true;
    formConfig.touched = true;

    form.setConfig(updatedFormConfig);
    form.createFormElements();
  }
};
