import styles from "components/Form/Form.module.scss";

export const formConfig = {
  type: {
    title: "Account Type",
    elementType: "select",
    elementConfig: {
      id: "type",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "", text: "-- select account type --" },
      { value: "user", text: "User" },
      { value: "provider", text: "Provider" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "Account type is required",
    },
  },
  type_id: {
    title: "Type Id",
    elementType: "input",
    elementConfig: {
      id: "type_id",
      type: "text",
      "data-id": "",
      value: "",
      placeholder: "Search id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Type is required",
    },
  },
  number: {
    title: "Number",
    elementType: "input",
    elementConfig: {
      id: "number",
      type: "text",
      value: "",
      autoComplete: "off",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Number is required",
    },
  },
  localization_id: {
    title: "Localization",
    elementType: "input",
    elementConfig: {
      id: "localization_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search localization id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Localization is required",
    },
  },
  allow_ip_management: {
    title: "Allow ip Management",
    elementType: "select",
    elementConfig: {
      id: "allow_ip_management",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "", text: "-- allow ip managemenet --" },
      { value: "true", text: "Yes" },
      { value: "false", text: "No" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "Localization is required",
    },
  },
  maxchannels: {
    title: "Max Channels",
    elementType: "input",
    elementConfig: {
      id: "maxchannels",
      type: "number",
      value: "1",
      step: 1,
      min: 1,
      placeholder: "1",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Max channels is required",
    },
  },
  call_recording: {
    title: "Allow Call Recording",
    elementType: "select",
    elementConfig: {
      id: "call_recording",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "", text: "-- allow call recording --" },
      { value: "true", text: "Yes" },
      { value: "false", text: "No" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "Call recording is required",
    },
  },
  charge_per_min: {
    title: "Charge per Min",
    elementType: "input",
    elementConfig: {
      id: "charge_per_min",
      type: "number",
      value: "1",
      step: 1,
      min: 1,
      placeholder: "1",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Charge per min number is required",
    },
  },
  cps: {
    title: "CPS",
    elementType: "input",
    elementConfig: {
      id: "cps",
      type: "number",
      step: 1,
      min: 1,
      value: "1",
      placeholder: "1",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Cps is required",
    },
  },
  did_cid_translation: {
    title: "DID CID translation",
    elementType: "input",
    elementConfig: {
      id: "did_cid_translation",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "DID CID is required",
    },
  },
  std_cid_translation: {
    title: "STD CID translation",
    elementType: "input",
    elementConfig: {
      id: "std_cid_translation",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "STD CID is required",
    },
  },
  enabled: {
    title: "Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    options: [
      { value: "", text: "-- set enabled --" },
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    error: {
      message: "",
    },
  },
  description: {
    title: "Description",
    elementType: "textarea",
    elementConfig: {
      id: "description",
      rows: 4,
      placeholder: "Write account description",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.textarea],
    validation: {
      required: false,
    },

    valid: true,
    touched: false,
    error: {
      message: "Account description is required",
    },
  },
};
