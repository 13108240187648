import { Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Globals } from "utils/styleconfig";
import { BiErrorAlt } from "react-icons/bi";
import { useEffect, useState } from "react";
import FPSpinner from "components/FPSpinner/FPSpinner";
import { parseError } from "utils/helpers";
import { toast } from "react-hot-toast";
import { setFingerprintId } from "features/device/deviceSlice";
import { useSignDeviceMutation } from "features/device/deviceApiSlice";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import formStyles from "components/Form/Form.module.scss";

function App() {
  const [signDevice] = useSignDeviceMutation();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  // const { error, data } = useVisitorData();

  useEffect(() => {
    const onSignDevice = async () => {
      try {
        const fp = await FingerprintJS.load();
        const { visitorId } = await fp.get();
        // console.log("Browser fingerprint "+visitorId);
        dispatch(setFingerprintId(visitorId));
        
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    // if (data) {
      onSignDevice();
    // }
  }, [ dispatch, signDevice]);

  const refresh = () => window.location.reload(true);

  if (isLoading) {
    return <FPSpinner />;
  }

 
  return <Outlet />;
}

export default App;
