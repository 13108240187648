import React from "react";

import styles from "components/Tooltip/Tooltip.module.scss";
import Button from "components/UI/Button/Button";

const Tooltip = ({ actionLabel, visible, text, actionHandler }) => {
  if (!visible) return <></>;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <p>{text}</p>
        <Button className={styles.action__btn} onClick={actionHandler}>
          {actionLabel}
        </Button>
      </div>
    </div>
  );
};

export default Tooltip;
