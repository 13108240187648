import { forwardRef } from "react";
import { RxDot } from "react-icons/rx";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Globals } from "utils/styleconfig";
import useMediaQuery from "hooks/useMediaQuery";
import { toggleSidebar } from "features/sidebar/sidebarSlice";

import styles from "components/Sidebar/Sidebar.module.scss";

const SidebarSublist = forwardRef(({ list }, ref) => {
  const [isMobile] = useMediaQuery();

  const dispatch = useDispatch();

  const onItemClick = () => {
    if (isMobile) {
      dispatch(toggleSidebar());
    }
  };

  return (
    <ul ref={ref} className={styles.sublist}>
      {list.map((item, idx) => {
        return (
          <li className={styles.sublist_item} key={`subitem-${idx}`}>
            <Link onClick={onItemClick} to={item.gotoPath}>
              <span>
                <RxDot size={14} color={Globals.colorDarkBlue} />
              </span>
              <p>{item.title}</p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
});

export default SidebarSublist;
