import className from "classnames";
import { Globals } from "utils/styleconfig";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import Button from "components/UI/Button/Button";
import Userlines from "pages/Userlines/Userlines";
import Userlogs from "pages/Userlogs/Userlogs";
import { useSelector } from "react-redux";
import React, { Fragment, useState } from "react";
import { selectAuthUser } from "features/auth/authSlice";
import ReactPortal from "components/ReactPortal/ReactPortal";
import {
  RiCloseLine,
  RiCheckboxCircleFill,
  RiArrowLeftLine,
} from "react-icons/ri";

import formStyles from "components/Form/Form.module.scss";
import styles from "components/UserMenu/UserMenu.module.scss";

const VIEWS = {
  menu: "",
  recharge: "Recharge User line",
  logs: "User logs",
};

const UserMenu = () => {
  const params = useParams();

  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);

  const [selectedOption, setSelectedOption] = useState(null);

  const view = params?.view;

  const onBack = () => {
    if (view === undefined) {
      if (typeof authUser?.role?.name === "string") {
        if (authUser.role.name === "user") {
          return navigate("/me");
        }
      }

      navigate("/");
    } else {
      navigate("/user/menu");
    }
  };

  const onNavigate = () => {
    if (selectedOption === VIEWS.recharge) {
      navigate("/user/menu/recharge");
    }

    if (selectedOption === VIEWS.logs) {
      navigate({
        pathname: "/user/menu/logs",
        search: createSearchParams({ page: "1" }).toString(),
      });
    }
  };

  let content = (
    <Fragment>
      <div className={styles.content__menu}>
        <div className={styles.title}>
          <h2>Hi, What would you like to do?</h2>
        </div>
        <div className={styles.options}>
          <div className={styles.grid}>
            <div
              onClick={() => setSelectedOption(VIEWS.recharge)}
              className={className({
                [styles.grid__item]: true,
                [styles.active]: selectedOption === VIEWS.recharge,
              })}
            >
              <div className={styles.item__text}>
                <h4>Recharge User Line</h4>
                <p>
                  Top up your user line directly from your account using quick
                  easy steps.
                </p>
              </div>
              <div className={styles.item__marker}>
                <div className={styles.circle}>
                  {selectedOption === VIEWS.recharge && (
                    <RiCheckboxCircleFill size={23} color={Globals.colorBlue} />
                  )}
                </div>
              </div>
            </div>
            <div
              onClick={() => setSelectedOption(VIEWS.logs)}
              className={className({
                [styles.grid__item]: true,
                [styles.active]: selectedOption === VIEWS.logs,
              })}
            >
              <div className={styles.item__text}>
                <h4>View users logs</h4>
                <p>Read all registered user call logs for each user line</p>
              </div>
              <div className={styles.item__marker}>
                <div className={styles.circle}>
                  {selectedOption === VIEWS.logs && (
                    <RiCheckboxCircleFill size={23} color={Globals.colorBlue} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.action}>
          <div className={formStyles.btn}>
            <Button onClick={onNavigate}>Continue</Button>
          </div>
        </div>
      </div>
    </Fragment>
  );

  if (view === "logs") {
    content = <Userlogs />;
  }

  if (view === "recharge") {
    content = (
      <div className={styles.content__wrapper}>
        <Userlines />
      </div>
    );
  }

  return (
    <ReactPortal containerElement="body">
      <div className={styles.container}>
        <div className={styles.header}>
          <Button onClick={onBack}>
            {view === undefined ? (
              <RiCloseLine color={Globals.colorDarkBlue} size="32" />
            ) : (
              <RiArrowLeftLine color={Globals.colorDarkBlue} size="32" />
            )}
          </Button>
          <span>{VIEWS[view]}</span>
        </div>
        <div className={styles.content}>{content}</div>
      </div>
    </ReactPortal>
  );
};

export default UserMenu;
