import { useDispatch } from "react-redux";
import React, { useLayoutEffect, useState } from "react";
import Button from "components/UI/Button/Button";
import { setNavbarTitle } from "features/navbar/navbarSlice";

import layoutStyles from "components/BaseLayout/BaseLayout.module.scss";
import styles from "pages/AccountSettings/AccountSettings.module.scss";
import ChangePassword from "components/ChangePassword/ChangePassword";

const TABS = {
  PASSWORD: 0,
};

const AccountSettings = () => {
  const dispatch = useDispatch();
  const [tabs] = useState(TABS.PASSWORD);

  useLayoutEffect(() => {
    dispatch(setNavbarTitle("My Account Settings"));
  }, [dispatch]);

  let content = null;

  if (tabs === TABS.PASSWORD) {
    content = <ChangePassword />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <div
          className={`${styles.tabs__item} ${
            tabs === TABS.PASSWORD ? styles.active : ``
          }`}
        >
          <Button>Change Password</Button>
        </div>
      </div>
      <div className={styles.content}>{content}</div>
      <style>
        {`
          .${layoutStyles.content__card} {
            background-color: #f6f7f8 !important;
          }
        `}
      </style>
    </div>
  );
};

export default AccountSettings;
