import styles from "components/Form/Form.module.scss";

export const formConfig = {
  name: {
    title: "Domain Name",
    elementType: "input",
    elementConfig: {
      id: "name",
      type: "text",
      value: "",
      placeholder: "xlr.com",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Domain name is required",
    },
  },
  is_primary: {
    title: "Is Domain primary",
    elementType: "select",
    elementConfig: {
      id: "is_primary",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "Domain name is required",
    },
  },
  enabled: {
    title: "Is Domain Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
  description: {
    title: "Description",
    elementType: "textarea",
    elementConfig: {
      id: "description",
      rows: 4,
      placeholder: "Write domain description",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.textarea],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Domain description is required",
    },
  },
};
