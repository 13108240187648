import { formatDate } from "utils/helpers";
import { RESULTS_PER_PAGE } from "constants";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";

const trunkAdapter = createEntityAdapter({});

const initialState = trunkAdapter.getInitialState({ size: 0 });

export const trunkApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrunks: builder.query({
      query: (searchParams) => `/trunks?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results) return trunkAdapter.setAll(initialState, []);

        const fetchedTrunks = response.results.map((trunk) => {
          const {
            created_by,
            account_id,
            call_type,
            enabled,
            created_by_id,
            gateway,
            gateway_id,
            provider_id,
            failover_gateway_1,
            failover_gateway_2,
            created_at,
            updated_at,
            ...trunkProps
          } = trunk;

          trunkProps["enabled"] = enabled ? "Yes" : "No";

          trunkProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            name: gateway.name,
            ...trunkProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return trunkAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedTrunks
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Trunk", id })),
              { type: "Trunk", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Trunk", id: "PARTIAL-LIST" }],
    }),
    getTrunk: builder.query({
      query: (id) => `/trunks/${id}`,
      providesTags: (result, error, id) => [{ type: "Trunk", id }],
    }),
    createTrunk: builder.mutation({
      query: (data) => ({
        url: "/trunks",
        method: "POST",
        body: JSON.stringify({ ...data }),
      }),
      invalidateTags: [{ type: "Trunk", id: "PARTIAL-LIST" }],
    }),
    updateTrunk: builder.mutation({
      query: ({ trunkId, ...data }) => ({
        url: `/trunks/${trunkId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: trunkId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [{ type: "Trunk", id: args.id }],
    }),
    deleteTrunk: builder.mutation({
      query: (id) => ({
        url: `/trunks/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "Trunk", id: args.id },
        { type: "Trunk", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetTrunkQuery,
  useGetTrunksQuery,
  useUpdateTrunkMutation,
  useCreateTrunkMutation,
  useDeleteTrunkMutation,
} = trunkApiSlice;
