import className from "classnames";
import Logo from "components/Logo/Logo";
import { Globals } from "utils/styleconfig";
import { RiCloseLine } from "react-icons/ri";
import { useRef, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import SidebarList from "components/Sidebar/SidebarList/SidebarList";
import EventBubbleStop from "components/EventBubbleStop/EventBubbleStop";
import { selectSidebar, setVisibility } from "features/sidebar/sidebarSlice";

import styles from "components/Sidebar/Sidebar.module.scss";

const Sidebar = () => {
  const nodeRef = useRef(null);

  const dispatch = useDispatch();

  const sidebar = useSelector(selectSidebar);

  useLayoutEffect(() => {
    let observer;
    let ref = nodeRef;

    if (ref) {
      if (ResizeObserver) {
        observer = new ResizeObserver(() => {
          const clientWidth = Math.round(window?.innerWidth);

          if (clientWidth <= 1045) {
            dispatch(setVisibility(false));
          }
        });

        observer.observe(ref.current);
      }
    }

    return () => {
      // on component unmount, we remove the listner
      if (observer && observer.unobserve) {
        observer.unobserve(ref.current);
      }
    };
  }, [dispatch, nodeRef]);

  const ovelayStyles = className({
    [styles.blur]: sidebar.in,
    [styles.visible]: sidebar.in,
  });

  return (
    <div className={styles.overlay}>
      <EventBubbleStop styles={ovelayStyles}>
        {sidebar.in && (
          <div className={styles.btnClose}>
            <button onClick={(e) => dispatch(setVisibility(false))}>
              <RiCloseLine color={Globals.colorDarkBlue} size="25" />
            </button>
          </div>
        )}
        <CSSTransition
          timeout={200}
          nodeRef={nodeRef}
          in={sidebar.in}
          unmountOnExit={true}
          classNames={{
            enter: "",
            enterActive: styles.open,
            exit: "",
            exitActive: styles.close,
          }}
        >
          <div ref={nodeRef} className={styles.container}>
            <div className={styles.wrapper}>
              <Logo />
              <SidebarList />
            </div>
          </div>
        </CSSTransition>
      </EventBubbleStop>
    </div>
  );
};

export default Sidebar;
