import { createSlice } from "@reduxjs/toolkit";
import { searchApiSlice } from "features/search/searchApiSlice";

const initialState = {
  results: [],
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearResults(state) {
      state.results = [];
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      searchApiSlice.endpoints.search.matchFulfilled,
      (state, action) => {
        state.results = action.payload?.results || action.payload;
      }
    );
  },
});

export const { clearResults } = searchSlice.actions;

export const selectAllResults = (state) => state.search.results;

export default searchSlice.reducer;
