import styles from "components/Form/Form.module.scss";

export const formConfig = {
  name: {
    title: "Call Account name",
    elementType: "input",
    elementConfig: {
      id: "name",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: true,
    },
    valid: true,
    touched: false,
    error: {
      message: "Call account name is required",
    },
  },
  number: {
    title: "Call Account number",
    elementType: "input",
    elementConfig: {
      id: "number",
      type: "text",
      inputMode: "numeric",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: true,
      isNumeric: true,
    },
    valid: true,
    touched: false,
    error: {
      message: "Caller id number is required",
    },
  },
  pricelist_id: {
    title: "Price list",
    elementType: "input",
    elementConfig: {
      id: "pricelist_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search price list id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: true,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Price list is required",
    },
  },
  type: {
    title: "Account Type",
    elementType: "select",
    elementConfig: {
      id: "type",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: true,
    },
    options: [
      { value: "", text: "-- select account type --" },
      { value: "user", text: "User" },
      { value: "provider", text: "Provider" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "Account type is required",
    },
  },
  type_id: {
    title: "Type Id",
    elementType: "input",
    elementConfig: {
      id: "type_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: true,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Type is required",
    },
  },
  local_charge_per_min: {
    title: "Local charge per minute",
    elementType: "input",
    elementConfig: {
      id: "local_charge_per_min",
      type: "number",
      value: "0.00",
      min: 0,
      step: 1,
      autoComplete: "off",
      placeholder: "0.00",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Charge per min is required",
    },
  },
  // credit_limit: {
  //   title: "Credit limit",
  //   elementType: "input",
  //   elementConfig: {
  //     id: "credit_limit",
  //     type: "number",
  //     value: "",
  //     min: 0,
  //     step: 1,
  //     autoComplete: "off",
  //     placeholder: "",
  //   },
  //   labelClasses: [styles.label],
  //   elementClasses: [styles.input],
  //   validation: {
  //     required: false,
  //   },
  //   valid: false,
  //   touched: false,
  //   error: {
  //     message: "Credit limit is required",
  //   },
  // },
  // balance: {
  //   title: "Balance",
  //   elementType: "input",
  //   elementConfig: {
  //     id: "balance",
  //     type: "number",
  //     value: "",
  //     min: 0,
  //     step: 1,
  //     autoComplete: "off",
  //     placeholder: "",
  //   },
  //   labelClasses: [styles.label],
  //   elementClasses: [styles.input],
  //   validation: {
  //     required: false,
  //   },
  //   valid: false,
  //   touched: false,
  //   error: {
  //     message: "Balance is required",
  //   },
  // },
  main_account_id: {
    title: "Main Account Id",
    elementType: "input",
    elementConfig: {
      id: "main_account_id",
      value: "",
      "data-id": "",
      placeholder: "Search account id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Main account id is required",
    },
  },
  // postpaid: {
  //   title: "Is Call account postpaid",
  //   elementType: "select",
  //   elementConfig: {
  //     id: "postpaid",
  //   },
  //   labelClasses: [styles.label],
  //   elementClasses: [styles.select],
  //   validation: {
  //     required: false,
  //   },
  //   options: [
  //     { value: "false", text: "No" },
  //     { value: "true", text: "Yes" },
  //   ],
  //   valid: false,
  //   touched: false,
  //   error: {
  //     message: "",
  //   },
  // },
  enabled: {
    title: "Is Call account Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
};
