const EventBubbleStop = ({ children, styles = "" }) => {
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={styles}
    >
      {children}
    </div>
  );
};

export default EventBubbleStop;
