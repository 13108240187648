import styles from "components/Form/Form.module.scss";

export const formConfig = {
  name: {
    title: "Name of Pricelist",
    elementType: "input",
    elementConfig: {
      id: "name",
      type: "text",
      value: "",
      placeholder: "Example",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Pricelist name is required",
    },
  },
  inc: {
    title: "Inc",
    elementType: "input",
    elementConfig: {
      id: "inc",
      type: "number",
      min: 1,
      step: 1,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Inc is required",
    },
  },
  init_inc: {
    title: "Init inc",
    elementType: "input",
    elementConfig: {
      id: "init_inc",
      type: "number",
      min: 1,
      step: 1,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Init inc is required",
    },
  },
  markup: {
    title: "Markup",
    elementType: "input",
    elementConfig: {
      id: "markup",
      type: "number",
      min: 1,
      step: 1,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Markup is required",
    },
  },
  routing_type: {
    title: "Routing type",
    elementType: "input",
    elementConfig: {
      id: "routing_type",
      type: "number",
      min: 1,
      step: 1,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Routing type is required",
    },
  },
  enabled: {
    title: "Is Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    options: [
      { value: "", text: "-- set is enabled --" },
      { value: true, text: "Yes" },
      { value: false, text: "No" },
    ],
    error: {
      message: "",
    },
  },
  description: {
    title: "Description",
    elementType: "textarea",
    elementConfig: {
      id: "description",
      rows: 3,
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.textarea],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Description is required",
    },
  },
};
