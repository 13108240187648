import { actions } from "utils/helpers";
import Table from "components/Table/Table";
import { toast } from "react-hot-toast";
import { UNAUTHORIZED } from "constants";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "features/auth/authSlice";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useGetDomainsQuery } from "features/domain/domainApiSlice";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";

import cardStyles from "styles/modules/Card.module.scss";

const Domains = ({ label }) => {
  const { data, isLoading, isError } = useGetDomainsQuery();

  const authUser = useSelector(selectAuthUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setNavbarTitle("Domains"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load domains, try refreshing the page");
    }
  }, [isError]);

  const onEditBtnHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/domains/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate]
  );

  return (
    <div>
      <div className={cardStyles.menu}>
        <h3>Showing data for domains</h3>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Table data={data} onEdit={onEditBtnHandler} />
      )}
    </div>
  );
};

export default Domains;
