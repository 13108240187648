import { toast } from "react-hot-toast";
import { actions } from "utils/helpers";
import { UNAUTHORIZED } from "constants";
import Table from "components/Table/Table";
import { Globals } from "utils/styleconfig";
import Skeleton from "react-loading-skeleton";
import { RiFilter3Line } from "react-icons/ri";
import { MdOutlineSort } from "react-icons/md";
import Button from "components/UI/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "features/auth/authSlice";
import Pagination from "components/Pagination/Pagination";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { Fragment, useCallback, useEffect, useState } from "react";
import CallRecordFilter from "components/UI/Modals/CallRecordFilter";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useGetUserCallRecordsQuery } from "features/call_record/callRecordApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const UserCallRecords = ({ label }) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetUserCallRecordsQuery(searchParams.toString());

  const [toggleModal, setToggleModal] = useState(false);

  const [toggleSort, setToggleSort] = useState(false);

  const authUser = useSelector(selectAuthUser);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setNavbarTitle("User Call Records"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error(
        "Could not load call records for users, try refreshing the page"
      );
    }
  }, [isError]);

  const onEditBtnHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/call_records/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate]
  );

  const onSortList = (sortField) => {
    let currentParams = {};

    for (const [key, value] of searchParams.entries()) {
      currentParams[key] = value;
    }

    navigate({
      search: createSearchParams({
        ...currentParams,
        sort_by: sortField,
      }).toString(),
    });
  };

  return (
    <>
      <CallRecordFilter
        visible={toggleModal}
        close={() => setToggleModal(false)}
      />
      <div style={{ marginBottom: "3rem" }} className={cardStyles.menu}>
        <h3>Showing data for user call records</h3>
        <div className={cardStyles.menuOptions}>
          <Button
            onClick={() => setTimeout(() => setToggleModal(true), 100)}
            className={utilityStyles.outline__button}
          >
            <RiFilter3Line color={Globals.colorDarkBlue} size={17} />
            <span>Filter</span>
          </Button>
          <Button
            onClick={() => setToggleSort((prev) => !prev)}
            className={utilityStyles.outline__button}
          >
            <MdOutlineSort color={Globals.colorDarkBlue} size={17} />
            <span>Sort by</span>
            {toggleSort && (
              <div className={utilityStyles.select__dropdown}>
                <span onClick={() => onSortList("caller_id_number")}>Caller Id Number</span>
                <span onClick={() => onSortList("call_type")}>Call Type</span>
                <span onClick={() => onSortList("cost")}>Cost</span>
                <span onClick={() => onSortList("destination_number")}>Destination Number</span>
              </div>
            )}
          </Button>
        </div>
      </div>
      <div className={cardStyles.miniCards}>
        <div className={cardStyles.miniCardItem}>
          <div className={cardStyles.miniCardHeading}>
            <h4>Total Cost</h4>
          </div>
          <div className={cardStyles.miniCardBody}>
            {currentData?.total_cost >= 0 ? (
              <p>{currentData?.total_cost}</p>
            ) : (
              <p>
                <Skeleton width={100} />
              </p>
            )}
          </div>
        </div>
        <div className={cardStyles.miniCardItem}>
          <div className={cardStyles.miniCardHeading}>
            <h4>Bill Seconds</h4>
          </div>
          <div className={cardStyles.miniCardBody}>
            {currentData?.billseconds >= 0 ? (
              <p>{currentData?.billseconds}</p>
            ) : (
              <p>
                <Skeleton width={100} />
              </p>
            )}
          </div>
        </div>
        <div className={cardStyles.miniCardItem}>
          <div className={cardStyles.miniCardHeading}>
            <h4>Count</h4>
          </div>
          <div className={cardStyles.miniCardBody}>
            {currentData?.size >= 0 ? (
              <p>{currentData?.size}</p>
            ) : (
              <p>
                <Skeleton width={100} />
              </p>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table
            data={currentData}
            onEdit={onEditBtnHandler}
            hiddenColumns={[
              "account",
              "call_uuid",
              "call_billing_account_id",
              "call_billling_account",
              "trunk",
            ]}
          />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </>
  );
};

export default UserCallRecords;
