import React, { useState } from "react";
import { RiCloseLine, RiSearch2Line } from "react-icons/ri";
import Button from "components/UI/Button/Button";
import { Globals } from "utils/styleconfig";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import styles from "components/Search/Search.module.scss";

const Search = () => {
  const [form, setForm] = useState({
    query: {
      value: "",
    },
  });

  const [searchParams] = useSearchParams();

  const searchMode = searchParams.get("search");

  const navigate = useNavigate();

  const inputChangeHandler = (e) => {
    const key = e.target.id;

    if (e.target.value.trim().length < 1) {
      resetForm(key);
    }

    if (e.target.value.trim().length >= 1) {
      setForm((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value: e.target.value,
        },
      }));
    }
  };

  const resetForm = (key) => {
    if (key) {
      setForm((prevState) => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value: "",
        },
      }));

      return null;
    }

    setForm({
      query: {
        value: "",
      },
    });
  };

  const onClearSearch = () => {
    resetForm();
    navigate({ search: createSearchParams({ page: "1" }).toString() });
  };

  const onSearchFormHandler = async (e) => {
    e.preventDefault();

    let currentParams = {};

    if (form.query.value?.length > 0) {
      for (const [key, value] of searchParams.entries()) {
        currentParams[key] = value;
      }

      navigate({
        search: createSearchParams({
          ...currentParams,
          page: "1",
          search: form.query.value,
        }).toString(),
      });
    }
  };

  return (
    <div className={styles.container}>
      <form className={styles.input}>
        <input
          type="text"
          id="query"
          required
          placeholder="Search"
          value={form.query.value}
          onChange={inputChangeHandler}
        />
        {form.query.value.trim().length >= 0 && !searchMode ? (
          <Button onClick={onSearchFormHandler}>
            <RiSearch2Line size={16} color={Globals.colorDarkBlue} />
          </Button>
        ) : (
          <Button onClick={onClearSearch}>
            <RiCloseLine size={18} color={Globals.colorDarkBlue} />
          </Button>
        )}
      </form>
    </div>
  );
};

export default Search;
