import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const gatewayAdapter = createEntityAdapter({});

const initialState = gatewayAdapter.getInitialState({ size: 0 });

export const gatewayApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGateways: builder.query({
      query: (searchParams) => `/gateways?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response.results.length)
          return gatewayAdapter.setAll(initialState, []);

        const fetchedGateways = response.results.map((gateway) => {
          const {
            created_by_id,
            profile_id,
            enabled,
            created_by,
            created_at,
            updated_at,
            variables,
            inbound_variables,
            parameters,
            outbound_variables,
            ...gatewayProps
          } = gateway;

          gatewayProps["enabled"] = enabled ? "Yes" : "No";

          gatewayProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            ...gatewayProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return gatewayAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedGateways
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Gateway", id })),
              { type: "Gateway", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Gateway", id: "PARTIAL-LIST" }],
    }),
    getGateway: builder.query({
      query: (id) => `/gateways/${id}`,
      providesTags: (result, error, id) => [{ type: "Gateway", id }],
    }),
    createGateway: builder.mutation({
      query: (data) => ({
        url: "/gateways",
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidateTags: [{ type: "Gateway", id: "PARTIAL-LIST" }],
    }),
    updateGateway: builder.mutation({
      query: ({ gatewayId, ...data }) => ({
        url: `/gateways/${gatewayId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: gatewayId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "Gateway", id: args.id },
      ],
    }),
    deleteGateway: builder.mutation({
      query: (id) => ({
        url: `/gateways/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "Gateway", id: args.id },
        { type: "Gateway", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetGatewayQuery,
  useGetGatewaysQuery,
  useCreateGatewayMutation,
  useDeleteGatewayMutation,
  useUpdateGatewayMutation,
} = gatewayApiSlice;
