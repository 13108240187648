import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  in: true,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.in = !state.in;
    },
    setVisibility(state, action) {
      state.in = action.payload;
    },
  },
});

export const { toggleSidebar, openSidebar, closeSidebar, setVisibility } =
  sidebarSlice.actions;

export const selectSidebar = (state) => state.sidebar;

export default sidebarSlice.reducer;
