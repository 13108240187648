import Form from "views/Form";
import className from "classnames";
import { toast } from "react-hot-toast";
import { parseError } from "utils/helpers";
import * as apiLabel from "models/apiLabels";
import { searchHandler } from "models/search";
import Button from "components/UI/Button/Button";
import { dropdownItemSelector } from "models/form";
import { capitalize, actions } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "features/auth/authSlice";
import { updateAccountTypeId } from "models/accountType";
import { useEffect, useLayoutEffect, useState } from "react";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { selectAllResults } from "features/search/searchSlice";
import FormElement from "components/Form/FormElement/FormElement";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import CreditCallAccount from "components/UI/Modals/CreditCallAccount";
import { formConfig } from "pages/CallAccounts/EditCallAccount/formConfig";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import {
  useGetCallAccountQuery,
  useUpdateCallAccountMutation,
} from "features/call_account/callAccountApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const EditCallAccount = ({ label }) => {
  const params = useParams();

  const dispatch = useDispatch();

  const searchResults = useSelector(selectAllResults);

  const [activeInput, setActiveInput] = useState("");

  const authUser = useSelector(selectAuthUser);

  const { data: callAccount } = useGetCallAccountQuery(params?.id);

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(true);
  const [toggleModal, setToggleModal] = useState(false);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [initialDataValues, setInitialDataValues] = useState([]);

  const navigate = useNavigate();

  const [updateCallAccount, { isLoading }] = useUpdateCallAccountMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Edit CallAccount"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    if (callAccount) {
      const dataValues = [];
      const updatedFormConfig = JSON.parse(JSON.stringify(formConfig));

      let newFormConfig = {};

      Object.entries(callAccount).forEach(([key, value]) => {
        if (updatedFormConfig[key]) {
          if (key === "type") {
            updatedFormConfig[key].elementConfig.value = value;

            // =================== Update type id ===================
            updatedFormConfig["type_id"].title = `${capitalize(
              callAccount[key]
            )} Id`;

            updatedFormConfig["type_id"].elementConfig = {
              ...updatedFormConfig["type_id"].elementConfig,
              id: `${value}_id`,
              value: callAccount[`${callAccount[key]}_id`] || "",
            };

            // =================== Update type id ===================

            newFormConfig[key] = { ...updatedFormConfig[key] };
            newFormConfig[`${value}_id`] = {
              ...updatedFormConfig["type_id"],
            };

            /**
             * Convert all values to strings to allow comparison
             */
            dataValues.push(String(value));
            dataValues.push(String(callAccount[`${callAccount[key]}_id`]));
          } else {
            updatedFormConfig[key].elementConfig.value = value;
            dataValues.push(String(value)); // Convert all values to strings to allow comparison
          }
        }
      });

      form.setConfig(updatedFormConfig);
      form.createFormElements();
      setFormElements(form.formElements);
      setInitialDataValues(dataValues);
    }
  }, [callAccount]);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity && authUser.actions[label].includes(actions.update)) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (
          typeof el.elementConfig.value === "string" &&
          el.elementConfig.value.trim().length <= 0
        ) {
          continue;
        }

        if (key.split("_")[1] === "id" || key.split("_")[2] === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          if (el.elementConfig.type === "number") {
            formData[key] = form.parseFormValue(
              el.elementConfig.value,
              "number"
            );
          } else {
            formData[key] = el.elementConfig.value;
          }
        }
      }

      try {
        await updateCallAccount({
          callAccountId: callAccount.id,
          ...formData,
        }).unwrap();
        toast.success("Call Account updated successfully");
        navigate({
          pathname: "/call_accounts",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      let isFormChanged = true;
      const updatedFormValues = [];

      updatedFormValues.push(e.target.value);

      if (e.target.id === "type") {
        const newFormConfig = updateAccountTypeId(
          form.getFormConfig(),
          updatedInput
        );

        form.setConfig(newFormConfig);
        form.createFormElements();
      }

      if (e.target.id === "user_id") {
        searchHandler("/users", e.target.value, dispatch);
      }

      if (e.target.id === "main_account_id") {
        searchHandler("/accounts", e.target.value, dispatch);
      }

      if (e.target.id === "pricelist_id") {
        searchHandler("/pricelists", e.target.value, dispatch);
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      isFormChanged =
        updatedFormValues.every((value) => initialDataValues.includes(value)) &&
        isFormChanged;

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
      setIsChangesMade(!isFormChanged);
    }
  };

  const onCreditAccount = () => {
    setTimeout(() => {
      setToggleModal(true);
    }, 100);
  };

  let buttonEl = null;
  let creditBtnEl = null;

  if (
    authUser?.actions[apiLabel.CALL_ACCOUNT_TRANSACTIONS].includes(
      actions.create
    )
  ) {
    creditBtnEl = (
      <Button
        type="button"
        className={`${formStyles.btnRed} ${utilityStyles.ml_1}`}
        disabled={isLoading}
        onClick={onCreditAccount}
      >
        Credit Account
      </Button>
    );
  }

  if (authUser.actions[label].includes(actions.update)) {
    buttonEl = (
      <button disabled={isLoading || !formValidity || !isChangesMade}>
        {isLoading ? "Saving..." : "Save Changes"}
      </button>
    );
  }

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>
          Changes made to this call account will overwrite the existing info
        </h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div key={`el-group-${idx + 1}`} className={formStyles.group}>
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
                <FormDropdown
                  items={searchResults}
                  itemClickHandler={(item) =>
                    dropdownItemClickHandler(elementConfig.id, item)
                  }
                  visible={
                    activeInput === elementConfig.id && el.config.dropdown
                  }
                />
              </div>
            );
          })}
          <div className={formStyles.btn}>
            {buttonEl}
            {creditBtnEl}
          </div>
        </form>
      </div>
      <CreditCallAccount
        visible={toggleModal}
        close={() => setToggleModal(false)}
      />
    </div>
  );
};

export default EditCallAccount;
