import className from "classnames";
import { Fragment } from "react";

import formStyles from "components/Form/Form.module.scss";

const FormElement = ({ id, title, config, type, ...props }) => {
  let formElement = null;

  const renderFormElement = () => {
    const { labelClasses, elementClasses, valid, validation, touched, error } =
      props;

    const isInvalid = touched && !valid && validation.required;

    switch (type) {
      case "label":
        formElement = (
          <label
            className={labelClasses
              .map((classname) =>
                className({
                  [classname]: true,
                  [formStyles.labelError]: isInvalid,
                })
              )
              .join(" ")}
            htmlFor={id}
          >
            {title}
          </label>
        );
        break;
      case "input":
        formElement = (
          <Fragment>
            <label
              className={labelClasses
                .map((classname) =>
                  className({
                    [classname]: true,
                    [formStyles.labelError]: isInvalid,
                  })
                )
                .join(" ")}
              htmlFor={id}
            >
              {title}
            </label>
            <input
              className={elementClasses
                .map((classname) =>
                  className({
                    [classname]: true,
                    [formStyles.inputError]: isInvalid,
                  })
                )
                .join(" ")}
              {...config}
            />
            <span className={className({ [formStyles.textError]: isInvalid })}>
              {isInvalid && error.message}
            </span>
          </Fragment>
        );
        break;
      case "select":
        const { options } = props;

        formElement = (
          <Fragment>
            <label
              className={labelClasses
                .map((classname) =>
                  className({
                    [classname]: true,
                    [formStyles.labelError]: isInvalid,
                  })
                )
                .join(" ")}
              htmlFor={id}
            >
              {title}
            </label>
            <select
              className={elementClasses
                .map((classname) =>
                  className({
                    [classname]: true,
                    [formStyles.selectError]: isInvalid,
                  })
                )
                .join(" ")}
              {...config}
            >
              {options.map((option, idx) => {
                return (
                  <option
                    key={`${title}-option-${idx + 1}`}
                    value={option.value}
                  >
                    {option.text}
                  </option>
                );
              })}
            </select>
            <span className={className({ [formStyles.textError]: isInvalid })}>
              {isInvalid && error.message}
            </span>
          </Fragment>
        );
        break;
      case "textarea":
        formElement = (
          <Fragment>
            <label
              className={labelClasses
                .map((classname) =>
                  className({
                    [classname]: true,
                    [formStyles.labelError]: isInvalid,
                  })
                )
                .join(" ")}
              htmlFor={id}
            >
              {title}
            </label>
            <textarea
              className={elementClasses.map((classname) =>
                className({
                  [classname]: true,
                  [formStyles.textareaError]: isInvalid,
                })
              )}
              {...config}
            ></textarea>
            <span className={className({ [formStyles.textError]: isInvalid })}>
              {isInvalid && error.message}
            </span>
          </Fragment>
        );
        break;
      default:
        formElement = <input {...config} />;
        break;
    }

    return formElement;
  };

  return renderFormElement();
};

export default FormElement;
