import styles from "components/Form/Form.module.scss";

export const formConfig = {
  callerid_name: {
    title: "Caller id name",
    elementType: "input",
    elementConfig: {
      id: "callerid_name",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Caller id name is required",
    },
  },
  callerid_number: {
    title: "Caller id number",
    elementType: "input",
    elementConfig: {
      id: "callerid_number",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Caller id number is required",
    },
  },
  enabled: {
    title: "Is CallerId Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
      value: true,
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: false,
    touched: false,
    error: {
      message: "",
    },
  },
};
