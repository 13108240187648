import styles from "components/Form/Form.module.scss";

export const formConfig = {
  name: {
    title: "Name of Gateway",
    elementType: "input",
    elementConfig: {
      id: "name",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Gateway name is required",
    },
  },
  profile_id: {
    title: "Profile",
    elementType: "input",
    elementConfig: {
      id: "profile_id",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Profile is required",
    },
  },
  enabled: {
    title: "Is Gateway Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
};
