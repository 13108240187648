import styles from "components/Form/Form.module.scss";

export const formConfig = {
  username: {
    title: "Device Username",
    elementType: "input",
    elementConfig: {
      id: "username",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Username is required",
    },
  },
  password: {
    title: "Device password",
    elementType: "input",
    elementConfig: {
      id: "password",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Password is required",
    },
  },
  description: {
    title: "Description",
    elementType: "textarea",
    elementConfig: {
      id: "description",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.textarea],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Description is required",
    },
  },
  enabled: {
    title: "Is Device Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
      value: true,
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
};
