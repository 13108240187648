import styles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

export const formConfig = {
  name: {
    title: "Name of Localization",
    elementType: "input",
    elementConfig: {
      id: "name",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Localization name is required",
    },
  },
  enabled: {
    title: "Is Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    options: [
      {
        value: "",
        text: "-- is localization enabled --",
      },
      {
        value: "false",
        text: "No",
      },
      {
        value: "true",
        text: "Yes",
      },
    ],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
  number_originate: [
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "number_originate_1",
        type: "text",
        value: "",
        "data-parent": "number_originate",
        placeholder: "234",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Destination number is required",
      },
    },
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "number_originate_2",
        type: "text",
        value: "",
        "data-parent": "number_originate",
        placeholder: "",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input, utilityStyles.alignColEnd],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Destination number is required",
      },
    },
  ],
  in_caller_id_originate: [
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "in_caller_id_originate_1",
        type: "text",
        value: "",
        "data-parent": "in_caller_id_originate",
        placeholder: "234",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Inbound Callerid is required",
      },
    },
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "in_caller_id_originate_2",
        type: "text",
        value: "",
        "data-parent": "in_caller_id_originate",
        placeholder: "",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input, utilityStyles.alignColEnd],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Inbound Callerid is required",
      },
    },
  ],
  out_caller_id_originate: [
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "out_caller_id_originate_1",
        type: "text",
        value: "",
        "data-parent": "out_caller_id_originate",
        placeholder: "234",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Outbound Callerid is required",
      },
    },
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "out_caller_id_originate_2",
        type: "text",
        value: "",
        "data-parent": "out_caller_id_originate",
        placeholder: "",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input, utilityStyles.alignColEnd],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Outbound Callerid is required",
      },
    },
  ],
  number_terminate: [
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "number_terminate_1",
        type: "text",
        value: "",
        "data-parent": "number_terminate",
        placeholder: "234",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Destination number is required",
      },
    },
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "number_terminate_2",
        type: "text",
        value: "",
        "data-parent": "number_terminate",
        placeholder: "",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input, utilityStyles.alignColEnd],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Destination number is required",
      },
    },
  ],
  in_caller_id_terminate: [
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "in_caller_id_terminate_1",
        type: "text",
        value: "",
        "data-parent": "in_caller_id_terminate",
        placeholder: "234",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Inbound Callerid is required",
      },
    },
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "in_caller_id_terminate_2",
        type: "text",
        value: "",
        "data-parent": "in_caller_id_terminate",
        placeholder: "",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input, utilityStyles.alignColEnd],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Inbound Callerid is required",
      },
    },
  ],
  out_caller_id_terminate: [
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "out_caller_id_terminate_1",
        type: "text",
        value: "",
        "data-parent": "out_caller_id_terminate",
        placeholder: "234",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Outbound Callerid is required",
      },
    },
    {
      title: "",
      elementType: "input",
      elementConfig: {
        id: "out_caller_id_terminate_2",
        type: "text",
        value: "",
        "data-parent": "out_caller_id_terminate",
        placeholder: "",
      },
      labelClasses: [styles.label, utilityStyles.m_0],
      elementClasses: [styles.input, utilityStyles.alignColEnd],
      validation: {
        required: false,
      },
      valid: true,
      touched: false,
      error: {
        message: "Outbound Callerid is required",
      },
    },
  ],
};
