import { toast } from "react-hot-toast";
import Table from "components/Table/Table";
import Pagination from "components/Pagination/Pagination";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import React, { Fragment, useEffect } from "react";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import { useGetUserLogsQuery } from "features/userline/userlineApiSlice";

import styles from "pages/Userlogs/Userlogs.module.scss";
import cardStyles from "styles/modules/Card.module.scss";

const Userlogs = () => {
  const [searchParams] = useSearchParams();

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetUserLogsQuery(searchParams.toString());

  useEffect(() => {
    if (isError) {
      toast.error("Could not load logs data, try refreshing the page");
    }
  }, [isError]);

  return (
    <div className={styles.container}>
      <div className={cardStyles.miniCards}>
        <div className={cardStyles.miniCardItem}>
          <div className={cardStyles.miniCardHeading}>
            <h4>Total Cost</h4>
          </div>
          <div className={cardStyles.miniCardBody}>
            {currentData?.total_cost >= 0 ? (
              <p>{currentData?.total_cost}</p>
            ) : (
              <p>
                <Skeleton width={100} />
              </p>
            )}
          </div>
        </div>
        <div className={cardStyles.miniCardItem}>
          <div className={cardStyles.miniCardHeading}>
            <h4>Bill Seconds</h4>
          </div>
          <div className={cardStyles.miniCardBody}>
            {currentData?.billseconds >= 0 ? (
              <p>{currentData?.billseconds}</p>
            ) : (
              <p>
                <Skeleton width={100} />
              </p>
            )}
          </div>
        </div>
        <div className={cardStyles.miniCardItem}>
          <div className={cardStyles.miniCardHeading}>
            <h4>Count</h4>
          </div>
          <div className={cardStyles.miniCardBody}>
            {currentData?.size >= 0 ? (
              <p>{currentData?.size}</p>
            ) : (
              <p>
                <Skeleton width={100} />
              </p>
            )}
          </div>
        </div>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table
            data={currentData}
            enableActions={false}
            hiddenColumns={["call_uuid", "call_billing_account_id"]}
          />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </div>
  );
};

export default React.memo(Userlogs);
