import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const extensionAdapter = createEntityAdapter({});

const initialState = extensionAdapter.getInitialState({ size: 0 });

export const extensionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getExtensions: builder.query({
      query: (searchParams) => `/extensions?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results)
          return extensionAdapter.setAll(initialState, []);

        const fetchedExtensions = response.results.map((extension) => {
          const {
            account,
            account_caller_id,
            created_by,
            profile_id,
            domain_id,
            caller_id,
            did_id,
            did,
            domain,
            account_id,
            display_name,
            local_call,
            enabled,
            call_billing_account_id,
            call_billing_account,
            created_by_id,
            variables,
            created_at,
            updated_at,
            parameters,
            ...extensionProps
          } = extension;

          extensionProps["local_call"] = local_call ? "Yes" : "No";
          extensionProps["enabled"] = enabled ? "Yes" : "No";

          extensionProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            account: account.number,
            domain: domain.name,
            did: did.number,
            caller_id: account_caller_id.callerid_number,
            ...extensionProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return extensionAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedExtensions
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Extension", id })),
              { type: "Extension", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Extension", id: "PARTIAL-LIST" }],
    }),
    getExtension: builder.query({
      query: (id) => `/extensions/${id}`,
      providesTags: (result, error, id) => [{ type: "Extension", id }],
    }),
    getExtensionsByAccount: builder.query({
      query: ({ accountId, searchParams }) =>
        `/accounts/${accountId}/extensions?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results)
          return extensionAdapter.setAll(initialState, []);

        const fetchedExtensions = response.results.map((extension) => {
          const {
            account,
            account_caller_id,
            created_by,
            profile_id,
            domain_id,
            caller_id,
            did_id,
            did,
            domain,
            account_id,
            display_name,
            local_call,
            enabled,
            call_billing_account_id,
            call_billing_account,
            created_by_id,
            variables,
            created_at,
            updated_at,
            parameters,
            ...extensionProps
          } = extension;

          extensionProps["local_call"] = local_call ? "Yes" : "No";
          extensionProps["enabled"] = enabled ? "Yes" : "No";

          extensionProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            account: account.number,
            domain: domain.name,
            did: did.number,
            caller_id: account_caller_id.callerid_number,
            ...extensionProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return extensionAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedExtensions
        );
      },
      providesTags: (result, error, id) => [
        ...result.ids.map((id) => ({ type: "Extension", id })),
      ],
    }),
    createExtension: builder.mutation({
      query: (data) => ({
        url: "/extensions",
        method: "POST",
        body: JSON.stringify({
          ...data,
        }),
      }),
      invalidateTags: [{ type: "Extension", id: "PARTIAL-LIST" }],
    }),
    updateExtension: builder.mutation({
      query: ({ extensionId, ...data }) => ({
        url: `/extensions/${extensionId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: extensionId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "Extension", id: args.id },
      ],
    }),
    deleteExtension: builder.mutation({
      query: (id) => ({
        url: `/extensions/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "Extension", id: args.id },
        { type: "Extension", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetExtensionQuery,
  useGetExtensionsQuery,
  useUpdateExtensionMutation,
  useCreateExtensionMutation,
  useDeleteExtensionMutation,
  useGetExtensionsByAccountQuery,
} = extensionApiSlice;
