import React, { useEffect } from "react";
import Spinner from "components/Spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import ReactPortal from "components/ReactPortal/ReactPortal";

import styles from "pages/VerifyPayment/VerifyPayment.module.scss";
import { useVerifyPaymentQuery } from "features/payment/paymentApiSlice";
import { toast } from "react-hot-toast";

const VerifyPayment = () => {
  const navigate = useNavigate();

  const params = useParams();

  const accountId = params?.account_id;
  const referenceCode = params?.reference_code;

  const { isSuccess, isError } = useVerifyPaymentQuery({
    accountId,
    referenceCode,
  });

  useEffect(() => {
    let timer = null;

    if (isSuccess) {
      toast.success("Payment verified successfully");
      timer = setTimeout(() => navigate("/"), 1000);
    }

    return () => clearTimeout(timer);
  }, [isSuccess, navigate]);

  useEffect(() => {
    let timer = null;

    if (isError) {
      toast.error("Payment verification failed");
      timer = setTimeout(() => navigate("/"), 1000);
    }

    return () => clearTimeout(timer);
  }, [isError, navigate]);

  return (
    <ReactPortal containerElement="body">
      <div className={styles.container}>
        <Spinner />
        <p>Verifying payment, please wait...</p>
      </div>
    </ReactPortal>
  );
};

export default VerifyPayment;
