import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const localizationAdapter = createEntityAdapter({});

const initialState = localizationAdapter.getInitialState({ size: 0 });

export const localizationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLocalizations: builder.query({
      query: (searchParams) => `/localizations?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response.length)
          return localizationAdapter.setAll(initialState, []);

        const fetchedLocalizations = response.map((localization) => {
          const {
            created_at,
            created_by,
            created_by_id,
            updated_at,
            enabled,
            ...localizationProps
          } = localization;

          localizationProps["enabled"] = enabled ? "Yes" : "No";
          localizationProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;
          localizationProps["created_at"] = formatDate(created_at);
          localizationProps["updated_at"] = formatDate(created_at);

          return {
            ...localizationProps,
          };
        });

        return localizationAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedLocalizations
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "Localization", id })),
              { type: "Localization", id: "PARTIAL-LIST" },
            ]
          : [{ type: "Localization", id: "PARTIAL-LIST" }],
    }),
    getLocalization: builder.query({
      query: (id) => `/localizations/${id}`,
      providesTags: (result, error, id) => [{ type: "Localization", id }],
    }),
    createLocalization: builder.mutation({
      query: (data) => ({
        url: "/localizations",
        method: "POST",
        body: JSON.stringify({ ...data }),
      }),
      invalidateTags: [{ type: "Localization", id: "PARTIAL-LIST" }],
    }),
    updateLocalization: builder.mutation({
      query: ({ localizationId, ...data }) => ({
        url: `/localizations/${localizationId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: localizationId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "Localization", id: args.id },
      ],
    }),
    deleteLocalization: builder.mutation({
      query: (id) => ({
        url: `/localizations/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "Localization", id: args.id },
        { type: "Localization", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetLocalizationQuery,
  useGetLocalizationsQuery,
  useUpdateLocalizationMutation,
  useCreateLocalizationMutation,
  useDeleteLocalizationMutation,
} = localizationApiSlice;
