import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "components/Table/Table";
import { UNAUTHORIZED } from "constants";
import { Globals } from "utils/styleconfig";
import { RiArrowRightSLine } from "react-icons/ri";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import { useGetAccountsByUserQuery } from "features/account/accountApiSlice";
import { selectAuthUser } from "features/auth/authSlice";
import { toast } from "react-hot-toast";
import { actions } from "utils/helpers";

import cardStyles from "styles/modules/Card.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";
import styles from "pages/Localizations/Localizations.module.scss";

const UserAccounts = ({ label }) => {
  const params = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);

  const [searchParams] = useSearchParams();

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetAccountsByUserQuery({
    userId: params?.id,
    searchParams: searchParams.toString(),
  });

  useEffect(() => {
    dispatch(setNavbarTitle("User Accounts"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load user accounts, try refreshing the page");
    }
  }, [isError]);

  const onAddBtnHandler = useCallback(() => {
    if (authUser?.actions[label].includes(actions.create)) {
      navigate(`/users/${params?.id}/accounts/new`);
    }
  }, [authUser?.actions, label, navigate, params?.id]);

  const onEditHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/accounts/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate]
  );

  let buttonEl = null;

  if (authUser?.actions[label].includes(actions.create)) {
    buttonEl = (
      <div className={`${utilityStyles.flexRow} ${utilityStyles.itemsCenter}`}>
        <button
          onClick={onAddBtnHandler}
          className={utilityStyles.outline__button}
        >
          <span>Create new User Account</span>
          <RiArrowRightSLine color={Globals.colorDarkBlue} size={16} />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={cardStyles.menu}>
        <h3>Showing data for user accounts</h3>
        {buttonEl}
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table data={currentData} onEdit={onEditHandler} />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </div>
  );
};

export default UserAccounts;
