import { toBoolean } from "utils/helpers";
import validator from "validator";

class Form {
  #config = {};
  formElements;
  isValid = false;

  constructor(config = {}) {
    this.#config = JSON.parse(JSON.stringify(config));
  }

  #getFormConfigKey(el) {
    return el.id;
  }

  createFormElements() {
    let formElements = [];

    for (const key in this.#config) {
      formElements.push({
        id: key,
        config: this.#config[key],
      });
    }

    this.formElements = formElements;
  }

  updateFormElement(elConfig) {
    let key = this.#getFormConfigKey(elConfig);

    const index = this.formElements.findIndex((formEl) => formEl.id === key);

    const updatedFormElements = this.formElements.slice();

    if (index !== -1) {
      updatedFormElements[index].config = elConfig.config;
    }

    this.formElements = updatedFormElements;
  }

  parseFormValue(value, type) {
    if (value === "true") {
      return toBoolean("true");
    }

    if (value === "false") {
      return toBoolean("false");
    }

    if (Number(value) === +value && type === "number") {
      return Number(value);
    }

    return value;
  }

  checkElValidity(el, rules) {
    let isValid = true;

    if (!el?.target?.value) return false;

    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = el.target.value.toString().trim().length !== 0 && isValid;
    }

    if (rules.isEmail) {
      isValid = validator.isEmail(el.target.value) && isValid;
    }

    if (rules.minLength) {
      isValid = el.target.value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = el.target.value.length <= rules.maxLength && isValid;
    }

    if (rules.isNumeric) {
      isValid = validator.isNumeric(el.target.value) && isValid;
    }

    return isValid;
  }

  inputChangeHandler = (e) => {
    let key = this.#getFormConfigKey({ id: e.target.id });

    let updatedFormConfigElement = null;

    // Check if form config el is an item of an array
    if (!this.#config[key]) {
      // Get the parent config key
      const parentKey = e.target.dataset?.parent;

      if (parentKey) {
        //Find config in array
        const index = this.#config[parentKey].findIndex(
          ({ elementConfig }) => elementConfig.id === key
        );

        if (index !== -1) {
          const item = {
            ...this.#config[parentKey][index],
            elementConfig: {
              ...this.#config[parentKey][index].elementConfig,
              value: this.parseFormValue(
                e.target.value,
                this.#config[parentKey][index].elementConfig.type
              ),
            },
            valid: this.checkElValidity(
              e,
              this.#config[parentKey][index].validation
            ),
            touched: true,
          };

          updatedFormConfigElement = [...this.#config[parentKey]];

          updatedFormConfigElement[index] = item;

          if (updatedFormConfigElement) {
            this.#config[parentKey] = updatedFormConfigElement;
          }
        }
      }
    } else {
      updatedFormConfigElement = {
        ...this.#config[key],
        elementConfig: {
          ...this.#config[key].elementConfig,
          value: this.parseFormValue(
            e.target.value,
            this.#config[key].elementConfig.type
          ),
        },
        valid: this.checkElValidity(e, this.#config[key].validation),
        touched: true,
      };

      if (updatedFormConfigElement) {
        this.#config[key] = updatedFormConfigElement;
      }
    }

    return updatedFormConfigElement;
  };

  setValidity(validity) {
    this.isValid = validity;
  }

  checkFormValidity() {
    let formIsValid = true;

    let newFormElements = [];

    for (let key in this.#config) {
      if (this.#config[key].length) {
        newFormElements = [...newFormElements, ...this.#config[key]];
      } else {
        newFormElements.push(this.#config[key]);
      }
    }

    for (let key in newFormElements) {
      formIsValid = newFormElements[key].valid && formIsValid;
    }

    this.setValidity(formIsValid);
  }

  getFormConfig() {
    return this.#config;
  }

  clear() {
    this.#config = {};
  }

  setConfig(config) {
    this.#config = JSON.parse(JSON.stringify(config));
  }

  cloneConfigEl(key, newConfig) {
    const currentFormConfig = this.#config;

    if (key) {
      const newFormConfig = JSON.parse(JSON.stringify(newConfig));

      let newConfigEl = newFormConfig[key];

      // Check if config el is an array
      if (newConfigEl.length) {
        let id = currentFormConfig[key]?.length;

        const updatedConfigEl = newConfigEl.map((configEl) => {
          id++;

          configEl.title = "";

          configEl.elementConfig = {
            ...configEl.elementConfig,
            id: `${key}_${id}`,
          };

          configEl.valid = false;

          return configEl;
        });

        newConfigEl = [...currentFormConfig[key], ...updatedConfigEl];

        currentFormConfig[key] = newConfigEl;

        this.updateFormElement({ id: key, config: newConfigEl });

        return currentFormConfig;
      }
    }
  }

  removeConfgEl(key, start, count) {
    const currentFormConfig = this.#config;

    if (key) {
      const newFormConfig = JSON.parse(JSON.stringify(currentFormConfig));

      let newConfigEl = newFormConfig[key];

      // Check if config el is an array
      if (newConfigEl.length) {
        if (start > count - 1) {
          newConfigEl.splice(start, count);

          let id = count;
          let newEls = newConfigEl.slice(count);

          newEls = newEls.map((configEl) => {
            id++;

            configEl.title = "";

            configEl.elementConfig = {
              ...configEl.elementConfig,
              id: `${key}_${id}`,
            };

            configEl.valid = false;

            return configEl;
          });

          // Merge default elements with new elements
          const updatedConfigEl = newConfigEl.slice(0, count).concat(newEls);

          newConfigEl = updatedConfigEl;

          newFormConfig[key] = newConfigEl;

          this.updateFormElement({ id: key, config: newConfigEl });

          return newFormConfig;
        }
      }
    }
  }
}

export default Form;
