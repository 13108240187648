import { useSelector } from "react-redux";
import { selectAuthUser } from "features/auth/authSlice";
import Unauthorized from "pages/Unauthorized/Unauthorized";

const ProtectedRoutes = ({ label, action, children }) => {
  const authUser = useSelector(selectAuthUser);

  const actions = authUser?.actions;

  if (actions && !actions[label]?.includes(action)) {
    return (
      <Unauthorized message="You're not authorized to view this page" />
    );
  }

  return children;
};

export default ProtectedRoutes;
