import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "",
};

const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setNavbarTitle: (state, action) => {
      state.title = action.payload;
    },
  },
});

export const { setNavbarTitle } = navbarSlice.actions;

export const selectNavbarTitle = (state) => state.navbar.title;

export default navbarSlice.reducer;
