import Form from "views/Form";
import className from "classnames";
import { useDispatch } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useEffect, useLayoutEffect, useState } from "react";
import FormElement from "components/Form/FormElement/FormElement";
import { formConfig } from "pages/SipProfiles/NewSipProfile/formConfig";
import { useCreateSipProfileMutation } from "features/sip_profile/sipprofileApiSlice";
import { toast } from "react-hot-toast";
import { parseError } from "utils/helpers";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const NewSipProfile = () => {
  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [createSipProfile, { isLoading }] = useCreateSipProfileMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("New Sip Profile"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        formData[key] = el.elementConfig.value;
      }

      try {
        await createSipProfile(formData).unwrap();
        toast.success("SipProfile created successfully");
        navigate({
          pathname: "/sip_profiles",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    if (updatedInput) {
      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Fill in details for the new sip profile</h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div
                key={`el-group-${idx + 1}`}
                className={className({
                  [formStyles.group]: true,
                  [utilityStyles.column100]: elementType === "textarea",
                })}
              >
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
            );
          })}
          <div className={formStyles.btn}>
            <button disabled={isLoading || !formValidity}>
              {isLoading ? "Creating sip profile..." : "Create Sip Profile"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewSipProfile;
