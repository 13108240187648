import Form from "views/Form";
import className from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Globals } from "utils/styleconfig";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { NUM_FORM_ITEMS, actions, parseError } from "utils/helpers";
import Button from "components/UI/Button/Button";
import { RiAddFill, RiCloseFill } from "react-icons/ri";
import { useState, useLayoutEffect, useEffect } from "react";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import FormElement from "components/Form/FormElement/FormElement";
import EventBubbleStop from "components/EventBubbleStop/EventBubbleStop";
import { formConfig } from "pages/Localizations/EditLocalization/formConfig";
import { selectAuthUser } from "features/auth/authSlice";
import { toast } from "react-hot-toast";
import {
  useGetLocalizationQuery,
  useUpdateLocalizationMutation,
} from "features/localization/localizationApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const ORIGINATION_LABELS = [
  "Destination Number Translation",
  "Inbound Callerid Translation",
  "Outbound Callerid Translation",
];
const TERMINATION_LABELS = [
  "Destination Number Translation",
  "Inbound Callerid Translation",
  "Outbound Callerid Translation",
];

const EditLocalization = ({ label }) => {
  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(true);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [initialDataValues, setInitialDataValues] = useState([]);

  const params = useParams();

  const authUser = useSelector(selectAuthUser);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { data: localization } = useGetLocalizationQuery(params?.id);

  const [updateLocalization, { isLoading }] = useUpdateLocalizationMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Edit Localization"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    if (localization) {
      const dataValues = [];
      const updatedFormConfig = JSON.parse(JSON.stringify(formConfig));

      Object.entries(localization).forEach(([key, value]) => {
        if (updatedFormConfig[key]) {
          if (updatedFormConfig[key].length) {
            // convert value to array
            const arr = value.split(",");
            let valueArr = [];

            if (arr.length <= 1) {
              valueArr = arr[0].split("/");
            } else {
              valueArr = arr.reduce(
                (acc, curr) => (acc = acc.split("/").concat(curr.split("/")))
              );
            }

            if (valueArr.length) {
              valueArr.forEach((value, idx) => {
                if (updatedFormConfig[key][idx]) {
                  updatedFormConfig[key][idx].elementConfig.value = value;
                } else {
                  const newConfigEl = {
                    ...updatedFormConfig[key][0],
                    elementConfig: {
                      ...updatedFormConfig[key][0].elementConfig,
                      value: valueArr[idx],
                      id: `${key}_${idx + 1}`,
                    },
                  };

                  updatedFormConfig[key].push(newConfigEl);
                }
              });
            }

            dataValues.push(String(value));
          } else {
            updatedFormConfig[key].elementConfig.value = value;
            dataValues.push(String(value)); // Convert all values to strings to allow comparison
          }
        }
      });

      form.setConfig(updatedFormConfig);
      form.createFormElements();

      setFormElements(form.formElements);
      setInitialDataValues(dataValues);
    }
  }, [localization]);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity && authUser?.actions[label].includes(actions.update)) {
      for (const key in form.getFormConfig()) {
        if (form.getFormConfig()[key].length) {
          const arr = JSON.parse(JSON.stringify(form.getFormConfig()[key]));

          let items = [];

          formData[key] = arr
            .reduce((acc, curr, index, array) => {
              const lastIndex = array.length - 1 === index;
              const value = curr.elementConfig.value.trim();

              if (items.length === NUM_FORM_ITEMS) {
                acc.push(items.join("/"));
                items = [value.length > 0 ? value : "*"];
              } else {
                items.push(value.length > 0 ? value : "*");
              }

              if (lastIndex) {
                acc.push(items.join("/"));
                items = [];
              }

              return acc;
            }, [])
            .join(",");
        } else {
          formData[key] = form.getFormConfig()[key].elementConfig.value;
        }
      }

      try {
        await updateLocalization({
          localizationId: localization.id,
          ...formData,
        }).unwrap();
        toast.success("Localization updated successfully");
        navigate({
          pathname: "/localizations",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    if (updatedInput) {
      const isArray = !!updatedInput.length;

      let isFormChanged = true;
      const updatedFormValues = [];

      updatedFormValues.push(e.target.value);

      form.updateFormElement({
        id: isArray ? e.target.dataset?.parent : e.target.id,
        config: updatedInput,
      });

      form.checkFormValidity();

      isFormChanged =
        updatedFormValues.every((value) => initialDataValues.includes(value)) &&
        isFormChanged;

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
      setIsChangesMade(!isFormChanged);
    }
  };

  const onCreateNewConfgEl = (e) => {
    e.preventDefault();

    const newFormConfig = form.cloneConfigEl(e.target.dataset?.id, formConfig);

    form.setConfig(newFormConfig);

    setFormElements(form.formElements);
  };

  const onRemoveConfgEl = (e, { start, element }) => {
    e.preventDefault();

    const key = element?.elementConfig?.["data-parent"];

    const newFormConfig = form.removeConfgEl(key, start, NUM_FORM_ITEMS);

    form.setConfig(newFormConfig);

    setFormElements(form.formElements);
  };

  let buttonEl = null;

  if (authUser?.actions[label].includes(actions.update)) {
    buttonEl = (
      <div className={formStyles.btn}>
        <button disabled={!formValidity || isLoading || !isChangesMade}>
          {isLoading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    );
  }

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>
          Changes made to this localization will overwrite the existing info
        </h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.slice(0, 2).map((el) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div
                key={`el-group-${elementConfig.id}`}
                className={className({
                  [formStyles.group]: true,
                  [utilityStyles.column100]: elementType === "textarea",
                })}
              >
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
            );
          })}
          <div className={formStyles.legend}>
            <h3>Origination</h3>
            {formElements.slice(2, 5).map((formEl, idx) => {
              return (
                <div
                  key={idx}
                  className={`${utilityStyles.grid} ${utilityStyles.grid_col1__min_content} ${utilityStyles.gap10}`}
                >
                  <h4 className="grid-label">{ORIGINATION_LABELS[idx]}</h4>
                  <div
                    style={{ gridColumn: "1 / 2" }}
                    className={`${utilityStyles.grid} ${utilityStyles.grid_col2} ${utilityStyles.gap15}`}
                  >
                    {formEl.config.map((el, idx) => {
                      const { title, elementType, elementConfig, ...config } =
                        el;

                      const remainder = idx % NUM_FORM_ITEMS;

                      return (
                        <div
                          className={formStyles.group}
                          style={{ position: "relative" }}
                          key={`el-group-${elementConfig.id}`}
                        >
                          {idx > NUM_FORM_ITEMS - 1 && (
                            <Button
                              onClick={(e) =>
                                onRemoveConfgEl(e, {
                                  start: idx - remainder,
                                  element: el,
                                })
                              }
                              className="btn--del"
                            >
                              <RiCloseFill
                                color={Globals.colorWhite}
                                size="14"
                              />
                            </Button>
                          )}
                          <FormElement
                            {...config}
                            title={title}
                            type={elementType}
                            id={elementConfig.id}
                            config={{
                              ...elementConfig,
                              onChange: inputChangeHandler,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    data-id={formEl.config[0].elementConfig["data-parent"]}
                    onClick={onCreateNewConfgEl}
                    className="btn--add"
                  >
                    <EventBubbleStop
                      styles={`${utilityStyles.flexRow} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
                    >
                      <RiAddFill color={Globals.colorWhite} size="17" />
                    </EventBubbleStop>
                  </Button>
                </div>
              );
            })}
          </div>
          <div
            className={`${formStyles.legend} ${utilityStyles.alignRowStart}`}
          >
            <h3>Termination</h3>
            {formElements.slice(5).map((formEl, idx) => {
              return (
                <div
                  key={idx}
                  className={`${utilityStyles.grid} ${utilityStyles.grid_col1__min_content} ${utilityStyles.gap10}`}
                >
                  <h4 className="grid-label">{TERMINATION_LABELS[idx]}</h4>
                  <div
                    style={{ gridColumn: "1 / 2" }}
                    className={`${utilityStyles.grid} ${utilityStyles.grid_col2} ${utilityStyles.gap15}`}
                  >
                    {formEl.config.map((el, idx) => {
                      const { title, elementType, elementConfig, ...config } =
                        el;

                      const remainder = idx % NUM_FORM_ITEMS;

                      return (
                        <div
                          className={formStyles.group}
                          style={{ position: "relative" }}
                          key={`el-group-${elementConfig.id}`}
                        >
                          {idx > NUM_FORM_ITEMS - 1 && (
                            <Button
                              onClick={(e) =>
                                onRemoveConfgEl(e, {
                                  start: idx - remainder,
                                  element: el,
                                })
                              }
                              className="btn--del"
                            >
                              <RiCloseFill
                                color={Globals.colorWhite}
                                size="14"
                              />
                            </Button>
                          )}
                          <FormElement
                            {...config}
                            title={title}
                            type={elementType}
                            id={elementConfig.id}
                            config={{
                              ...elementConfig,
                              onChange: inputChangeHandler,
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <Button
                    data-id={formEl.config[0].elementConfig["data-parent"]}
                    onClick={onCreateNewConfgEl}
                    className="btn--add"
                  >
                    <EventBubbleStop
                      styles={`${utilityStyles.flexRow} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
                    >
                      <RiAddFill color={Globals.colorWhite} size="17" />
                    </EventBubbleStop>
                  </Button>
                </div>
              );
            })}
          </div>
          {buttonEl}
        </form>
      </div>
      <style jsx="true">
        {`
          .grid-label {
            font-size: 1.35rem;
            grid-column: 1 / -1;
            color: var(--color-dark-blue);
          }

          .btn--del {
            top: -0.5rem;
            width: 1.7rem;
            display: flex;
            height: 1.7rem;
            right: -0.8rem;
            position: absolute;
            align-items: center;
            border-radius: 50%;
            justify-content: center;
            background-color: var(--color-dark-gray);
          }

          .btn--add {
            padding: 1.4rem;
            grid-column: 2 / -1;
            border-radius: 0.8rem;
            align-self: flex-start;
            background-color: var(--color-blue);
          }
        `}
      </style>
    </div>
  );
};

export default EditLocalization;
