import { actions } from "utils/helpers";
import { toast } from "react-hot-toast";
import { UNAUTHORIZED } from "constants";
import { Globals } from "utils/styleconfig";
import Table from "components/Table/Table";
import { MdOutlineSort } from "react-icons/md";
import Button from "components/UI/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthUser } from "features/auth/authSlice";
import Pagination from "components/Pagination/Pagination";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from "react";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";

import {
  useGetCallAccountsQuery,
  useDeleteCallAccountMutation,
} from "features/call_account/callAccountApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const CallAccounts = ({ label }) => {
  const dispatch = useDispatch();

  const authUser = useSelector(selectAuthUser);

  const [searchParams] = useSearchParams();

  const [toggleSort, setToggleSort] = useState(false);

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetCallAccountsQuery(searchParams.toString());

  const [deleteCallAccount] = useDeleteCallAccountMutation();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setNavbarTitle("Call Accounts"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load call accounts, try refreshing the page");
    }
  }, [isError]);

  const onDeleteBtnHandler = useCallback(
    async (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.delete)) {
        if (callFn) {
          await deleteCallAccount(id).unwrap(id);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, deleteCallAccount, label]
  );

  const onEditBtnHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/call_accounts/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate]
  );

  const onSortList = (sortField) => {
    let currentParams = {};

    for (const [key, value] of searchParams.entries()) {
      currentParams[key] = value;
    }

    navigate({
      search: createSearchParams({
        ...currentParams,
        sort_by: sortField,
      }).toString(),
    });
  };

  return (
    <div>
      <div className={cardStyles.menu}>
        <h3>Showing data for call accounts</h3>
        <div className={cardStyles.menuOptions}>
          <Button
            onClick={() => setToggleSort((prev) => !prev)}
            className={utilityStyles.outline__button}
          >
            <MdOutlineSort color={Globals.colorDarkBlue} size={17} />
            <span>Sort by</span>
            {toggleSort && (
              <div className={utilityStyles.select__dropdown}>
                <span onClick={() => onSortList("number")}>Number</span>
                <span onClick={() => onSortList("name")}>Name</span>
              </div>
            )}
          </Button>
        </div>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table
            data={currentData}
            onEdit={onEditBtnHandler}
            onDelete={onDeleteBtnHandler}
          />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </div>
  );
};

export default CallAccounts;
