import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "features/api/apiSlice";

const searchAdapter = createEntityAdapter();

export const searchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    search: builder.query({
      query: ({ apiPath }) => apiPath,
      keepUnusedDataFor: 10,
      transformResponse: (response, _, arg) => {
        if (arg?.normalize) {
          return searchAdapter.setAll({}, response?.results || []);
        }

        return response;
      },
    }),
  }),
});