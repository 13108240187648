import React from "react";
import Button from "components/UI/Button/Button";

import styles from "components/Form/FormDropdown/FormDropdown.module.scss";

const FormDropdown = ({ items = [], itemClickHandler, visible }) => {
  if (items.length <= 0 || !visible) return null;

  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {items.map((item, idx) => {
          return (
            <li key={`dropdown-item-${idx}`}>
              <Button type="button" onClick={() => itemClickHandler(item)}>
                {item?.["name"] || item?.["number"]}
              </Button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FormDropdown;
