import styles from "components/Form/Form.module.scss";

export const formConfig = {
  old_password: {
    title: "Old Password",
    elementType: "input",
    elementConfig: {
      id: "old_password",
      type: "password",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Old Password is required",
    },
  },
  new_password: {
    title: "New Password",
    elementType: "input",
    elementConfig: {
      id: "new_password",
      type: "password",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Old Password is required",
    },
  },
  confirm_password: {
    title: "Confirm New Password",
    elementType: "input",
    elementConfig: {
      id: "confirm_password",
      type: "password",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Old Password is required",
    },
  },
};
