export const ACCOUNTS = "ACCOUNTS";
export const ACCOUNT_CALLER_IDS = "ACCOUNT_CALLER_IDS";
export const AUTHORIZATIONS = "AUTHORIZATIONS";
export const CALL_ACCOUNTS = "CALL_ACCOUNTS";
export const DEVICES = "DEVICES";
export const DIDS = "DIDS";
export const DOMAINS = "DOMAINS";
export const EXTENSIONS = "EXTENSIONS";
export const EXTENSION_DEVICES = "EXTENSION_DEVICES";
export const GATEWAYS = "GATEWAYS";
export const LOCALIZATIONS = "LOCALIZATIONS";
export const PRICELISTS = "PRICELISTS";
export const ROLES = "ROLES";
export const ROUTES = "ROUTES";
export const SIP_PROFILES = "SIP_PROFILES";
export const TRUNKS = "TRUNKS";
export const USERS = "USERS";
export const CALL_RECORDS = "CALL_RECORDS";
export const USER_LOGINS = "USER_LOGINS";
export const CALL_ACCOUNT_TRANSACTIONS = "CALL_ACCOUNT_TRANSACTIONS";
