import DIDs from "pages/DIDs/DIDs";
import Users from "pages/Users/Users";
import Trunks from "pages/Trunks/Trunks";
import Domains from "pages/Domains/Domains";
import NewDID from "pages/DIDs/NewDID/NewDID";
import Gateways from "pages/Gateways/Gateways";
import Accounts from "pages/Accounts/Accounts";
import EditDID from "pages/DIDs/EditDID/EditDID";
import NewUser from "pages/Users/NewUser/NewUser";
import CallerIds from "pages/CallerIds/CallerIds";
import CallRecord from "pages/CallRecord/CallRecord";
import EditUser from "pages/Users/EditUser/EditUser";
import Extensions from "pages/Extensions/Extensions";
import Pricelists from "pages/Pricelists/Pricelists";
import NewTrunk from "pages/Trunks/NewTrunk/NewTrunk";
import SipProfiles from "pages/SipProfiles/SipProfiles";
import EditTrunk from "pages/Trunks/EditTrunk/EditTrunk";
import CallAccounts from "pages/CallAccounts/CallAccounts";
import EditDomain from "pages/Domains/EditDomain/EditDomain";
import NewAccount from "pages/Accounts/NewAccount/NewAccount";
import Localizations from "pages/Localizations/Localizations";
import NewGateway from "pages/Gateways/NewGateway/NewGateway";
import EditAccount from "pages/Accounts/EditAccount/EditAccount";
import EditGateway from "pages/Gateways/EditGateway/EditGateway";
import UserAccounts from "pages/Users/UserAccounts/UserAccounts";
import NewCallerId from "pages/CallerIds/NewCallerId/NewCallerId";
import UserCallRecords from "pages/UserCallRecords/UserCallRecords";
import EditCallerId from "pages/CallerIds/EditCallerId/EditCallerId";
import NewPricelist from "pages/Pricelists/NewPricelist/NewPricelist";
import NewExtension from "pages/Extensions/NewExtension/NewExtension";
import EditExtension from "pages/Extensions/EditExtension/EditExtension";
import EditPricelist from "pages/Pricelists/EditPricelist/EditPricelist";
import NewSipProfile from "pages/SipProfiles/NewSipProfile/NewSipProfile";
import EditSipProfile from "pages/SipProfiles/EditSipProfile/EditSipProfile";
import NewCallAccount from "pages/CallAccounts/NewCallAccount/NewCallAccount";
import ProviderCallRecords from "pages/ProviderCallRecords/ProviderCallRecords";
import EditCallAccount from "pages/CallAccounts/EditCallAccount/EditCallAccount";
import ExtensionDevices from "pages/Extensions/ExtensionDevices/ExtensionDevices";
import NewLocalization from "pages/Localizations/NewLocalization/NewLocalization";
import AccountExtensions from "pages/Accounts/AccountExtensions/AccountExtensions";
import EditLocalization from "pages/Localizations/EditLocalization/EditLocalization";
import NewExtensionDevice from "pages/Extensions/ExtensionDevices/NewExtensionDevice/NewExtensionDevice";
import EditExtensionDevice from "pages/Extensions/ExtensionDevices/EditExtensionDevice/EditExtensionDevice";

import * as apiLabel from "models/apiLabels";
import { actions } from "utils/helpers";

export const routes = [
  {
    index: true,
    path: "accounts",
    action: actions.list,
    label: apiLabel.ACCOUNTS,
    element: Accounts,
  },
  {
    path: "accounts/new",
    action: actions.create,
    label: apiLabel.ACCOUNTS,
    element: NewAccount,
  },
  {
    path: "accounts/:id",
    action: actions.view,
    label: apiLabel.ACCOUNTS,
    element: EditAccount,
  },
  {
    path: "accounts/:id/extensions",
    action: actions.list,
    label: apiLabel.EXTENSIONS,
    element: AccountExtensions,
  },
  {
    index: true,
    path: "users",
    action: actions.list,
    label: apiLabel.USERS,
    element: Users,
  },
  {
    path: "users/:id",
    action: actions.view,
    label: apiLabel.USERS,
    element: EditUser,
  },
  {
    path: "users/:id/accounts",
    action: actions.list,
    label: apiLabel.ACCOUNTS,
    element: UserAccounts,
  },
  {
    path: "users/new",
    action: actions.create,
    label: apiLabel.USERS,
    element: NewUser,
  },
  {
    path: "users/:id/accounts/new",
    action: actions.create,
    label: apiLabel.ACCOUNTS,
    element: NewAccount,
  },
  {
    path: "domains/:id",
    action: actions.view,
    label: apiLabel.DOMAINS,
    element: EditDomain,
  },
  {
    index: true,
    path: "domains",
    action: actions.list,
    label: apiLabel.DOMAINS,
    element: Domains,
  },
  {
    index: true,
    path: "gateways",
    action: actions.list,
    label: apiLabel.GATEWAYS,
    element: Gateways,
  },
  {
    path: "gateways/new",
    action: actions.create,
    label: apiLabel.GATEWAYS,
    element: NewGateway,
  },
  {
    path: "gateways/:id",
    action: actions.view,
    label: apiLabel.GATEWAYS,
    element: EditGateway,
  },
  {
    index: true,
    path: "sip_profiles",
    action: actions.list,
    label: apiLabel.SIP_PROFILES,
    element: SipProfiles,
  },
  {
    path: "sip_profiles/new",
    action: actions.create,
    label: apiLabel.SIP_PROFILES,
    element: NewSipProfile,
  },
  {
    path: "sip_profiles/:id",
    action: actions.view,
    label: apiLabel.SIP_PROFILES,
    element: EditSipProfile,
  },
  {
    index: true,
    path: "trunks",
    action: actions.list,
    label: apiLabel.TRUNKS,
    element: Trunks,
  },
  {
    path: "trunks/new",
    action: actions.create,
    label: apiLabel.TRUNKS,
    element: NewTrunk,
  },
  {
    path: "trunks/:id",
    action: actions.view,
    label: apiLabel.TRUNKS,
    element: EditTrunk,
  },
  {
    index: true,
    path: "localizations",
    action: actions.list,
    label: apiLabel.LOCALIZATIONS,
    element: Localizations,
  },
  {
    path: "localizations/new",
    action: actions.create,
    label: apiLabel.LOCALIZATIONS,
    element: NewLocalization,
  },
  {
    path: "localizations/:id",
    action: actions.view,
    label: apiLabel.LOCALIZATIONS,
    element: EditLocalization,
  },
  {
    index: true,
    path: "pricelists",
    action: actions.list,
    label: apiLabel.PRICELISTS,
    element: Pricelists,
  },
  {
    path: "pricelists/new",
    action: actions.create,
    label: apiLabel.PRICELISTS,
    element: NewPricelist,
  },
  {
    path: "pricelists/:id",
    action: actions.view,
    label: apiLabel.PRICELISTS,
    element: EditPricelist,
  },
  {
    index: true,
    path: "call_accounts",
    action: actions.list,
    label: apiLabel.CALL_ACCOUNTS,
    element: CallAccounts,
  },
  {
    path: "call_accounts/new",
    action: actions.create,
    label: apiLabel.CALL_ACCOUNTS,
    element: NewCallAccount,
  },
  {
    path: "call_accounts/:id",
    action: actions.view,
    label: apiLabel.CALL_ACCOUNTS,
    element: EditCallAccount,
  },
  {
    index: true,
    path: "dids",
    action: actions.list,
    label: apiLabel.DIDS,
    element: DIDs,
  },
  {
    path: "dids/new",
    action: actions.create,
    label: apiLabel.DIDS,
    element: NewDID,
  },
  {
    path: "dids/:id",
    action: actions.view,
    label: apiLabel.DIDS,
    element: EditDID,
  },
  {
    index: true,
    path: "callerids",
    action: actions.list,
    label: apiLabel.ACCOUNT_CALLER_IDS,
    element: CallerIds,
  },
  {
    path: "callerids/new",
    action: actions.create,
    label: apiLabel.ACCOUNT_CALLER_IDS,
    element: NewCallerId,
  },
  {
    path: "callerids/:id",
    action: actions.view,
    label: apiLabel.ACCOUNT_CALLER_IDS,
    element: EditCallerId,
  },
  {
    index: true,
    path: "extensions",
    action: actions.list,
    label: apiLabel.EXTENSIONS,
    element: Extensions,
  },
  {
    path: "extensions/:id/devices",
    action: actions.list,
    label: apiLabel.EXTENSION_DEVICES,
    element: ExtensionDevices,
  },
  {
    path: "extensions/new",
    action: actions.create,
    label: apiLabel.EXTENSIONS,
    element: NewExtension,
  },
  {
    path: "extensions/:id",
    action: actions.view,
    label: apiLabel.EXTENSIONS,
    element: EditExtension,
  },
  {
    path: "extensions/:id/devices/new",
    action: actions.create,
    label: apiLabel.EXTENSION_DEVICES,
    element: NewExtensionDevice,
  },
  {
    path: "extensions/:id/devices/:deviceId",
    action: actions.view,
    label: apiLabel.EXTENSION_DEVICES,
    element: EditExtensionDevice,
  },
  {
    index: true,
    path: "call_records/users",
    action: actions.list,
    label: apiLabel.CALL_RECORDS,
    element: UserCallRecords,
  },
  {
    index: true,
    path: "call_records/providers",
    action: actions.list,
    label: apiLabel.CALL_RECORDS,
    element: ProviderCallRecords,
  },
  {
    index: true,
    path: "call_records/:id",
    action: actions.view,
    label: apiLabel.CALL_RECORDS,
    element: CallRecord,
  },
];
