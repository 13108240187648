import { formatDate } from "utils/helpers";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const userAdapter = createEntityAdapter({});

const initialState = userAdapter.getInitialState({ size: 0 });

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (searchParams) => `/users?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results) return userAdapter.setAll(initialState, []);

        const fetchedUsers = response.results.map((user) => {
          const {
            created_at,
            role_id,
            updated_at,
            created_by_id,
            created_by,
            ...userProps
          } = user;

          userProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            ...userProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return userAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedUsers
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "User", id })),
              { type: "User", id: "PARTIAL-LIST" },
            ]
          : [{ type: "User", id: "PARTIAL-LIST" }],
    }),
    getUser: builder.query({
      query: (id) => `/users/${id}`,
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),
    createUser: builder.mutation({
      query: (data) => ({
        url: "/users",
        method: "POST",
        body: JSON.stringify({ ...data }),
      }),
      invalidateTags: [{ type: "User", id: "PARTIAL-LIST" }],
    }),
    updateUser: builder.mutation({
      query: ({ userId, ...data }) => ({
        url: `/users/${userId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: userId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [{ type: "User", id: args.id }],
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/users/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "User", id: args.id },
        { type: "User", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetUsersQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
} = userApiSlice;
