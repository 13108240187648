import Form from "views/Form";
import className from "classnames";
import { actions, capitalize, parseError } from "utils/helpers";
import { Globals } from "utils/styleconfig";
import { searchHandler } from "models/search";
import { RiArrowRightSLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountTypeId } from "models/accountType";
import { useParams, useNavigate, createSearchParams } from "react-router-dom";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useEffect, useLayoutEffect, useState } from "react";
import { selectAllResults } from "features/search/searchSlice";
import FormElement from "components/Form/FormElement/FormElement";
import { formConfig } from "pages/Accounts/EditAccount/formConfig";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";

import {
  useUpdateAccountMutation,
  useGetAccountQuery,
} from "features/account/accountApiSlice";
import { dropdownItemSelector } from "models/form";
import { selectAuthUser } from "features/auth/authSlice";
import { toast } from "react-hot-toast";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const EditAccount = ({ label }) => {
  const params = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const authUser = useSelector(selectAuthUser);

  const searchResults = useSelector(selectAllResults);

  const [activeInput, setActiveInput] = useState("");

  const { data: account } = useGetAccountQuery(params?.id);

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(true);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [initialDataValues, setInitialDataValues] = useState([]);

  const [updateAccount, { isLoading }] = useUpdateAccountMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Edit Account"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    if (account) {
      const dataValues = [];
      const updatedFormConfig = JSON.parse(JSON.stringify(formConfig));

      let newFormConfig = {};

      Object.entries(account).forEach(([key, value]) => {
        if (updatedFormConfig[key]) {
          if (key === "type") {
            updatedFormConfig[key].elementConfig.value = value;

            // =================== Update type id ===================
            updatedFormConfig["type_id"].title = `${capitalize(
              account[key]
            )} Id`;

            updatedFormConfig["type_id"].elementConfig = {
              ...updatedFormConfig["type_id"].elementConfig,
              id: `${value}_id`,
              value: account[`${account[key]}_id`] || "",
            };

            // =================== Update type id ===================

            newFormConfig[key] = { ...updatedFormConfig[key] };
            newFormConfig[`${value}_id`] = {
              ...updatedFormConfig["type_id"],
            };

            /**
             * Convert all values to strings to allow comparison
             */
            dataValues.push(String(value));
            dataValues.push(String(account[`${account[key]}_id`]));
          } else {
            updatedFormConfig[key].elementConfig.value = value;
            dataValues.push(String(value)); // Convert all values to strings to allow comparison

            newFormConfig[key] = { ...updatedFormConfig[key] };
          }
        }
      });

      form.setConfig(newFormConfig);
      form.createFormElements();
      setFormElements(form.formElements);
      setInitialDataValues(dataValues);
    }
  }, [account]);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const onViewExtensionsBtnHandler = () => {
    navigate({
      pathname: `/accounts/${params.id}/extensions`,
      search: createSearchParams({ page: "1" }).toString(),
    });
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity && authUser?.actions[label].includes(actions.update)) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (key.split("_")[1] === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          if (el.elementConfig.type === "number") {
            formData[key] = form.parseFormValue(
              el.elementConfig.value,
              "number"
            );
          } else {
            formData[key] = el.elementConfig.value;
          }
        }
      }
      try {
        await updateAccount({ accountId: account.id, ...formData }).unwrap();
        toast.success("Account updated successfully");
        navigate({
          pathname: "/accounts",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      let isFormChanged = true;
      const updatedFormValues = [];

      updatedFormValues.push(e.target.value);

      if (e.target.id === "type") {
        const newFormConfig = updateAccountTypeId(
          form.getFormConfig(),
          updatedInput
        );

        form.setConfig(newFormConfig);
        form.createFormElements();
      }

      if (e.target.id === "user_id") {
        searchHandler("/users", e.target.value, dispatch);
      }

      if (e.target.id === "localization_id") {
        searchHandler("/localizations", e.target.value, dispatch);
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      isFormChanged =
        updatedFormValues.every((value) => initialDataValues.includes(value)) &&
        isFormChanged;

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
      setIsChangesMade(!isFormChanged);
    }
  };

  let buttonEl = null;

  if (authUser?.actions[label].includes(actions.update)) {
    buttonEl = (
      <div className={formStyles.btn}>
        <button disabled={isLoading || !formValidity || !isChangesMade}>
          {isLoading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    );
  }

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Changes made to this account will overwrite the existing info</h3>
        <button
          onClick={onViewExtensionsBtnHandler}
          className={utilityStyles.outline__button}
        >
          <span>View account extensions</span>
          <RiArrowRightSLine color={Globals.colorDarkBlue} size={16} />
        </button>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div
                key={`el-group-${idx + 1}`}
                className={className({
                  [formStyles.group]: true,
                  [utilityStyles.column100]: elementType === "textarea",
                })}
              >
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
                <FormDropdown
                  items={searchResults}
                  itemClickHandler={(item) =>
                    dropdownItemClickHandler(elementConfig.id, item)
                  }
                  visible={
                    activeInput === elementConfig.id && el.config.dropdown
                  }
                />
              </div>
            );
          })}
          {buttonEl}
        </form>
      </div>
    </div>
  );
};

export default EditAccount;
