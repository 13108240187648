import { apiSlice } from "features/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/login",
        method: "POST",
        body: JSON.stringify(credentials),
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: "/users/password",
        method: "POST",
        body: JSON.stringify(data),
      }),
    }),
    verifyAuth: builder.mutation({
      query: (data) => ({
        url: "/refresh",
        method: "POST",
        body: JSON.stringify({
          device: { platform: "web", data: "", token: data },
        }),
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/logout",
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useChangePasswordMutation,
  useLogoutMutation,
  useVerifyAuthMutation,
} = authApiSlice;
