import classNames from "classnames";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Globals } from "utils/styleconfig";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "hooks/useMediaQuery";
import { RiArrowDownSLine } from "react-icons/ri";
import { CSSTransition } from "react-transition-group";
import { selectAuthToken } from "features/auth/authSlice";
import { toggleSidebar } from "features/sidebar/sidebarSlice";
import SidebarSublist from "components/Sidebar/SidebarSublist/SidebarSublist";

import styles from "components/Sidebar/Sidebar.module.scss";
import slideAnim from "components/Sidebar/SidebarList/SidebarListItem/Slide.module.scss";

const SidebarListItem = ({ children, item }) => {
  const nodeRef = useRef(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const token = useSelector(selectAuthToken);

  const [isMobile] = useMediaQuery();

  const [toggleDropdown, setToggleDropdown] = useState(false);

  const onToggleDropdown = () => {
    if (!token) return null;

    if (item.dropdown) {
      setToggleDropdown(!toggleDropdown);
    } else {
      navigate(item.gotoPath);

      if (isMobile) {
        dispatch(toggleSidebar());
      }
    }
  };

  return (
    <>
      <li className={styles.list_item}>
        <button onClick={onToggleDropdown}>
          {children}
          <p>{item.title}</p>
          {item.dropdown && (
            <span
              className={classNames({
                [styles.bg_transparent]: true,
                [styles.rotate]: toggleDropdown,
              })}
            >
              <RiArrowDownSLine color={Globals.colorGrey} size={16} />
            </span>
          )}
        </button>
      </li>
      <CSSTransition
        timeout={300}
        nodeRef={nodeRef}
        in={toggleDropdown}
        unmountOnExit={true}
        classNames={slideAnim}
      >
        <SidebarSublist ref={nodeRef} list={item.sublist} />
      </CSSTransition>
    </>
  );
};

export default SidebarListItem;
