import React, { Fragment, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import Table from "components/Table/Table";
import { toast } from "react-hot-toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Pagination from "components/Pagination/Pagination";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import { useGetExtensionsByAccountQuery } from "features/extension/extensionApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import { UNAUTHORIZED } from "constants";

const AccountExtensions = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetExtensionsByAccountQuery({
    accountId: params?.id,
    searchParams: searchParams.toString(),
  });

  useEffect(() => {
    dispatch(setNavbarTitle("Account Extensions"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load account extensions, try refreshing the page");
    }
  }, [isError]);

  const onEditHandler = useCallback(
    (id, callFn) => {
      if (callFn) {
        navigate(`/accounts/${id}`);

        return "";
      }

      return UNAUTHORIZED;
    },

    [navigate]
  );

  return (
    <div>
      <div className={cardStyles.menu}>
        <h3>Showing data for account extensions</h3>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table data={currentData} onEdit={onEditHandler} />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </div>
  );
};

export default AccountExtensions;
