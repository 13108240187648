import { useGetCallRecordQuery } from "features/call_record/callRecordApiSlice";
import React from "react";
import className from "classnames";
import { useParams } from "react-router-dom";

import cardStyles from "styles/modules/Card.module.scss";
import styles from "pages/CallRecord/CallRecord.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const CallRecord = () => {
  const params = useParams();

  const { data: call_record } = useGetCallRecordQuery(params?.id);

  return (
    <div className={styles.container}>
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Showing data for call record</h3>
      </div>
      <div className={styles.content}>
        <div className={styles.textGroup}>
          <span>Id:</span> <p>{call_record?.id}</p>
        </div>
        <div className={styles.textGroup}>
          <span>Call Uuid:</span> <p>{call_record?.call_uuid}</p>
        </div>
        <fieldset className={styles.group}>
          <legend>Account</legend>
          <div className={styles.textGroup}>
            <span>Number:</span> <p>{call_record?.account?.number}</p>
          </div>
          <div className={styles.textGroup}>
            <span>Type:</span> <p>{call_record?.account?.type}</p>
          </div>
        </fieldset>
        <div className={styles.textGroup}>
          <span>Caller Id number:</span> <p>{call_record?.caller_id_number}</p>
        </div>
        <div className={styles.textGroup}>
          <span>Caller Billing account id:</span>{" "}
          <p>{call_record?.call_billing_account_id}</p>
        </div>
        <div className={styles.textGroup}>
          <span>Destination number:</span>{" "}
          <p>{call_record?.destination_number}</p>
        </div>
        <fieldset className={styles.group}>
          <legend>Call Billing Account</legend>
          <div className={styles.textGroup}>
            <span>Number:</span>{" "}
            <p>{call_record?.call_billling_account?.number}</p>
          </div>
          <div className={styles.textGroup}>
            <span>Type:</span> <p>{call_record?.call_billling_account?.type}</p>
          </div>
        </fieldset>
        <div className={styles.textGroup}>
          <span>Direction:</span> <p>{call_record?.direction}</p>
        </div>
        <div className={styles.textGroup}>
          <span>Call type:</span> <p>{call_record?.call_type}</p>
        </div>
        <div className={styles.textGroup}>
          <span>Sip user:</span>
          <p>{call_record?.sip_user}</p>
        </div>
        <div className={styles.textGroup}>
          <span>Trunk id:</span>
          <p>{call_record?.trunk_id}</p>
        </div>
        <div className={styles.textGroup}>
          <span>Call start:</span>
          <p>{call_record?.callstart}</p>
        </div>
        <fieldset className={styles.group}>
          <legend>Trunk</legend>
          <div className={styles.textGroup}>
            <span>Name:</span> <p>{call_record?.trunk?.name}</p>
          </div>
        </fieldset>
        <div className={styles.textGroup}>
          <span>Hang clause:</span>
          <p>{call_record?.hangup_cause}</p>
        </div>
        <fieldset className={styles.group}>
          <legend>Notes</legend>
          <div className={styles.textGroup}>
            <p>{call_record?.notes}</p>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default CallRecord;
