import Form from "views/Form";
import className from "classnames";
import { toast } from "react-hot-toast";
import { parseError } from "utils/helpers";
import { searchHandler } from "models/search";
import { dropdownItemSelector } from "models/form";
import { useDispatch, useSelector } from "react-redux";
import { updateAccountTypeId } from "models/accountType";
import { useEffect, useLayoutEffect, useState } from "react";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { selectAllResults } from "features/search/searchSlice";
import FormElement from "components/Form/FormElement/FormElement";
import { createSearchParams, useNavigate } from "react-router-dom";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import { formConfig } from "pages/CallAccounts/NewCallAccount/formConfig";
import { useCreateCallAccountMutation } from "features/call_account/callAccountApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const NewCallAccount = () => {
  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  const dispatch = useDispatch();

  const searchResults = useSelector(selectAllResults);

  const [activeInput, setActiveInput] = useState("");

  const navigate = useNavigate();

  const [createCallAccount, { isLoading }] = useCreateCallAccountMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("New CallAccount"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (
          typeof el.elementConfig.value === "string" &&
          el.elementConfig.value.trim().length <= 0
        ) {
          continue;
        }

        if (key.split("_")[1] === "id" || key.split("_")[2] === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          if (el.elementConfig.type === "number") {
            formData[key] = form.parseFormValue(
              el.elementConfig.value,
              "number"
            );
          } else {
            formData[key] = el.elementConfig.value;
          }
        }
      }

      try {
        await createCallAccount(formData).unwrap();
        toast.success("Call Account created successfully");
        navigate({
          pathname: "/call_accounts",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      if (e.target.id === "type") {
        const newFormConfig = updateAccountTypeId(
          form.getFormConfig(),
          updatedInput
        );

        form.setConfig(newFormConfig);
        form.createFormElements();
      }

      if (e.target.id === "user_id") {
        searchHandler("/users", e.target.value, dispatch);
      }

      if (e.target.id === "main_account_id") {
        searchHandler("/accounts", e.target.value, dispatch);
      }

      if (e.target.id === "pricelist_id") {
        searchHandler("/pricelists", e.target.value, dispatch);
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Fill in details for the new call account</h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div key={`el-group-${idx + 1}`} className={formStyles.group}>
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
                <FormDropdown
                  items={searchResults}
                  itemClickHandler={(item) =>
                    dropdownItemClickHandler(elementConfig.id, item)
                  }
                  visible={
                    activeInput === elementConfig.id && el.config.dropdown
                  }
                />
              </div>
            );
          })}
          <div className={formStyles.btn}>
            <button disabled={!formValidity || isLoading}>
              {isLoading ? "Creating call account..." : "Create Call Account"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewCallAccount;
