import styles from "components/Form/Form.module.scss";

export const formConfig = {
  name: {
    title: "Trunk Name",
    elementType: "input",
    elementConfig: {
      id: "name",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Call type is required",
    },
  },
  leg_timeout: {
    title: "Leg timeout",
    elementType: "input",
    elementConfig: {
      id: "leg_timeout",
      type: "number",
      step: 1,
      min: 0,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Leg timeout is required",
    },
  },
  precedence: {
    title: "Precedence",
    elementType: "input",
    elementConfig: {
      id: "precedence",
      type: "number",
      step: 1,
      min: 0,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Precedence is required",
    },
  },
  tech: {
    title: "Tech",
    elementType: "input",
    elementConfig: {
      id: "tech",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Tech is required",
    },
  },
  maxchannels: {
    title: "Max Channels",
    elementType: "input",
    elementConfig: {
      id: "maxchannels",
      type: "number",
      step: 1,
      min: 0,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Maxchannels is required",
    },
  },
  cps: {
    title: "Cps",
    elementType: "input",
    elementConfig: {
      id: "cps",
      type: "number",
      step: 1,
      min: 0,
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Cps is required",
    },
  },
  cid_translation: {
    title: "Cid Translation",
    elementType: "input",
    elementConfig: {
      id: "cid_translation",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Cid Translation is required",
    },
  },
  codec: {
    title: "Codec",
    elementType: "input",
    elementConfig: {
      id: "codec",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "Codec is required",
    },
  },
  enabled: {
    title: "Is Trunk Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "", text: "-- is Trunk enabled --" },
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: false,
    touched: false,
    error: {
      message: "",
    },
  },
  gateway_id: {
    title: "Gateway",
    elementType: "input",
    elementConfig: {
      id: "gateway_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search placeholder id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: false,
    touched: false,
    error: {
      message: "Gateway is required",
    },
  },
  provider_id: {
    title: "Provider (optional)",
    elementType: "input",
    elementConfig: {
      id: "provider_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search provider id",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Provider is required",
    },
  },
  dialed_modify: {
    title: "Dialed Modify",
    elementType: "input",
    elementConfig: {
      id: "dialed_modify",
      type: "text",
      placholder: "",
      value: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    valid: false,
    touched: false,
    error: {
      message: "",
    },
  },
};
