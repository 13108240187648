import Form from "views/Form";
import className from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { useEffect, useLayoutEffect, useState } from "react";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { formConfig } from "pages/Trunks/EditTrunk/formConfig";
import FormElement from "components/Form/FormElement/FormElement";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import { searchHandler } from "models/search";
import { selectAllResults } from "features/search/searchSlice";
import { dropdownItemSelector } from "models/form";
import { selectAuthUser } from "features/auth/authSlice";
import { actions, parseError } from "utils/helpers";
import { toast } from "react-hot-toast";
import {
  useGetTrunkQuery,
  useUpdateTrunkMutation,
} from "features/trunk/trunkApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const EditTrunk = ({ label }) => {
  const params = useParams();

  const { data: trunk } = useGetTrunkQuery(params?.id);

  const authUser = useSelector(selectAuthUser);

  const searchResults = useSelector(selectAllResults);

  const [activeInput, setActiveInput] = useState("");

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(true);
  const [isChangesMade, setIsChangesMade] = useState(false);
  const [initialDataValues, setInitialDataValues] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [updateTrunk, { isLoading }] = useUpdateTrunkMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("Edit Trunk"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    if (trunk) {
      const dataValues = [];
      const updatedFormConfig = JSON.parse(JSON.stringify(formConfig));

      Object.entries(trunk).map(([key, value]) => {
        if (updatedFormConfig[key]) {
          updatedFormConfig[key].elementConfig.value = value;
          dataValues.push(String(value)); // Convert all values to strings to allow comparison
        }

        return null;
      });

      form.setConfig(updatedFormConfig);
      form.createFormElements();
      setFormElements(form.formElements);
      setInitialDataValues(dataValues);
    }
  }, [trunk]);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity && authUser?.actions[label].includes(actions.update)) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (key.split("_")[1] === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          formData[key] = el.elementConfig.value;
        }
      }

      try {
        await updateTrunk({
          trunkId: trunk.id,
          ...formData,
        }).unwrap();
        toast.success("Trunk updated successfully");
        navigate({
          pathhname: "/trunks",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      let isFormChanged = true;
      const updatedFormValues = [];

      updatedFormValues.push(e.target.value);

      if (e.target.id === "gateway_id") {
        searchHandler("/gateways", e.target.value, dispatch);
      }

      if (e.target.id === "provider_id") {
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      isFormChanged =
        updatedFormValues.every((value) => initialDataValues.includes(value)) &&
        isFormChanged;

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
      setIsChangesMade(!isFormChanged);
    }
  };

  let buttonEl = null;

  if (authUser?.actions[label].includes(actions.update)) {
    buttonEl = (
      <div className={formStyles.btn}>
        <button disabled={isLoading || !formValidity || !isChangesMade}>
          {isLoading ? "Saving..." : "Save Changes"}
        </button>
      </div>
    );
  }

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Changes made to this trunk will overwrite the existing info</h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div key={`el-group-${idx + 1}`} className={formStyles.group}>
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
                <FormDropdown
                  items={searchResults}
                  itemClickHandler={(item) =>
                    dropdownItemClickHandler(elementConfig.id, item)
                  }
                  visible={
                    activeInput === elementConfig.id && el.config.dropdown
                  }
                />
              </div>
            );
          })}
          {buttonEl}
        </form>
      </div>
    </div>
  );
};

export default EditTrunk;
