import { formatDate } from "utils/helpers";
import { RESULTS_PER_PAGE } from "constants";
import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";

const callerIdAdapter = createEntityAdapter({});

const initialState = callerIdAdapter.getInitialState({ size: 0 });

export const callerIdApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCallerIds: builder.query({
      query: (searchParams) => `/callerids?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response?.results) return callerIdAdapter.setAll(initialState, []);

        const fetchedCallerIds = response.results.map((callerId) => {
          const {
            created_by,
            enabled,
            account_id,
            created_at,
            created_by_id,
            updated_at,
            name,
            callerid_name,
            callerid_number,
            ...callerIdProps
          } = callerId;

          callerIdProps["enabled"] = enabled ? "Yes" : "No";
          callerIdProps[
            "created_by"
          ] = `${created_by.lastname} ${created_by.firstname}`;

          return {
            ...callerIdProps,
            created_at: formatDate(created_at),
            // updated_at: formatDate(updated_at),
          };
        });

        return callerIdAdapter.setAll(
          { ...initialState, size: response?.total },
          fetchedCallerIds
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "CallerId", id })),
              { type: "CallerId", id: "PARTIAL-LIST" },
            ]
          : [{ type: "CallerId", id: "PARTIAL-LIST" }],
    }),
    getCallerId: builder.query({
      query: (id) => `/callerids/${id}`,
      providesTags: (result, error, id) => [{ type: "CallerId", id }],
    }),
    createCallerId: builder.mutation({
      query: (data) => ({
        url: "/callerids",
        method: "POST",
        body: JSON.stringify({ ...data }),
      }),
      invalidateTags: [{ type: "CallerId", id: "PARTIAL-LIST" }],
    }),
    updateCallerId: builder.mutation({
      query: ({ calleridId, ...data }) => ({
        url: `/callerids/${calleridId}`,
        method: "PATCH",
        body: JSON.stringify({
          id: calleridId,
          ...data,
        }),
      }),
      invalidateTags: (result, error, args) => [
        { type: "CallerId", id: args.id },
      ],
    }),
    deleteCallerId: builder.mutation({
      query: (id) => ({
        url: `/callerids/${id}`,
        method: "DELETE",
      }),
      invalidateTags: (result, error, args) => [
        { type: "CallerId", id: args.id },
        { type: "CallerId", id: "PARTIAL-LIST" },
      ],
    }),
  }),
});

export const {
  useGetCallerIdQuery,
  useGetCallerIdsQuery,
  useUpdateCallerIdMutation,
  useCreateCallerIdMutation,
  useDeleteCallerIdMutation,
} = callerIdApiSlice;
