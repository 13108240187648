import Form from "views/Form";
import { toast } from "react-hot-toast";
import Logo from "components/Logo/Logo";
import { formConfig } from "pages/Auth/formConfig";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { setCredentials } from "features/auth/actions";
import Checkbox from "components/Form/Checkbox/Checkbox";
import { selectDevice } from "features/device/deviceSlice";
import ReactPortal from "components/ReactPortal/ReactPortal";
import { useLoginMutation } from "features/auth/authApiSlice";
import FormElement from "components/Form/FormElement/FormElement";
import { useRef, useState, useLayoutEffect, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import styles from "pages/Auth/Auth.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const Auth = () => {
  const nodeRef = useRef(null);

  const dispatch = useDispatch();

  const location = useLocation();

  const navigate = useNavigate();

  const device = useSelector(selectDevice);

  const [error, setError] = useState({ msg: null });

  const [login, { isLoading }] = useLoginMutation();

  const [formElements, setFormElements] = useState([]);

  const [formVisible, setFormVisible] = useState(false);

  const [formValidity, setFormValidity] = useState(false);

  const [inputCheckbox, setInputCheckbox] = useState(false);

  const { trust_device } = useSelector((state) => state.auth);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  useEffect(() => {
    let timer = null;

    timer = setTimeout(() => {
      setFormVisible(true);
    }, 180);

    return () => {
      clearTimeout(timer);
      setFormVisible(false);
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    try {
      const formData = {};

      if (formValidity) {
        for (const key in form.getFormConfig()) {
          formData[key] = form.getFormConfig()[key].elementConfig.value;
        }

        setError({ msg: null });

        // console.log("DeviceID: "+device.fingerprint);
        const userData = await login({
          deviceID: device.fingerprint,
          ...formData,
        }).unwrap();

        dispatch(
          setCredentials({
            token: userData?.tokens?.accessToken,
            user: userData?.user,
          })
        );

        toast.success("Login successful");

        navigate("/", { replace: true });
      }
    } catch (error) {
      setError({
        msg: error?.data?.error || "an unexpected error occurred",
      });
    }
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    if (updatedInput) {
      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  const onToggleTrustDevice = () => {
    const isTrustDevice = inputCheckbox ? false : true;

    setInputCheckbox(isTrustDevice);
    localStorage.setItem("trust_device", isTrustDevice);
  };

  if (trust_device) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <ReactPortal containerElement="body">
      <div className={styles.overlay}>
        <CSSTransition
          nodeRef={nodeRef}
          timeout={300}
          in={formVisible}
          classNames={{
            enter: "",
            enterActive: styles.scaleIn,
            exit: "",
            exitActive: styles.scaleOut,
          }}
          unmountOnExit
        >
          <div ref={nodeRef} className={styles.panel}>
            <div className={styles.brand}>
              <Logo />
            </div>
            {error.msg && (
              <div className={styles.feedback}>
                <p>{error.msg}</p>
              </div>
            )}
            <div className={styles.content}>
              <h3>Log in to your account</h3>
              <div className={styles.form}>
                <form
                  className={formStyles.container}
                  onSubmit={submitFormHandler}
                >
                  {formElements.map((el, idx) => {
                    const { title, elementType, elementConfig, ...config } =
                      el.config;

                    return (
                      <div
                        key={`el-group-${idx + 1}`}
                        className={`${formStyles.group} ${utilityStyles.column100}`}
                      >
                        <FormElement
                          {...config}
                          title={title}
                          type={elementType}
                          id={elementConfig.id}
                          config={{
                            ...elementConfig,
                            onChange: inputChangeHandler,
                          }}
                        />
                      </div>
                    );
                  })}
                  <div className={formStyles.group}>
                    <Checkbox
                      isChecked={inputCheckbox}
                      title="Trust this device?"
                      onChange={onToggleTrustDevice}
                    />
                  </div>
                  <div className={formStyles.btn}>
                    <button disabled={!formValidity || isLoading}>
                      {isLoading ? "Verifying..." : "Login"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    </ReactPortal>
  );
};

export default Auth;
