import styles from "components/Form/Form.module.scss";

export const formConfig = {
  name: {
    title: "Name of Sip Profile",
    elementType: "input",
    elementConfig: {
      id: "name",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Sip profile name is required",
    },
  },
  hostname: {
    title: "Hostname of Sip Profile",
    elementType: "input",
    elementConfig: {
      id: "hostname",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Sip profile hostname is required",
    },
  },
  sip_ip: {
    title: "Sip ip",
    elementType: "input",
    elementConfig: {
      id: "sip_ip",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Sip ip is required",
    },
  },
  sip_port: {
    title: "Sip Port",
    elementType: "input",
    elementConfig: {
      id: "sip_port",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Sip port is required",
    },
  },
  enabled: {
    title: "Is Sip Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "", text: "-- Is Sip Enabled --" },
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
  description: {
    title: "Description",
    elementType: "textarea",
    elementConfig: {
      id: "description",
      rows: 4,
      placeholder: "Write sip profile description",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.textarea],
    validation: {
      required: false,
    },

    valid: true,
    touched: false,
    error: {
      message: "Sip profile description is required",
    },
  },
};
