import styles from "components/Form/Form.module.scss";

export const formConfig = {
  number: {
    title: "Number",
    elementType: "input",
    elementConfig: {
      id: "number",
      type: "text",
      inputMode: "numeric",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Number is required",
    },
  },
  leg_timeout: {
    title: "Leg timeout",
    elementType: "input",
    elementConfig: {
      id: "leg_timeout",
      type: "number",
      step: 1,
      min: 1,
      value: "60",
      placeholder: "1",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Leg timeout is required",
    },
  },
  account_id: {
    title: "Account",
    elementType: "input",
    elementConfig: {
      id: "account_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search Account",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: false,
    touched: false,
    error: {
      message: "Account is required",
    },
  },
  call_billing_account_id: {
    title: "Call Account",
    elementType: "input",
    elementConfig: {
      id: "call_billing_account_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search Call Account",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: false,
    touched: false,
    error: {
      message: "Account is required",
    },
  },
  ringback: {
    title: "Ringback",
    elementType: "input",
    elementConfig: {
      id: "ringback",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [styles.label],
    elementClasses: [styles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Number is required",
    },
  },
  enabled: {
    title: "Is DID Enabled",
    elementType: "select",
    elementConfig: {
      id: "enabled",
      value: true,
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
  simultaneous_extension: {
    title: "Simultaneous Extension",
    elementType: "select",
    elementConfig: {
      id: "simultaneous_extension",
      value: false,
    },
    labelClasses: [styles.label],
    elementClasses: [styles.select],
    validation: {
      required: false,
    },
    options: [
      { value: "false", text: "No" },
      { value: "true", text: "Yes" },
    ],
    valid: true,
    touched: false,
    error: {
      message: "",
    },
  },
};
