import Form from "views/Form";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { parseError } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { formConfig } from "pages/Userlines/formConfig";
import { TiWarningOutline } from "react-icons/ti";
import FormElement from "components/Form/FormElement/FormElement";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import {
  selectAllUserlines,
  useGetUserlinesQuery,
} from "features/userline/userlineApiSlice";
import { dropdownItemSelector } from "models/form";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useRechargeAccountMutation } from "features/recharge/rechargeApiSlice";

import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const Userlines = () => {
  const navigate = useNavigate();

  const [activeInput, setActiveInput] = useState(Object.keys(formConfig)[0]);

  const [rechargeAccount, { isLoading }] = useRechargeAccountMutation();

  const { isLoading: isFetchingData } = useGetUserlinesQuery();

  const userlines = useSelector(selectAllUserlines);

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (key.split("_").pop() === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          formData[key] = el.elementConfig.value;
        }
      }

      try {
        const res = await rechargeAccount({
          ...formData,
          amount: formData.amount * 100,
        }).unwrap();

        if (res) window.location.href = res.url;

        toast.success("Transaction initiated successfully");
        navigate("/user/menu");
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, { id: item?.call_billing_account_id, name: item?.name}, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div className={`${formStyles.wrapper} ${utilityStyles.wFull}`}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div
                key={`el-group-${idx + 1}`}
                className={`${formStyles.group}  ${utilityStyles.column100}`}
              >
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
                <FormDropdown
                  visible={
                    activeInput === elementConfig.id && el.config.dropdown
                  }
                  items={isFetchingData ? [] : userlines}
                  itemClickHandler={(item) =>
                    dropdownItemClickHandler(elementConfig.id, item)
                  }
                />
              </div>
            );
          })}
          <p
            className={utilityStyles.textWarn}
            style={{ gridColumn: "1 / -1" }}
          >
            <TiWarningOutline size={24} />
            <span style={{ marginLeft: "0.5rem" }}>
              You'll be redirected to Paystack payment page to complete this
              transaction.
            </span>
          </p>
          <div className={formStyles.btn}>
            <button disabled={isLoading || !formValidity}>
              {isLoading ? "Please wait..." : "Credit line"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Userlines;
