import Modal from "./Modal";
import Form from "views/Form";
import Button from "../Button/Button";
import { searchHandler } from "models/search";
import { dropdownItemSelector } from "models/form";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";
import React, { useEffect, useRef, useState } from "react";
import ReactPortal from "components/ReactPortal/ReactPortal";
import { selectAllResults } from "features/search/searchSlice";
import FormElement from "components/Form/FormElement/FormElement";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";
import modalStyles from "components/UI/Modals/Modal.module.scss";
import styles from "styles/modules/CallRecordFilter.module.scss";

const formConfig = {
  from: {
    title: "From Date",
    elementType: "input",
    elementConfig: {
      id: "from",
      type: "date",
      value: "",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Start date is required",
    },
  },
  to: {
    title: "To Date",
    elementType: "input",
    elementConfig: {
      id: "to",
      type: "date",
      value: "",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "End date is required",
    },
  },
  call_type: {
    title: "Call type",
    elementType: "select",
    elementConfig: {
      id: "call_type",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.select],
    validation: {
      required: false,
    },
    options: [
      {
        value: "",
        text: "-- select call type --",
      },
      {
        value: "STANDARD",
        text: "STANDARD",
      },
      {
        value: "DID",
        text: "DID",
      },
    ],
    valid: true,
    touched: false,
    error: {
      message: "End date is required",
    },
  },
  direction: {
    title: "Direction",
    elementType: "select",
    elementConfig: {
      id: "direction",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.select],
    validation: {
      required: false,
    },
    options: [
      {
        value: "",
        text: "-- select direction --",
      },
      {
        value: "outbound",
        text: "Outbound",
      },
      {
        value: "inbound",
        text: "Inbound",
      },
      {
        value: "local",
        text: "Local",
      },
    ],
    valid: true,
    touched: false,
    error: {
      message: "Direction is required",
    },
  },
  user_type: {
    title: "User type",
    elementType: "select",
    elementConfig: {
      id: "user_type",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.select],
    validation: {
      required: false,
    },
    options: [
      {
        value: "",
        text: "-- select direction --",
      },
      {
        value: "user",
        text: "User",
      },
      {
        value: "provider",
        text: "Provider",
      },
    ],
    valid: true,
    touched: false,
    error: {
      message: "User type is required",
    },
  },
  user_id: {
    title: "User Id",
    elementType: "input",
    elementConfig: {
      id: "user_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search user",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "User id is required",
    },
  },
  destination: {
    title: "Destination",
    elementType: "input",
    elementConfig: {
      id: "destination",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Direction is required",
    },
  },
  caller: {
    title: "Caller",
    elementType: "input",
    elementConfig: {
      id: "caller",
      type: "text",
      value: "",
      placeholder: "",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Caller is required",
    },
  },
  extension_id: {
    title: "Extension id",
    elementType: "input",
    elementConfig: {
      id: "extension_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search extension",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Extension id is required",
    },
  },
  call_account_id: {
    title: "Call Account id",
    elementType: "input",
    elementConfig: {
      id: "call_account_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search call account",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Call account id is required",
    },
  },
  account_id: {
    title: "Account id",
    elementType: "input",
    elementConfig: {
      id: "account_id",
      type: "text",
      value: "",
      "data-id": "",
      placeholder: "Search account",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    dropdown: true,
    valid: true,
    touched: false,
    error: {
      message: "Account id is required",
    },
  },
};

const form = new Form(formConfig);

const CallRecordFilter = ({ visible, close }) => {
  const ref = useRef();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const searchResults = useSelector(selectAllResults);

  const [activeInput, setActiveInput] = useState("");
  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  useEffect(() => {
    if (visible) {
      form.createFormElements();
      setFormElements(form.formElements);
    }
  }, [visible]);

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      if (e.target.id === "user_id") {
        searchHandler("/users", e.target.value, dispatch);
      }

      if (e.target.id === "account_id") {
        searchHandler("/accounts", e.target.value, dispatch);
      }

      if (e.target.id === "extension_id") {
        searchHandler("/extensions", e.target.value, dispatch);
      }

      if (e.target.id === "call_account_id") {
        searchHandler("/call_accounts", e.target.value, dispatch);
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  const onClose = () => {
    close();
    form.clear();
    setFormValidity(false);
    form.setConfig(formConfig);
    form.createFormElements();
    setFormElements(form.formElements);
  };

  const onClearFilters = () => {
    onClose();
    navigate({ search: createSearchParams({ page: "1" }).toString() });
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const submitFormHandler = (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (!!el.elementConfig.value) {
          if (key.split("_")[1] === "id" || key.split("_")[2] === "id") {
            formData[key] = el.elementConfig["data-id"];
          } else {
            if (el.elementConfig?.type === "date") {
              formData[key] = new Date(el.elementConfig.value).toJSON();
            } else {
              formData[key] = el.elementConfig.value;
            }
          }
        }
      }
    }

    let currentParams = {};

    for (const [key, value] of searchParams.entries()) {
      currentParams[key] = value;
    }

    navigate({
      search: createSearchParams({
        ...currentParams,
        ...formData,
      }).toString(),
    });

    onClose();
  };

  return (
    <ReactPortal containerElement="body">
      <CSSTransition
        in={visible}
        nodeRef={ref}
        classNames={{
          enter: "",
          enterActive: modalStyles.scaleIn,
          exit: "",
          exitActive: modalStyles.scaleOut,
        }}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit
      >
        <Modal ref={ref} position="center" maxWidth="120rem" onClose={onClose}>
          <fieldset className={styles.fieldset}>
            <legend className={styles.legendTitle}>Search Filter</legend>
            <div className={modalStyles.wrapper}>
              <form
                className={`${formStyles.container} ${utilityStyles.grid_col3}`}
              >
                {formElements.map((el, idx) => {
                  const { title, elementType, elementConfig, ...config } =
                    el.config;

                  return (
                    <div
                      key={`el-group-${idx + 1}`}
                      className={`${formStyles.group}`}
                    >
                      <FormElement
                        {...config}
                        title={title}
                        type={elementType}
                        id={elementConfig.id}
                        config={{
                          ...elementConfig,
                          onChange: inputChangeHandler,
                        }}
                      />
                      <FormDropdown
                        items={searchResults}
                        itemClickHandler={(item) =>
                          dropdownItemClickHandler(elementConfig.id, item)
                        }
                        visible={
                          activeInput === elementConfig.id && el.config.dropdown
                        }
                      />
                    </div>
                  );
                })}
              </form>
            </div>
            <div
              className={`${modalStyles.actions} ${utilityStyles.justifyStart}`}
            >
              <Button
                type="button"
                disabled={!formValidity}
                onClick={submitFormHandler}
                className={modalStyles.btnConfirm}
              >
                Search
              </Button>
              <Button
                type="button"
                onClick={onClearFilters}
                className={modalStyles.btnClose}
              >
                Clear filters
              </Button>
            </div>
          </fieldset>
        </Modal>
      </CSSTransition>
    </ReactPortal>
  );
};

export default CallRecordFilter;
