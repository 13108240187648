import { Fragment, useCallback, useEffect } from "react";
import Table from "components/Table/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import { selectAuthUser } from "features/auth/authSlice";
import { actions } from "utils/helpers";
import { UNAUTHORIZED } from "constants";
import { toast } from "react-hot-toast";
import {
  useGetCallerIdsQuery,
  useDeleteCallerIdMutation,
} from "features/callerid/callerIdApiSlice";

import cardStyles from "styles/modules/Card.module.scss";

const CallerIds = ({ label }) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetCallerIdsQuery(searchParams.toString());

  const authUser = useSelector(selectAuthUser);

  const [deleteCallerId] = useDeleteCallerIdMutation();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setNavbarTitle("Caller Ids"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load caller ids, try refreshing the page");
    }
  }, [isError]);

  const onEditBtnHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/callerids/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate]
  );

  const onDeleteBtnHandler = useCallback(
    async (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.delete)) {
        if (callFn) {
          await deleteCallerId(id).unwrap(id);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, deleteCallerId, label]
  );

  return (
    <div>
      <div className={cardStyles.menu}>
        <h3>Showing data for caller ids</h3>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table
            data={currentData}
            onEdit={onEditBtnHandler}
            onDelete={onDeleteBtnHandler}
          />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </div>
  );
};

export default CallerIds;
