import { actions } from "utils/helpers";
import { toast } from "react-hot-toast";
import { UNAUTHORIZED } from "constants";
import Table from "components/Table/Table";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/Pagination/Pagination";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useNavigate, useSearchParams } from "react-router-dom";
import TableSkeleton from "components/Table/TableSkeleton/TableSkeleton";
import React, { Fragment, useCallback, useEffect } from "react";

import {
  useGetGatewaysQuery,
  useDeleteGatewayMutation,
} from "features/gateway/gatewayApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import { selectAuthUser } from "features/auth/authSlice";

const Gateways = ({ label }) => {
  const [searchParams] = useSearchParams();

  const {
    currentData,
    isFetching: isLoading,
    isError,
  } = useGetGatewaysQuery(searchParams.toString());

  const [deleteGateway] = useDeleteGatewayMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authUser = useSelector(selectAuthUser);

  useEffect(() => {
    dispatch(setNavbarTitle("Gateways"));
  }, [dispatch]);

  useEffect(() => {
    if (isError) {
      toast.error("Could not load gateways, try refreshing the page");
    }
  }, [isError]);

  const onEditBtnHandler = useCallback(
    (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.view)) {
        if (callFn) {
          navigate(`/gateways/${id}`);
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, label, navigate]
  );

  const onDeleteBtnHandler = useCallback(
    async (id, callFn = false) => {
      if (authUser?.actions[label].includes(actions.delete)) {
        if (callFn) {
          await deleteGateway(id).unwrap();
        }

        return "";
      }

      return UNAUTHORIZED;
    },
    [authUser?.actions, deleteGateway, label]
  );

  return (
    <div>
      <div className={cardStyles.menu}>
        <h3>Showing data for gateways</h3>
      </div>
      {isLoading ? (
        <TableSkeleton />
      ) : (
        <Fragment>
          <Table
            data={currentData}
            onEdit={onEditBtnHandler}
            onDelete={onDeleteBtnHandler}
          />
          <Pagination totalCount={currentData?.size} />
        </Fragment>
      )}
    </div>
  );
};

export default Gateways;
