import Modal from "./Modal";
import Form from "views/Form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Button from "components/UI/Button/Button";
import { generateRandomNum } from "utils/helpers";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import ReactPortal from "components/ReactPortal/ReactPortal";
import FormElement from "components/Form/FormElement/FormElement";
import { useCreateUserLoginMutation } from "features/user-login/userLoginApiSlice";

import formStyles from "components/Form/Form.module.scss";
import styles from "components/UI/Modals/Modal.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const formConfig = {
  login: {
    title: "Username",
    elementType: "input",
    elementConfig: {
      id: "login",
      type: "text",
      inputMode: "text",
      value: "",
      placeholder: "johndoes",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    error: {
      message: "Username is required",
    },
  },
  password: {
    title: "Password (optional)",
    elementType: "input",
    elementConfig: {
      id: "password",
      type: "password",
      value: "",
      autoComplete: "off",
      placeholder: "········",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: false,
    },
    valid: true,
    touched: false,
    error: {
      message: "Pasword is required",
    },
  },
  send_to_email: {
    title: "Email to send Password",
    elementType: "input",
    elementConfig: {
      id: "send_to_email",
      type: "email",
      inputMode: "email",
      value: "",
      placeholder: "JohnDoe@example.com",
    },
    labelClasses: [formStyles.label],
    elementClasses: [formStyles.input],
    validation: {
      required: true,
    },
    valid: false,
    touched: false,
    error: {
      message: "Email is required",
    },
  },
};

const form = new Form(formConfig);

const NewCredential = ({ visible, close }) => {
  const ref = useRef();

  const params = useParams();

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  const [createUserLogin, { isLoading }] = useCreateUserLoginMutation();

  useEffect(() => {
    if (visible) {
      form.createFormElements();
      setFormElements(form.formElements);
    }
  }, [visible]);

  const onGenerateCredentials = () => {
    const newFormConfig = JSON.parse(JSON.stringify(formConfig));

    const username = generateRandomNum();

    newFormConfig["password"].elementConfig.value = username.toString();
    newFormConfig["password"].elementConfig.touched = true;

    form.setConfig(newFormConfig);
    form.createFormElements();

    form.checkFormValidity();

    setFormElements(form.formElements);

    setFormValidity(form.isValid);
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    if (updatedInput) {
      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  const onClose = () => {
    close();
    form.clear();
    setFormValidity(false);
    form.setConfig(formConfig);
    form.createFormElements();
    setFormElements(form.formElements);
  };

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        formData[key] = el.elementConfig.value;
      }

      try {
        await createUserLogin({ userId: params?.id, ...formData }).unwrap();
        onClose();
        toast.success("User login successfully created");
      } catch (error) {}
    }
  };

  return (
    <ReactPortal containerElement="body">
      <CSSTransition
        in={visible}
        nodeRef={ref}
        classNames={{
          enter: "",
          enterActive: styles.scaleIn,
          exit: "",
          exitActive: styles.scaleOut,
        }}
        timeout={{ enter: 300, exit: 300 }}
        unmountOnExit
      >
        <Modal
          ref={ref}
          title="Create Credentials"
          maxWidth="42rem"
          onClose={onClose}
        >
          <p>Fill in the details for user login</p>
          <div className={styles.wrapper}>
            <form className={formStyles.container}>
              {formElements.map((el, idx) => {
                const { title, elementType, elementConfig, ...config } =
                  el.config;

                return (
                  <div
                    key={`el-group-${idx + 1}`}
                    className={`${formStyles.group} ${utilityStyles.column100}`}
                  >
                    <FormElement
                      {...config}
                      title={title}
                      type={elementType}
                      id={elementConfig.id}
                      config={{
                        ...elementConfig,
                        onChange: inputChangeHandler,
                      }}
                    />
                  </div>
                );
              })}
            </form>
          </div>
          <div
            className={`${styles.actions} ${utilityStyles.justifyStart} ${utilityStyles.gap0}`}
          >
            <Button
              type="button"
              className={styles.btnConfirm}
              onClick={submitFormHandler}
              disabled={isLoading || !formValidity}
            >
              {isLoading ? "Please wait..." : "Create Login"}
            </Button>
            <Button
              type="button"
              className={`${formStyles.btnRed} ${utilityStyles.ml_1}`}
              onClick={onGenerateCredentials}
            >
              Auto-Generate Password
            </Button>
          </div>
        </Modal>
      </CSSTransition>
    </ReactPortal>
  );
};

export default NewCredential;
