import styles from "components/Form/Checkbox/Checkbox.module.scss";

const Checkbox = ({ isChecked, onChange, title }) => {
  return (
    <label className={styles.wrapper}>
      {title}
      <input onChange={onChange} checked={isChecked} type="checkbox" />
      <span></span>
    </label>
  );
};

export default Checkbox;
