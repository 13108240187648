import Form from "views/Form";
import className from "classnames";
import { toast } from "react-hot-toast";
import { searchHandler } from "models/search";
import Button from "components/UI/Button/Button";
import { dropdownItemSelector } from "models/form";
import { useDispatch, useSelector } from "react-redux";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useEffect, useLayoutEffect, useState } from "react";
import { generateRandomNum, parseError } from "utils/helpers";
import { selectAllResults } from "features/search/searchSlice";
import FormElement from "components/Form/FormElement/FormElement";
import { createSearchParams, useNavigate } from "react-router-dom";
import { formConfig } from "pages/Extensions/NewExtension/formConfig";
import FormDropdown from "components/Form/FormDropdown/FormDropdown";
import { useCreateExtensionMutation } from "features/extension/extensionApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const NewExtension = () => {
  const [activeInput, setActiveInput] = useState("");

  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  const searchResults = useSelector(selectAllResults);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createExtension, { isLoading }] = useCreateExtensionMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("New Extension"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        if (key.split("_").pop() === "id") {
          formData[key] = el.elementConfig["data-id"];
        } else {
          formData[key] = el.elementConfig.value;
        }
      }

      try {
        await createExtension(formData).unwrap();
        toast.success("Extension created successfully");
        navigate({
          pathname: "/extensions",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const dropdownItemClickHandler = (key, item) => {
    dropdownItemSelector(key, item, form);
    setFormElements(form.formElements);
    setActiveInput("");
  };

  const onGenerateCredentials = () => {
    const newFormConfig = JSON.parse(JSON.stringify(formConfig));

    const username = generateRandomNum();

    newFormConfig["username"].elementConfig.value = username.toString();
    newFormConfig["username"].elementConfig.touched = true;

    form.setConfig(newFormConfig);
    form.createFormElements();

    form.checkFormValidity();

    setFormElements(form.formElements);

    setFormValidity(form.isValid);
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    setActiveInput(e.target.id);

    if (updatedInput) {
      if (e.target.id === "did_id") {
        searchHandler("/dids", e.target.value, dispatch);
      }

      if (e.target.id === "caller_id") {
        searchHandler("/callerids", e.target.value, dispatch);
      }

      if (e.target.id === "account_id") {
        searchHandler("/accounts", e.target.value, dispatch);
      }
      if (e.target.id === "call_billing_account_id") {
        searchHandler("/call_accounts", e.target.value, dispatch);
      }

      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Fill in details for the new extension</h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div key={`el-group-${idx + 1}`} className={formStyles.group}>
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
                <FormDropdown
                  items={searchResults}
                  itemClickHandler={(item) =>
                    dropdownItemClickHandler(elementConfig.id, item)
                  }
                  visible={
                    activeInput === elementConfig.id && el.config.dropdown
                  }
                />
              </div>
            );
          })}
          <div className={formStyles.btn}>
            <button disabled={isLoading || !formValidity}>
              {isLoading ? "Creating extension..." : "Create Extension"}
            </button>
            <Button
              type="button"
              className={`${formStyles.btnRed} ${utilityStyles.ml_1}`}
              onClick={onGenerateCredentials}
            >
              Auto-Generate Username
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewExtension;
