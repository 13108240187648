import Form from "views/Form";
import className from "classnames";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { parseError } from "utils/helpers";
import { createSearchParams, useNavigate } from "react-router-dom";
import { setNavbarTitle } from "features/navbar/navbarSlice";
import { useState, useEffect, useLayoutEffect } from "react";
import FormElement from "components/Form/FormElement/FormElement";
import { formConfig } from "pages/Pricelists/NewPricelist/formConfig";
import { useCreatePricelistMutation } from "features/pricelist/pricelistApiSlice";

import cardStyles from "styles/modules/Card.module.scss";
import formStyles from "components/Form/Form.module.scss";
import utilityStyles from "styles/modules/Utility.module.scss";

const form = new Form(formConfig);

const NewPricelist = () => {
  const [formElements, setFormElements] = useState([]);
  const [formValidity, setFormValidity] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createPricelist, { isLoading }] = useCreatePricelistMutation();

  useEffect(() => {
    dispatch(setNavbarTitle("New Pricelist"));
  }, [dispatch]);

  useLayoutEffect(() => {
    form.createFormElements();
    setFormElements(form.formElements);
  }, []);

  useEffect(() => {
    return () => {
      if (process.env.NODE_ENV === "production") {
        form.clear(); // will run once in production
        form.setConfig(formConfig);
      }
    };
  }, []);

  const submitFormHandler = async (e) => {
    e.preventDefault();

    const formData = {};

    if (formValidity) {
      for (const key in form.getFormConfig()) {
        const el = form.getFormConfig()[key];

        formData[key] = el.elementConfig.value;
      }

      try {
        await createPricelist(formData).unwrap();
        toast.success("Pricelist created successfully");
        navigate({
          pathname: "/pricelists",
          search: createSearchParams({ page: "1" }).toString(),
        });
      } catch (error) {
        const errorMessage = parseError(error);
        toast.error(errorMessage);
      }
    }
  };

  const inputChangeHandler = (e) => {
    const updatedInput = form.inputChangeHandler(e);

    if (updatedInput) {
      form.updateFormElement({ id: e.target.id, config: updatedInput });

      form.checkFormValidity();

      setFormElements(form.formElements);
      setFormValidity(form.isValid);
    }
  };

  return (
    <div
      className={`${utilityStyles.flexColumn} ${utilityStyles.itemsCenter} ${utilityStyles.justifyCenter}`}
    >
      <div
        className={className({
          [cardStyles.menu]: true,
          [utilityStyles.wFull]: true,
        })}
      >
        <h3>Fill in details for the new pricelist</h3>
      </div>
      <div className={formStyles.wrapper}>
        <form className={formStyles.container} onSubmit={submitFormHandler}>
          {formElements.map((el, idx) => {
            const { title, elementType, elementConfig, ...config } = el.config;

            return (
              <div
                key={`el-group-${idx + 1}`}
                className={className({
                  [formStyles.group]: true,
                  [utilityStyles.column100]: elementType === "textarea",
                })}
              >
                <FormElement
                  {...config}
                  title={title}
                  type={elementType}
                  id={elementConfig.id}
                  config={{
                    ...elementConfig,
                    onChange: inputChangeHandler,
                  }}
                />
              </div>
            );
          })}
          <div className={formStyles.btn}>
            <button disabled={!formValidity || isLoading}>
              {isLoading ? "Creating pricelist..." : "Create Pricelist"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewPricelist;
