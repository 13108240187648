import { createSlice } from "@reduxjs/toolkit";
import { deviceApiSlice } from "features/device/deviceApiSlice";

const initialState = {
  id: null,
  fingerprint: null,
};

const deviceSlice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setFingerprintId(state, action) {
      state.fingerprint = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      deviceApiSlice.endpoints.signDevice.matchFulfilled,
      (state, action) => {
        state.fingerprint = action.payload?.device_id;
      }
    );
  },
});

export const { setFingerprintId } = deviceSlice.actions;

export const selectDevice = (state) => state.device;

export default deviceSlice.reducer;
