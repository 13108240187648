import { setCredentials, logOut, setTrustDevice } from "features/auth/actions";
import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { REFRESH_TOKEN } from "constants";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

const baseQueryWithReAuth = retry(
  async (args, api, extraOptions) => {
    let response = await baseQuery(args, api, extraOptions);

    // Request for new access token
    if (response?.error?.data?.error === REFRESH_TOKEN) {
      const refreshResponse = await baseQuery(
        {
          url: "/refresh",
          method: "POST",
          body: JSON.stringify({
            device: {
              platform: "web",
              data: "",
              token: api.getState().device.fingerprint,
            },
          }),
        },
        api,
        extraOptions
      );

      if (refreshResponse?.data) {
        const user = api.getState().auth.user;
        // store new token in state;
        api.dispatch(
          setCredentials({
            token: refreshResponse.data.tokens?.accessToken,
            user,
          })
        );

        // retry original request with new access token
        response = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logOut());
        api.dispatch(setTrustDevice(false));
      }
    }

    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant
    if (response.error?.data?.code === 401) {
      api.dispatch(logOut());
      api.dispatch(setTrustDevice(false));
      retry.fail(response.error);
    }

    return response;
  },
  { maxRetries: 2 }
);

export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  tagTypes: [
    "Domain",
    "Localization",
    "Account",
    "User",
    "Pricelist",
    "CallAccount",
    "DID",
    "CallerId",
    "Extension",
    "Device",
    "Gateway",
    "SipProfile",
    "Trunk",
    "Role",
    "Userline",
    "Userlogs",
    "UserCallRecord",
    "ProviderCallRecord",
  ],
  endpoints: (builder) => ({}),
});
