import { apiSlice } from "features/api/apiSlice";
import { createEntityAdapter } from "@reduxjs/toolkit";
import { RESULTS_PER_PAGE } from "constants";

const userRecordsAdapter = createEntityAdapter({});
const providerRecordsAdapter = createEntityAdapter({});

const userRecordsInitialState = userRecordsAdapter.getInitialState({
  size: 0,
  total_cost: 0,
  billseconds: 0,
});
const providerRecordsInitialState = providerRecordsAdapter.getInitialState({
  size: 0,
  total_cost: 0,
  billseconds: 0,
});

export const callRecordApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserCallRecords: builder.query({
      query: (searchParams) =>
        `/call_records/users?${searchParams}&limit=${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response)
          return userRecordsAdapter.setAll(userRecordsInitialState, []);

        return userRecordsAdapter.setAll(
          {
            ...userRecordsInitialState,
            size: response?.count,
            total_cost: response?.total_cost,
            billseconds: response?.billseconds,
          },
          response?.records
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "UserCallRecord", id })),
              { type: "UserCallRecord", id: "PARTIAL-LIST" },
            ]
          : [{ type: "UserCallRecord", id: "PARTIAL-LIST" }],
    }),
    getProviderCallRecords: builder.query({
      query: (searchParams) =>
        `/call_records/providers?${searchParams}&limit${RESULTS_PER_PAGE}`,
      transformResponse: (response) => {
        if (!response)
          return providerRecordsAdapter.setAll(providerRecordsInitialState, []);

        return providerRecordsAdapter.setAll(
          {
            ...providerRecordsInitialState,
            size: response?.count,
            total_cost: response?.total_cost,
            billseconds: response?.billseconds,
          },
          response?.records
        );
      },
      providesTags: (result) =>
        result
          ? [
              ...result.ids.map((id) => ({ type: "ProviderCallRecord", id })),
              { type: "ProviderCallRecord", id: "PARTIAL-LIST" },
            ]
          : [{ type: "ProviderCallRecord", id: "PARTIAL-LIST" }],
    }),
    getCallRecord: builder.query({
      query: (id) => `/call_records/${id}`,
      providesTags: (result, error, id) => [
        { type: "UserCallRecord", id },
        { type: "ProviderCallRecord", id },
      ],
    }),
  }),
});

export const {
  useGetUserCallRecordsQuery,
  useGetCallRecordQuery,
  useGetProviderCallRecordsQuery,
} = callRecordApiSlice;
